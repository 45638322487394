import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import {
  SinglePageSwitchLazy,
  OrderConfirmationLazy,
  internationalPhoneNumberLazy,
  MainLazy,
  Auth0CallbackGuestLazy,
  Auth0CallbackAuthenticatedLazy,
  Auth0CallbackOrderClaimLazy,
  Auth0CallbackCreateAccountLazy,
  AgeVerificationCallbackLazy,
} from './utils/lazy/lazyMainBundle';
import App from './App';
import SessionManager from './views/login-session/SessionManager';
import Login from './views/login/Login';
import SessionExpired from './views/session-expired';
import NotFound from './views/not-found/NotFound';
import routesConstants from './constants/routeConstants';

const AppRoute = connect(state => ({
  location: state.router.location,
}))(App);

const ConnectedSwitch = connect(state => ({
  location: {
    ...state.router.location,
  },
}))(Switch);


const getMultiPathPattern = (paths) => {
  const pattern = paths.reduce((acc, path, index) => (
    index < paths.length - 1 ? `${acc}${path}|` : `${acc}${path}`
  ), '');
  return `(${pattern})`;
};

function routes(persist) {

  // initiate preloading of our main bundle
  internationalPhoneNumberLazy();

  const LoginSessionManagerPaths = [
    routesConstants.LOGIN,
    routesConstants.APPS_GUEST_HANDOVER,
    routesConstants.APPS_AUTHENTICATED_HANDOVER,
    routesConstants.WEB_HANDOVER,
  ];
  const LoginSessionManagerPathsPattern = getMultiPathPattern(LoginSessionManagerPaths);
  const LoginSessionManager = props => (
    <SessionManager {...props}>
      <Switch>
        <Route component={Login} exact path={routesConstants.LOGIN} />
      </Switch>
    </SessionManager>
  );

  const SinglePageSwitch = () => (
    <React.Suspense fallback={<div />}>
      <SinglePageSwitchLazy />
    </React.Suspense>
  );
  const OrderConfirmation = () => (
    <React.Suspense fallback={<div />}>
      <OrderConfirmationLazy />
    </React.Suspense>
  );

  const Auth0CallbackGuest = () => (
    <React.Suspense fallback={<div />}>
      <Auth0CallbackGuestLazy />
    </React.Suspense>
  );

  const Auth0CallbackAuthenticated = () => (
    <React.Suspense fallback={<div />}>
      <Auth0CallbackAuthenticatedLazy />
    </React.Suspense>
  );

  const Auth0CallbackOrderClaim = () => (
    <React.Suspense fallback={<div />}>
      <Auth0CallbackOrderClaimLazy />
    </React.Suspense>
  );

  const Auth0CallbackCreateAccount = () => (
    <React.Suspense fallback={<div />}>
      <Auth0CallbackCreateAccountLazy />
    </React.Suspense>
  );

  const AgeVerificationCallback = () => (
    <React.Suspense fallback={<div />}>
      <AgeVerificationCallbackLazy />
    </React.Suspense>
  );

  const MainManagerPaths = [
    routesConstants.DELIVERY_CHOICE,
    routesConstants.DELIVERY,
    routesConstants.DELIVERY_ADDRESS_BOOK,
    routesConstants.DELIVERY_ADDRESS_BOOK_NEW,
    routesConstants.DELIVERY_ADDRESS_BOOK_EDIT,
    routesConstants.DELIVERY_OPTIONS,
    routesConstants.CLICK_AND_COLLECT,
    routesConstants.CLICK_AND_COLLECT_STORE_DETAILS,
    routesConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS,
    routesConstants.CLICK_AND_COLLECT_STORE_SELECTED,
    routesConstants.CLICK_AND_COLLECT_SEARCH_MAP,
    routesConstants.CLICK_AND_COLLECT_SEARCH_LIST,
    routesConstants.PAYMENT,
    routesConstants.PAYMENT_STORE_DETAILS,
    routesConstants.PAYMENT_GIFT_OPTIONS,
    routesConstants.PAYMENT_ADDRESS_BOOK,
    routesConstants.PAYMENT_ADDRESS_BOOK_NEW,
    routesConstants.PAYMENT_ADDRESS_BOOK_EDIT,
    routesConstants.PAYMENT_POS_CREDIT_CALLBACK,
    routesConstants.ORDER_CONFIRMATION,
    routesConstants.ORDER_CONFIRMATION_STORE_DETAILS,
    routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL,
    routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL_NEW,
    routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL_EDIT,
    routesConstants.EDIT_BASKET,
    routesConstants.SESSION_EXPIRED,
    routesConstants.CALLBACK_LOGIN_AUTHENTICATED,
    routesConstants.CALLBACK_LOGIN_GUEST,
    routesConstants.CALLBACK_ORDER_CLAIM,
    routesConstants.CALLBACK_CREATE_ACCOUNT,
    routesConstants.LEAVE_CHECKOUT,
    routesConstants.JOIN_MY_JL,
    routesConstants.JOIN_MY_JL_NEW,
    routesConstants.JOIN_MY_JL_EDIT,
    routesConstants.CALLBACK_AGE_VERIFICATION,
    routesConstants.CALLBACK_AGE_VERIFICATION_CANCEL,
  ];
  const MainManagerPathsPattern = getMultiPathPattern(MainManagerPaths);
  const MainManager = props => (
    <React.Suspense fallback={<p>Loading...</p>}>
      <MainLazy {...props}>
        <Switch>
          {/* DELIVERY */}
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY_ADDRESS_BOOK_EDIT} />
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY_ADDRESS_BOOK_NEW} />
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY_ADDRESS_BOOK} />
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY_CHOICE} />
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY_OPTIONS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.DELIVERY} />
          {/* CLICK AND COLLECT */}
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT_SEARCH_LIST} />
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT_SEARCH_MAP} />
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT_STORE_DETAILS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT_STORE_SELECTED} />
          <Route component={SinglePageSwitch} exact path={routesConstants.CLICK_AND_COLLECT} />
          {/* PAYMENT */}
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_ADDRESS_BOOK_NEW} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_ADDRESS_BOOK_EDIT} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_ADDRESS_BOOK} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_CARD_DELETE} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_GIFT_OPTIONS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_GIFT_OPTIONS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_STORE_DETAILS} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT} />
          <Route component={SinglePageSwitch} exact path={routesConstants.PAYMENT_POS_CREDIT_CALLBACK} />
          {/* EDIT BASKET */}
          <Route component={SinglePageSwitch} exact path={routesConstants.EDIT_BASKET} />
          {/* ORDER CONFIRMATION */}
          <Route component={OrderConfirmation} exact path={routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL_EDIT} />
          <Route component={OrderConfirmation} exact path={routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL_NEW} />
          <Route component={OrderConfirmation} exact path={routesConstants.ORDER_CONFIRMATION_JOIN_MY_JL} />
          <Route component={OrderConfirmation} exact path={routesConstants.ORDER_CONFIRMATION_STORE_DETAILS} />
          <Route component={OrderConfirmation} exact path={routesConstants.ORDER_CONFIRMATION} />
          <Route component={OrderConfirmation} exact path={routesConstants.JOIN_MY_JL} />
          <Route component={OrderConfirmation} exact path={routesConstants.JOIN_MY_JL_EDIT} />
          <Route component={OrderConfirmation} exact path={routesConstants.JOIN_MY_JL_NEW} />
          {/* SESSION EXPIRED */}
          <Route component={SessionExpired} exact path={routesConstants.SESSION_EXPIRED} />
          <Route component={SinglePageSwitch} exact path={routesConstants.LEAVE_CHECKOUT} />
          {/* AUTH0 CALLBACKS */}
          <Route component={Auth0CallbackAuthenticated} exact path={routesConstants.CALLBACK_LOGIN_AUTHENTICATED} />
          <Route component={Auth0CallbackGuest} exact path={routesConstants.CALLBACK_LOGIN_GUEST} />
          <Route component={Auth0CallbackOrderClaim} exact path={routesConstants.CALLBACK_ORDER_CLAIM} />
          <Route component={Auth0CallbackCreateAccount} exact path={routesConstants.CALLBACK_CREATE_ACCOUNT} />
          <Route component={AgeVerificationCallback} exact path={routesConstants.CALLBACK_AGE_VERIFICATION} />
          <Route component={AgeVerificationCallback} exact path={routesConstants.CALLBACK_AGE_VERIFICATION_CANCEL} />
        </Switch>
      </MainLazy>
    </React.Suspense>
  );


  return (
    <AppRoute persistor={persist}>

      <ConnectedSwitch data-variant="on">

        <Route exact path={LoginSessionManagerPathsPattern} render={LoginSessionManager} />

        {/* MAIN BUNDLE - LAZY LOADS WHEN ANY OF THESE PATHS MATCH */}
        <Route path={MainManagerPathsPattern} render={MainManager} />

        {/* FALLBACK - ALL UNMATCHED ROUTES */}
        <Route component={NotFound} />

      </ConnectedSwitch>

    </AppRoute>
  );
}

export default routes;
