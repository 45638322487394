import React from 'react';
import cx from 'classnames';

// Types
import { HeadingProps } from './Heading.types';

// Config
import { getSpacingClass, headingThemes } from '../design-system/DesignSystem.constants';
import { getDataAttributes } from '../../utils/helpers/dataAttributes';

const Heading = ({
  children,
  className,
  label,
  marginBottom = '0',
  maskContentSquare = false,
  maskPercy = false,
  maskText = false,
  tag = 'h1',
  testId,
  type = 'm',
  ...otherProps
}: HeadingProps & React.HTMLAttributes<HTMLElement>) => {
  const Tag = tag;
  const themeClass = headingThemes[type] || headingThemes.m;
  const marginBottomClass = getSpacingClass({ type: 'margin', position: 'bottom', size: marginBottom, backupSize: '0' });

  return (label || children) && (
    <Tag
      className={cx(themeClass, marginBottomClass, className)}
      {...getDataAttributes({ maskContentSquare, maskPercy, maskText, testId })}
      {...otherProps}
    >{label || children}
    </Tag>
  );
};

export default Heading;
