import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { AppDispatch } from 'types/RootState.types';

// Config
import cardholdersDetailsAddressBookState from './CardholdersDetailsAddressBook.state';
import routeConstants from '../../../../constants/routeConstants';
import { updateAddress } from '../../../../redux/actions/addressBook/addressBookActions';
import { showBillingAddressBookModal } from '../../../../redux/actions/payment/paymentActions';
import { getCountryNameFromCode } from '../../../../utils/address/internationalAddress';
import getBillingDetailsFormConfig, { BILLING_ADDRESS_FORM_ID } from '../../../../utils/form/configs/billingAddress';

// Components
import AddressBookModal from '../../../address-book-modal';
import Container from '../../../container';
import DisplayAddress from '../../../display-address/DisplayAddress';
import InvalidAddressForm from '../../../invalid-address-form/InvalidAddressForm';

const CardholdersDetailsAddressBook = ({
  showChangeAddressButton = false,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    addressBook = [],
    billingAddress = {},
    showAddressBookModal,
  } = useSelector(cardholdersDetailsAddressBookState);

  const { address: { countryCode = '' } = {} } = billingAddress;
  const transformedAddress = countryCode && countryCode !== 'GB' ? {
    ...billingAddress.address,
    countryCode: getCountryNameFromCode(countryCode),
  } : billingAddress.address;

  const onChangeAddress = () => dispatch(showBillingAddressBookModal());
  const onUpdateAddress = ({ formId, formValues, handleModalClose }: any) => dispatch(updateAddress({
    formId,
    formValues,
    handleModalClose,
  }));

  return (
    <>
      {!billingAddress.invalid && (
        <>
          <Container marginBottom="2" marginRight="1">
            <DisplayAddress
              address={transformedAddress}
              addressee={billingAddress.addressee}
              data-test="payment-card-billing-address"
              inline
              phoneNumber={billingAddress.phoneNumber}
            />
          </Container>
        </>
      )}

      {billingAddress.invalid && !showAddressBookModal && (
        <InvalidAddressForm
          addressBook={addressBook}
          addressFormId={BILLING_ADDRESS_FORM_ID}
          getConfig={getBillingDetailsFormConfig}
          international
          isBillingAddress
          messageBody="Some of the information in your saved billing address is missing or invalid. Please update this information or change the address to continue."
          onChangeAddress={onChangeAddress}
          onUpdateAddress={onUpdateAddress}
          savedAddress={billingAddress}
          showChangeAddressButton={showChangeAddressButton}
        />
      )}

      {showAddressBookModal && (
        <AddressBookModal
          baseRoutePath={routeConstants.PAYMENT}
          formId={BILLING_ADDRESS_FORM_ID}
          getConfig={getBillingDetailsFormConfig}
          international
        />
      )}
    </>
  );
};

export default CardholdersDetailsAddressBook;
