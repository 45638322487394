import {
  storageCanBeSet,
  cookiePrefix,
} from '@JohnLewisPartnership/pecr-util';
import storageManifest from '../../storageManifest.json';
import deleteCookie from '../helpers/cookie/deleteCookie';
import getCookie from '../helpers/cookie/getCookie';
import setCookie from '../helpers/cookie/setCookie';

export function pecrCheck(storageType, key) {
  return storageCanBeSet(storageManifest[storageType][key]);
}

export function getCookieGroupPermission(groupName) {
  const cookieGroupPermission = getCookie(`${cookiePrefix}${groupName}`);
  if (!cookieGroupPermission) {
    return 'nopref';
  }

  return cookieGroupPermission === '1' ? 'allow' : 'refuse';
}

export function allowCookieGroup(groupName) {
  setCookie({ name: `${cookiePrefix}${groupName}`, value: 1, session: true });
}

export function refuseCookieGroup(groupName) {
  setCookie({ name: `${cookiePrefix}${groupName}`, value: 0, session: true });
}

export function removeCookieGroupPreference(groupName) {
  deleteCookie({ name: `${cookiePrefix}${groupName}` });
}
