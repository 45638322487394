import React from 'react';
import deliveryConstants from '../../constants/deliveryConstants';

export function shouldShowPersonalisationHelpText(params) {
  const {
    personalised,
    isChildItem,
    orderComplete,
    state = {},
  } = params;

  if (!personalised) return false;

  if (orderComplete || state.app?.orderComplete) return false;

  if (isChildItem) return false;

  return true;
}

export function deliveryContainsPersonalisedItems(delivery) {
  return delivery.type === deliveryConstants.SUPPLIER_DIRECT && delivery.items.some(item => item.personalised);
}

export function getPersonalisedItems(deliveries = []) {
  const sdDeliveries = deliveries.filter(delivery => delivery.type === deliveryConstants.SUPPLIER_DIRECT);
  return sdDeliveries.reduce((acc, delivery) => (
    delivery.items ? [...acc, ...delivery.items.filter(item => item.personalised)] : [...acc]
  ), []);
}

export function modifyDeliveryMethodText(additionalInformation) {
  return (
    <>
      <p>{ additionalInformation }</p>
      <p>Once you’ve placed your order, please email your personalisation details to the supplier.</p>
      <p>Details on how to do this can be found on the product details page or the order confirmation page.</p>
      <p>
        If this is not received within three working days,
        a representative will contact you by phone to discuss your order.
      </p>
    </>
  );
}
