export default function getDeleteCookieString(params) {
  const {
    name,
    domain,
  } = params;

  let cookieToDelete = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;

  if (domain) {
    cookieToDelete = `${cookieToDelete}; domain=${domain}`;
  }

  return cookieToDelete;
}
