import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { isCollectPlusRelatedType } from './getCollectPlusRelatedStores';
import { checkIsUkMobileNumber } from '../address/addressHelpers';
import featureConstants from '../../constants/featureConstants';
import userConstants from '../../constants/userConstants';

export default function shouldSkipCollectionPage(state) {
  if (!isFeatureActive(state, featureConstants.ENABLE_FULFILMENT_PREFERENCE)) {
    return false;
  }

  if (state.app.fulfilmentPreferenceHappened) {
    return false;
  }

  if (state.user.customerPreferences?.fulfilmentChoice !== userConstants.FULFILMENT_PREFERENCE_COLLECTION) {
    return false;
  }

  if (!state.user.collectionPoints.find(collectionPoint => collectionPoint.isDefault)) return false;

  if (!state.clickAndCollect.collectionPointDates) {
    return false;
  }

  if (state.clickAndCollect.collectionPointDatesEmpty) {
    return false;
  }

  if (!state.clickAndCollect.selectedCollectionPoint?.isAvailable) {
    return false;
  }

  // customer missing appropriate phone number
  if (isCollectPlusRelatedType(state.clickAndCollect?.selectedCollectionPoint?.ownerId)) {
    const defaultContactPhoneNumber = state.user?.defaultAddress?.phoneNumber;

    if (!checkIsUkMobileNumber(defaultContactPhoneNumber)) return false;
  }

  return true;
}
