import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export default function shouldSaveDefaultCollectionPoint(state) {
  const {
    user: {
      collectionPoints = [],
      isSignedIn,
    } = {},
  } = state;

  const defaultCollectionPoint = collectionPoints.find(point => point.isDefault);

  return isFeatureActive(state, featureConstants.SAVE_DEFAULT_COLLECTION_POINT) &&
    !defaultCollectionPoint &&
    isSignedIn;
}
