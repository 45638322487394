// jl-design-system
import replaceStringVars from 'jl-design-system/utils/string/replaceStringVars';
// constants
import paymentTypeConstants from '../../constants/paymentTypeConstants';

export default function getCardDetailsPayloadFromFormValues(formValues = {}, cardType) {

  const strippedCardNumber = (formValues.cardNumber || '').replace((/[^0-9]/g), '');
  const strippedExpiry = (formValues.expiryDate || '').replace((/[^0-9]/g), '');

  return {
    type: paymentTypeConstants.CREDIT_CARD,
    creditCardDetails: {
      number: strippedCardNumber,
      cardholderName: formValues.cardholderName,
      expiryDate: strippedExpiry,
      securityCode: formValues.securityCode,
      ...cardType && { cardType },
    },
  };
}

export function getLast4Digits(pan) {
  return pan.slice(-4);
}

export function getObfuscatedPanString(pan) {
  return replaceStringVars('**** **** **** ${last4Digits}', {
    last4Digits: getLast4Digits(pan),
  });
}
