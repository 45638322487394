import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Design System
import { PrimaryLink } from 'jl-design-system/elements/link/Link';
import { scrollTo } from 'jl-design-system/utils/scroll/scrollTo';

// Config
import sessionExpiredState from './SessionExpired.state';
import setPageTitle from '../../utils/helpers/pageTitle';

// Components
import Heading from '../../components/heading';

// Styles
import styles from './session-expired.scss';

const SessionExpired = () => {
  const { canonicalURL, showBackToPDP, showBackToBasket } = useSelector(sessionExpiredState);

  useEffect(() => {
    setPageTitle('Session expired');

    const timer = setTimeout(() => {
      scrollTo(0);
    }, 50);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.container}>
      <Heading
        label="Sorry, your session has expired"
        marginBottom="2"
        tag="h2"
        type="s"
      />
      {!showBackToPDP && (
        <p>
          We signed you out to keep your information secure.
          Please return to the basket to continue with your order.
        </p>
      )}
      {showBackToPDP && (
        <p>
          We signed you out to keep your information secure.
          Please return to the product page to continue with your order.
        </p>
      )}
      {showBackToBasket && !showBackToPDP && (
        <PrimaryLink
          external
          to={`${window.publicJLSiteDomain}/basket`}
        >
          Return to basket
        </PrimaryLink>
      )}
      {showBackToPDP && (
        <PrimaryLink
          external
          to={`${window.publicJLSiteDomain}/${canonicalURL}`}
        >
          Return to your product
        </PrimaryLink>
      )}
    </div>
  );
};

export default SessionExpired;
