// Types
import { RootState } from 'types/RootState.types';

export const getFormSelector = (state: RootState) => state.form;
export const getFormGuestFormSelector = (state: RootState) => state.form?.guestForm;
export const getFormSubmitFailedSelector =
    (state: RootState) => (state.form?.savedCardDetailsForm?.submitFailed ||
        state.form?.billingDetailsForm?.submitFailed) || false;
export const getPromoCodeEntrySelector = (state:RootState) => state.form?.promoCodeForm?.values || null;
export const getFormFieldValuesSelector = (
  state: RootState,
  formId: string,
) => state.form?.[formId]?.values;
