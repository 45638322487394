import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Checkbox from 'jl-design-system/elements/checkbox/Checkbox';

import { setGiftReceiptSelected } from '../../redux/actions/app/appActions';
import { giftReceiptSelected } from '../../redux/actions/delivery/deliveryActions';

export class GiftReceipt extends PureComponent {
  static propTypes = {
    ...Checkbox.propTypes,
    classNameOverride: PropTypes.string,
    giftFormId: PropTypes.string,
    giftReceiptSelected: PropTypes.func,
    // the onChange function here comes from redux form
    onChange: PropTypes.func,
    setGiftReceiptSelected: PropTypes.func,
  };

  static defaultProps = {
    ...Checkbox.defaultProps,
    classNameOverride: undefined,
    giftReceiptSelected: window.defaultFunc,
    onChange: window.defaultFunc,
    setGiftReceiptSelected: window.defaultFunc,
  };

  render() {
    const {
      giftFormId,
      giftReceiptSelected,
      onChange,
      setGiftReceiptSelected,
      classNameOverride,
    } = this.props;

    return (
      <div className={classNameOverride}>
        <Checkbox
          {...this.props}
          onChange={(evt) => {
            onChange(evt);

            const isSelected = !!evt;
            giftReceiptSelected(isSelected);
            setGiftReceiptSelected({ isSelected, giftFormId });
          }}
        />
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  };
}

export function mapDispatchToProps() {
  return {
    giftReceiptSelected,
    setGiftReceiptSelected,
  };
}

export default connect((state, ownProps) => mapStateToProps(state, ownProps), mapDispatchToProps())(GiftReceipt);
