import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
//
import { getItemsNeedingAgeVerification } from '../../actions/age-verification/ageVerificationActions';
import getUndeliverableItems from '../../../utils/delivery/getUndeliverableItems';
import setConfirmedDeliveryAddress from './setConfirmedDeliveryAddress';
import getSelectedFulfilmentOption from './getSelectedFulfilmentOption';
import deliveryContainsIncompatibleServices from './deliveryContainsIncompatibleServices';

function getDeliveriesState(state, action) {

  const orderForm = get(action, 'result.orderForm', {});
  const deliveries = get(orderForm, 'deliveries');
  const items = get(orderForm, 'items', []);
  const deliveryOptions = get(orderForm, 'deliveryOptions', {});

  const itemsNeedingAgeVerification = getItemsNeedingAgeVerification(items);
  const undeliverableItemsInBasket = getUndeliverableItems(deliveries, items);
  const confirmedDeliveryAddress = setConfirmedDeliveryAddress(state, action);
  const selectedDeliveryChoiceId = getSelectedFulfilmentOption({
    deliveryOptions,
    selectedDeliveryChoiceId: state.selectedDeliveryChoiceId,
    action,
  });

  const commonValues = {
    itemsNeedingAgeVerification,
    confirmedDeliveryAddress,
    deliveryOptions,
    selectedDeliveryChoiceId,
  };

  if (!isEmpty(undeliverableItemsInBasket)) {
    return {
      ...state,
      ...commonValues,
      undeliverableItemsInBasket,
    };
  }

  const removedServicesWithInfo = get(orderForm, 'removedServices', []);

  return {
    ...state,
    ...commonValues,
    undeliverableItemsInBasket: [],
    deliveryContainsIncompatibleServices: deliveryContainsIncompatibleServices(orderForm),
    removedServicesWithInfo: !isEmpty(removedServicesWithInfo) ?
      removedServicesWithInfo :
      state.removedServicesWithInfo,
  };
}

export default getDeliveriesState;
