import get from 'lodash/get';
//
import deliveryConstants from '../../../constants/deliveryConstants';

function deliveryContainsIncompatibleServices(orderForm) {
  const removedServices = get(orderForm, 'removedServices', []);
  return removedServices.some(service => service?.reason.includes(deliveryConstants.INCOMPATIBLE_SERVICE));
}

export default deliveryContainsIncompatibleServices;
