import assignIn from 'lodash/assignIn';
import get from 'lodash/get';
import { getDeliveryMethodText } from '../delivery/getDeliveryDetails';
import { isCollectPlusRelatedType } from '../collection/getCollectPlusRelatedStores';

import {
  isGreenVanFleet,
  isGreenVanFleetExCatchment,
  isGreenVanFleetUnavailable,
  isMadeToMeasureDelivery,
  isOneManDayDelivery,
  isOneManEveningDelivery,
  isOneManMorningDelivery,
  isOneManPremiumDelivery,
} from '../delivery/deliveryHelper';

function getDeliveryTypes(delivery) {
  const { fulfilment, type } = delivery;

  return {
    isGreenVanFleet: isGreenVanFleet(type),
    isGreenVanFleetExCatchment: isGreenVanFleetExCatchment(type, fulfilment),
    isGreenVanFleetUnavailable: isGreenVanFleetUnavailable(type, fulfilment),
    isMadeToMeasureDelivery: isMadeToMeasureDelivery(type),
    isOneManDayDelivery: isOneManDayDelivery(delivery),
    isOneManEveningDelivery: isOneManEveningDelivery(delivery),
    isOneManMorningDelivery: isOneManMorningDelivery(delivery),
    isOneManPremiumDelivery: isOneManPremiumDelivery(delivery),
  };
}

export function getGiftMessageLines(deliveries) {
  const deliveriesWithGiftMessage = deliveries.filter(delivery => get(delivery, 'giftMessageLines', []).length > 0);

  if (deliveriesWithGiftMessage.length > 1) {
    return 'Your order contains multiple gift messages';
  }

  return get(deliveriesWithGiftMessage, '[0].giftMessageLines');
}

function getDeliveryInstructions(deliveries) {
  const deliveriesWithDeliveryInstructions = deliveries.find(delivery => get(delivery, 'fulfilment.deliveryInfo.deliveryInstructions'));

  return get(deliveriesWithDeliveryInstructions, 'fulfilment.deliveryInfo.deliveryInstructions');
}

function getDeliveryDetails({ deliveries, isClickCollectOrder }) {
  return deliveries.map((delivery) => {
    const additionalInformation = get(delivery, 'fulfilment.additionalInformation', '');
    const deliveryInfo = get(delivery, 'fulfilment.deliveryInfo', {});
    const {
      collectionDateTime,
      ownerId,
    } = get(delivery, 'fulfilment.collectionInfo', {});
    const deliveryTypes = getDeliveryTypes(delivery);
    const method = get(delivery, 'fulfilment.description', '');
    const oneManPremiumDeliverySelectedDate = get(delivery, 'fulfilment.deliveryInfo.date');
    const {
      id,
      isGiftReceiptOrder,
      isGvfSlotGreaterThanTwoHours,
      items,
      type,
    } = delivery;

    const deliveryMethodText = getDeliveryMethodText({
      isGreenVanFleet: deliveryTypes.isGreenVanFleet,
      isGreenVanFleetExCatchment: deliveryTypes.isGreenVanFleetExCatchment,
      isGreenVanFleetUnavailable: deliveryTypes.isGreenVanFleetUnavailable,
      isMadeToMeasureDelivery: deliveryTypes.isMadeToMeasureDelivery,
      method,
      deliveryInfo,
    });

    const deliveryInfoText = {
      additionalInformation,
      deliveryInfo,
      collectionTime: collectionDateTime,
      ownerId,
      oneManPremiumDeliverySelectedDate,
      isClickCollectOrder,
      isGvfSlotGreaterThanTwoHours,
      ...deliveryTypes,
    };

    return {
      additionalInformation,
      charge: get(delivery, 'fulfilment.charge', ''),
      deliveryDescription: method,
      deliveryInfo,
      deliveryInfoText,
      deliveryMethodText,
      giftMessageLines: get(delivery, 'giftMessageLines', []),
      id,
      isGiftReceiptOrder,
      isGvfSlotGreaterThanTwoHours,
      items,
      oneManPremiumDeliverySelectedDate,
      deliveryInstructions: get(deliveryInfo, 'deliveryInstructions', ''),
      type,
      ...deliveryTypes,
    };
  });
}

export default function getOrderDetails({
  billingAddress,
  deliveries,
  deliveryAddress,
  isClickCollectOrder = false,
  payments,
}) {
  const deliveriesLength = deliveries.length;
  const isMultiBatch = deliveries.length > 1;
  const { address, addressee, phoneNumber } = deliveryAddress;

  const orderDetails = {
    address,
    addressee,
    billingAddress,
    deliveriesLength,
    isClickCollectOrder,
    isMultiBatch,
    payments,
    phoneNumber,
    deliveries: getDeliveryDetails({ address, deliveries, isClickCollectOrder }),
    giftMessageLines: getGiftMessageLines(deliveries),
    deliveryInstructions: getDeliveryInstructions(deliveries),
  };

  if (isMultiBatch) {
    assignIn(orderDetails, {
      deliveryInfoText: 'Not all items can arrive together as they’re dispatched from different warehouses.',
      deliveryMethodText: `Your order will be separated into ${deliveriesLength} deliveries.`,
    });
  } else {
    assignIn(orderDetails, {
      deliveryInfoText: orderDetails.deliveries[0].deliveryInfoText,
      deliveryMethodText: orderDetails.deliveries[0].deliveryMethodText,
    });
  }

  if (isClickCollectOrder) {
    const collectionTime = get(deliveries[0], 'fulfilment.collectionInfo.collectionDateTime', '');
    const ownerId = get(deliveries[0], 'fulfilment.collectionInfo.ownerId', 0);
    assignIn(orderDetails, {
      collectionTime,
      ownerId,
      isCollectPlusOrder: isCollectPlusRelatedType(ownerId),
    });
  }

  return orderDetails;
}
