import deliveryConstants from '../../../constants/deliveryConstants';
import userConstants from '../../../constants/userConstants';

function getSelectedFulfilmentOption(options) {
  const {
    deliveryOptions,
    selectedDeliveryChoiceId,
    action = {},
  } = options;

  const {
    clickCollectAvailable,
    collectPlusAvailable,
  } = deliveryOptions;

  const defaultDeliveryTabEnabled = action.defaultDeliveryTabEnabled || (
    action.result?.customer?.preferences?.fulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_DELIVERY &&
    action.fulfilmentPreferenceFeatureActive &&
    action.result?.customer?.addressBook?.find(addressRecord => addressRecord.defaultShipping)
  );

  if (!clickCollectAvailable && !collectPlusAvailable) {
    // only possible choice as collection is unavailable
    return deliveryConstants.DELIVERY;
  }

  if (!selectedDeliveryChoiceId) {
    if (defaultDeliveryTabEnabled) {
      return deliveryConstants.DELIVERY;
    }

    return deliveryConstants.CLICK_AND_COLLECT;
  }

  // will return either an existing choice or the default state of undefined
  return selectedDeliveryChoiceId;
}

export default getSelectedFulfilmentOption;
