import isPayPalAvailable from './isPayPalAvailable';

export default function isBasketPayPalPayLaterEligible(state) {

  const {
    delivery: {
      deliveriesWithClearabee = [],
    } = {},
    orderForm: {
      amounts: {
        outstandingBalanceRaw,
      } = {},
    } = {},
  } = state ?? {};

  if (!isPayPalAvailable(state)) {
    return false;
  }

  if (deliveriesWithClearabee.length > 0) return false;

  if (outstandingBalanceRaw < 30 || outstandingBalanceRaw > 2000) {
    return false;
  }

  return true;

}
