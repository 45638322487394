// lodash
import get from 'lodash/get';
//
import { isApps } from '../../redux/reducers/app/appReducer';
import loginTypeConstants from '../../constants/loginTypeConstants';
import { MOB, DROID, WEB } from '../../constants/applicationTypeConstants';

const getMvtFeatures = (state) => {
  const features = get(state, 'config.features', []);
  const mvtFeatures = features?.filter(feature => feature.source === 'MVT');
  return mvtFeatures.reduce((acc, feature) => ({ ...acc, [feature.id]: feature.enabled }), {});
};

export const logToConsole = (response) => {
  if (!window.publicJLSiteDomain?.includes('johnlewis')) {
    /* eslint-disable no-console */
    console.log(response);
  }
};

export function stringifyObject(object = {}) {
  return JSON.stringify(object);
}

export function setUserInformationToNewRelic(state) {
  if (window.newrelic && window.newrelic.setCustomAttribute) {
    const sessionId = get(state, 'bff.sessionId', '');
    const isAppOrder = isApps(state);
    const isGuest = get(state, 'user.isGuest');
    const applicationType = get(state, 'app.applicationType');

    let loginType = get(state, 'login.loginType');

    if (isAppOrder) {
      loginType = loginTypeConstants.CUSTOMER_APPS;
    }

    const experiences = stringifyObject({
      ...getMvtFeatures(state),
    });

    // TODO change applicationTypeConstants in conjunction with backend to be consistent and better named
    const applicationTypes = new Map();
    applicationTypes.set(DROID, 'android');
    applicationTypes.set(MOB, 'ios');
    applicationTypes.set(WEB, 'web');

    // booleans must be sent as strings
    window.newrelic.setCustomAttribute('sessionId', sessionId);
    window.newrelic.setCustomAttribute('isGuest', `${isGuest}`);
    window.newrelic.setCustomAttribute('experiences', `${experiences}`);
    if (loginType) window.newrelic.setCustomAttribute('loginType', loginType);
    if (applicationType) window.newrelic.setCustomAttribute('applicationType', applicationTypes.get(applicationType));
  }
}

export function sendNewRelicCustomEvent(actionName, attributes) {
  if (window.newrelic && window.newrelic.addPageAction) {
    window.newrelic.addPageAction(actionName, attributes);
  }
}

export function createNewRelicTrackingObject() {
  if (window.newrelic && window.newrelic.interaction) {
    return window.newrelic.interaction();
  }
  return false;
}

export function saveNewRelicTrackingEvent(trackingObject) {
  if (trackingObject) {
    trackingObject.save();
  }
}

export function endNewRelicTrackingEvent(trackingObject) {
  if (trackingObject) {
    trackingObject.end();
  }
}

export function setCorrelationIdToNewRelic(id) {
  if (window.newrelic && window.newrelic.interaction) {
    const newRelicInteraction = window.newrelic.interaction();
    newRelicInteraction.setAttribute('correlationId', id);
  }
}

export const reportErrorToNewRelic = ({ error, errorDescription, options = {} }) => (dispatch, getState) => {
  if (window.newrelic && window.newrelic.noticeError) {

    const sessionId = get(getState(), 'bff.sessionId');
    const timestamp = Date.now();
    const { pathname } = window.location;
    const { userAgent } = window.navigator;

    const params = {
      timestamp,
      path: (pathname || '').replace(/(\?.*)|(#.*)/g, ''),
      userAgent,
      sessionId,
      errorDescription,
      ...options,
    };

    window.newrelic.noticeError(error, params);
  }
};

export const initLogging = () => (dispatch, getState) => {
  setUserInformationToNewRelic(getState());
  return dispatch({ type: 'INIT_LOGGING' });
};
