import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import {
  SAVE_PREFERENCES_COLLECTION,
  SAVE_PREFERENCES_DELIVERY,
  SAVE_PREFERENCES_PAYMENT_CARD,
  SAVE_PREFERENCES_PAYMENT_METHOD,
  DELETE_PREFERENCES,
} from '../../../constants/actionConstants';
import {
  URL_PREFERENCES_COLLECTION,
  URL_PREFERENCES_DELIVERY,
  URL_PREFERENCES_PAYMENT_CARD,
  URL_PREFERENCES_DELETE,
  URL_PREFERENCES_PAYMENT_METHOD,
} from '../../../constants/endpointConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import featureConstants from '../../../constants/featureConstants';
import userConstants from '../../../constants/userConstants';
import {
  SAVE_AS_DEFAULT_FORM_ID,
} from '../../../utils/form/configs/saveAsDefault';
import { isFeatureActive } from '../../reducers/config/configReducer';
import { recordImpressions } from '../app/appActions';
import shouldSaveDefaultCollectionPoint from '../../../utils/collection/shouldSaveDefaultCollectionPoint';

export const deletePreferences = () => ({
  type: DELETE_PREFERENCES,
  request: client => client({ path: URL_PREFERENCES_DELETE, config: { method: 'DELETE' } }),
});

export const savePreferencesCollection = body => ({
  type: SAVE_PREFERENCES_COLLECTION,
  request: client => client({ path: URL_PREFERENCES_COLLECTION, config: { method: 'POST', body } }),
});

export const savePreferencesDelivery = body => ({
  type: SAVE_PREFERENCES_DELIVERY,
  request: client => client({ path: URL_PREFERENCES_DELIVERY, config: { method: 'POST', body } }),
});

export const savePreferencesCard = body => ({
  type: SAVE_PREFERENCES_PAYMENT_CARD,
  request: client => client({ path: URL_PREFERENCES_PAYMENT_CARD, config: { method: 'POST', body } }),
});

export const savePreferencesPaymentMethod = () => async (dispatch, getState) => {

  const selectedPaymentType = getState().payment.selectedPaymentType;
  const paymentChoice = getState().user.customerPreferences?.paymentChoice;

  let newPaymentPreference;

  const {
    PAYPAL,
    APPLE_PAY,
    GOOGLE_PAY,
    SAVED_PAYMENT_CARD,
  } = paymentTypeConstants;

  const {
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  } = userConstants;

  switch (selectedPaymentType) {
    case PAYPAL:
      if (paymentChoice !== PAYMENT_PREFERENCE_PAYPAL) {
        newPaymentPreference = PAYMENT_PREFERENCE_PAYPAL;
      }
      break;
    case APPLE_PAY:
      if (paymentChoice !== PAYMENT_PREFERENCE_APPLE_PAY) {
        newPaymentPreference = PAYMENT_PREFERENCE_APPLE_PAY;
      }
      break;
    case GOOGLE_PAY:
      if (paymentChoice !== PAYMENT_PREFERENCE_GOOGLE_PAY) {
        newPaymentPreference = PAYMENT_PREFERENCE_GOOGLE_PAY;
      }
      break;
    default:
      if (selectedPaymentType.includes(SAVED_PAYMENT_CARD) && paymentChoice !== PAYMENT_PREFERENCE_SAVED_CARD) {
        newPaymentPreference = PAYMENT_PREFERENCE_SAVED_CARD;
      }
  }

  if (!newPaymentPreference) return;

  const body = { paymentPreference: newPaymentPreference };

  dispatch({
    type: SAVE_PREFERENCES_PAYMENT_METHOD,
    request: client => client({ path: URL_PREFERENCES_PAYMENT_METHOD, config: { method: 'POST', body } }),
  });
};

export function shouldSavePreferenceOrUpdateAddress(state) {
  const selectedPaymentType = get(state, 'payment.selectedPaymentType');

  if (selectedPaymentType) {
    const parsedSelectedPaymentType = selectedPaymentType.split(':');
    const selectedPaymentTypeName = parsedSelectedPaymentType[0];
    const selectedPaymentCardId = parsedSelectedPaymentType[1];

    if (selectedPaymentTypeName === paymentTypeConstants.SAVED_PAYMENT_CARD) {
      const savedCards = get(state, 'user.savedPaymentCards');
      const savedDefaultCard = get(state, 'user.defaultSavedPaymentDetail.id');
      const savedCardAddressId = savedCards?.find(card => card.id === selectedPaymentCardId)?.addressId;
      const billingAddressId = get(state, 'user.selectedBillingAddress.id');

      const shouldSaveWithNewAddress = !isEqual(savedCardAddressId, billingAddressId);
      const shouldUpdateCardPreference = !isEqual(selectedPaymentCardId, savedDefaultCard);
      if (shouldSaveWithNewAddress || shouldUpdateCardPreference) {
        return { billingAddressId, selectedPaymentCardId, shouldUpdateCardPreference };
      }
    }
  }

  return {};
}

export function getPaymentCardBody(shouldUpdateCardPreference, billingAddressId, selectedPaymentCardId) {
  return {
    cardId: selectedPaymentCardId,
    ...shouldUpdateCardPreference && { default: true },
    ...billingAddressId && { addressId: billingAddressId },
  };
}

export function shouldSaveNonDefaultCollectionPoint(params) {
  const {
    savedCollectionPoints = [],
    confirmedCollectionPoint,
  } = params;

  if (!confirmedCollectionPoint) return null;

  const collectionPointAlreadySaved = !!savedCollectionPoints.find(point => point.id === confirmedCollectionPoint.id);
  return !collectionPointAlreadySaved;
}

export const handleDeliveryFulfilmentPreferences = ({ createAccount = false } = {}) => (dispatch, getState) => {
  const {
    createAccount: {
      userAddress = {},
    } = {},
    delivery: {
      confirmedDeliveryAddress = {},
    } = {},
    user: {
      customerPreferences: {
        fulfilmentChoice,
      } = {},
    },
  } = getState();

  if (fulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_DELIVERY) return {};

  const deliveryAddress = createAccount ? userAddress : confirmedDeliveryAddress;
  if (deliveryAddress?.id) {
    return dispatch(savePreferencesDelivery({
      addressId: deliveryAddress?.id,
      preferredFulfilmentOption: true,
      default: true,
    }));
  }

  return {};
};

export const handleCollectionFulfilmentPreferences = () => async (dispatch, getState) => {
  const {
    clickAndCollect: {
      confirmedCollectionPoint,
    },
    user: {
      customerPreferences: {
        fulfilmentChoice,
      } = {},
    },
  } = getState();

  if (fulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_COLLECTION) return {};

  return dispatch(savePreferencesCollection({
    collectionPointId: confirmedCollectionPoint.id,
    preferredFulfilmentOption: true,
    default: true,
  }));
};

export const saveDeliveryPreferences = ({ createAccount = false } = {}) => (dispatch, getState) => {
  const {
    addressBook: {
      addressIdToBeSavedAsDefault,
    } = {},
    createAccount: {
      userAddress = {},
    } = {},
    delivery: {
      confirmedDeliveryAddress = {},
    } = {},
  } = getState();

  const fulfilmentPreferenceFeatureActive = isFeatureActive(getState(), featureConstants.ENABLE_FULFILMENT_PREFERENCE);

  if (fulfilmentPreferenceFeatureActive) {
    return dispatch(handleDeliveryFulfilmentPreferences({ createAccount }));
  }

  const saveAddressAsDefaultId = createAccount ? userAddress?.id : addressIdToBeSavedAsDefault;

  if (saveAddressAsDefaultId) {
    return dispatch(savePreferencesDelivery({
      addressId: saveAddressAsDefaultId,
      preferredFulfilmentOption: true,
      default: true,
    }));
  }

  return dispatch(savePreferencesDelivery({
    addressId: confirmedDeliveryAddress?.id,
    preferredFulfilmentOption: true,
    default: false,
  }));
};

export const saveCollectionPreferences = () => (dispatch, getState) => {
  const fulfilmentPreferenceFeatureActive = isFeatureActive(getState(), featureConstants.ENABLE_FULFILMENT_PREFERENCE);

  if (fulfilmentPreferenceFeatureActive) {
    return dispatch(handleCollectionFulfilmentPreferences());
  }

  const collectionPointId = get(getState(), `form.${SAVE_AS_DEFAULT_FORM_ID}.values.saveCollectionPointAsDefault`);
  if (collectionPointId) {
    return dispatch(savePreferencesCollection({
      collectionPointId,
      preferredFulfilmentOption: true,
      default: true,
    }));
  }

  const savedCollectionPoints = getState().user?.collectionPoints;
  const confirmedCollectionPoint = getState().clickAndCollect?.confirmedCollectionPoint;

  if (shouldSaveDefaultCollectionPoint(getState())) {
    return dispatch(savePreferencesCollection({
      collectionPointId: confirmedCollectionPoint.id,
      preferredFulfilmentOption: true,
      default: true,
    }));
  }

  if (shouldSaveNonDefaultCollectionPoint({ savedCollectionPoints, confirmedCollectionPoint })) {
    return dispatch(savePreferencesCollection({
      collectionPointId: confirmedCollectionPoint.id,
      preferredFulfilmentOption: true,
      default: false,
    }));
  }

  return {};
};

export const saveCardPreferences = () => (dispatch, getState) => {
  // Save card as default or update saved card address
  const {
    billingAddressId,
    selectedPaymentCardId,
    shouldUpdateCardPreference,
  } = shouldSavePreferenceOrUpdateAddress(getState());
  const shouldUpdateCardAddress = !!billingAddressId && !!selectedPaymentCardId;

  if (shouldUpdateCardPreference || shouldUpdateCardAddress) {
    const paymentCardBody = getPaymentCardBody(shouldUpdateCardPreference, billingAddressId, selectedPaymentCardId);
    dispatch(savePreferencesCard(paymentCardBody));
  }
};

export const recordFulfilmentImpressionId = () => (dispatch, getState) => {
  const {
    app: {
      recordedImpressionIds,
    } = {},
    config: {
      features,
    } = {},
    user: {
      customerPreferences: {
        fulfilmentChoice,
      } = {},
      isSignedIn,
    } = {},
  } = getState();

  if (!isSignedIn) return null;

  if (![
    userConstants.FULFILMENT_PREFERENCE_DELIVERY,
    userConstants.FULFILMENT_PREFERENCE_COLLECTION,
  ].includes(fulfilmentChoice)) return null;

  const fulfilmentFeature = features?.find(feature => feature.id === featureConstants.ENABLE_FULFILMENT_PREFERENCE);
  const impressionId = fulfilmentFeature?.impressionId;

  if (!fulfilmentFeature?.impressionId) return null;

  if (recordedImpressionIds?.includes(impressionId)) return null;

  return dispatch(recordImpressions(impressionId));
};
