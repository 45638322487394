import React from 'react';
import get from 'lodash/get';

// Design System
import amexLogo from 'jl-design-system/assets/images/payment-icons/amex.svg';
import applePayLogo from 'jl-design-system/assets/images/payment-icons/apple-pay.svg';
import businessAccountLogo from 'jl-design-system/assets/images/payment-icons/business-account.png';
import googlePayLogo from 'jl-design-system/assets/images/payment-icons/google-pay.svg';
import maestroLogo from 'jl-design-system/assets/images/payment-icons/maestro.svg';
import mastercardLogo from 'jl-design-system/assets/images/payment-icons/mastercard.svg';
import partnership2022Logo from 'jl-design-system/assets/images/payment-icons/partnership-2022.png';
import paypalAccountLogo from 'jl-design-system/assets/images/payment-icons/paypal.svg';
import visaLogo from 'jl-design-system/assets/images/payment-icons/visa.svg';

// Types
import { PaymentDetailsProps } from './PaymentDetails.types';

// Config
import clearpayLogo from '../../assets/images/logos/clearpay.png';
import paymentTypeConstants from '../../constants/paymentTypeConstants';
import supportedCreditCardTypes from '../../constants/supportedCreditCardTypes';

// Components
import CreditRemainingAmount from '../account-credit-remaining-amount';

// Styles
import styles from './payment-details.scss';

const PaymentDetails = ({
  payments = [],
}: PaymentDetailsProps) => {
  const cardTypeMapper = (cardType: string) => ({
    [supportedCreditCardTypes.AMERICAN_EXPRESS]: amexLogo,
    [supportedCreditCardTypes.MAESTRO]: maestroLogo,
    [supportedCreditCardTypes.MASTERCARD]: mastercardLogo,
    [supportedCreditCardTypes.ANY_DAY]: mastercardLogo,
    [supportedCreditCardTypes.NEW_DAY]: partnership2022Logo,
    [supportedCreditCardTypes.VISA]: visaLogo,
    [supportedCreditCardTypes.VISA_DEBIT]: visaLogo,
    [supportedCreditCardTypes.BUSINESS_ACCOUNT]: businessAccountLogo,
    paypal: paypalAccountLogo,
    clearpay: clearpayLogo,
  }[cardType.toLowerCase()]);

  const paymentItems = payments.map((payment = {}) => {
    const paymentType: string = get(payment, 'type', '').split(':')[0];

    let imageProps;

    switch (paymentType) {
      case paymentTypeConstants.CREDIT_CARD:
      case paymentTypeConstants.TOKENIZED_PAYMENT_CARD:
      case paymentTypeConstants.SAVED_PAYMENT_CARD: {
        const type = get(payment, 'details.creditCardType', '');

        imageProps = {
          src: cardTypeMapper(type),
          alt: type,
          className: type === supportedCreditCardTypes.BUSINESS_ACCOUNT ?
            styles.paymentTypeLogoBusinessAccount : styles.paymentTypeLogo,
        };
        break;
      }
      case paymentTypeConstants.PAYPAL:
        imageProps = {
          src: paypalAccountLogo,
          alt: paymentType,
          className: styles.paymentTypeLogo,
        };
        break;
      case paymentTypeConstants.APPLE_PAY:
        imageProps = {
          src: applePayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogoApplePay,
        };
        break;
      case paymentTypeConstants.GOOGLE_PAY:
        imageProps = {
          src: googlePayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogoGooglePay,
        };
        break;
      case paymentTypeConstants.CLEARPAY:
        imageProps = {
          src: clearpayLogo,
          alt: paymentType,
          className: styles.paymentTypeLogo,
        };
        break;

      default:
    }

    const cardType =
      paymentType === paymentTypeConstants.CREDIT_CARD ||
      paymentType === paymentTypeConstants.TOKENIZED_PAYMENT_CARD ||
      paymentType === paymentTypeConstants.SAVED_PAYMENT_CARD;

    const paymentTypeLabels: Record<string, string> = {
      clearpay: 'Clearpay',
      giftCard: 'Gift card',
      giftVoucher: 'Gift voucher',
      posCredit: 'John Lewis & Partners Payment Plans',
    };

    const cardWithRemainingBalance = get(payment, 'cardWithRemainingBalance');

    const isPosCredit = paymentType?.includes(paymentTypeConstants.POS_CREDIT) || false;
    const amountLabel = isPosCredit ? 'Agreement no.' : 'Amount:';
    const amountText = isPosCredit ? `ending in ${get(payment, 'posCreditDetails.agreementNumberLastDigits')}` :
      payment?.amount;

    const paymentTypeConfig = {
      [paymentTypeConstants.TOKENIZED_PAYMENT_CARD]: {
        cardTestId: 'payment-details-item-tokenized-card',
        cardNumberPath: 'details.maskedNumber',
        sliceStart: -4,
      },
      [paymentTypeConstants.CREDIT_CARD]: {
        cardTestId: 'payment-details-item-card',
        cardNumberPath: 'details.lastDigits',
      },
      [paymentTypeConstants.SAVED_PAYMENT_CARD]: {
        cardTestId: 'payment-details-item-card',
        cardNumberPath: 'details.maskedNumber',
        sliceStart: -4,
      },
    };

    const config = paymentTypeConfig[paymentType] || {};
    let cardNumber = get(payment, config.cardNumberPath, '');
    const cardTestId = config.cardTestId;
    if (config.sliceStart) {
      cardNumber = cardNumber.slice(config.sliceStart);
    }

    return (
      <div key={paymentType}>
        <dl data-test="payment-details-item">
          {(!cardType || (cardType && !!cardTypeMapper(get(payment, 'details.creditCardType', '')))) && (
            <div data-test="payment-details-item-type">
              <dt>Payment type:</dt>
              {imageProps ? (
                <dd>
                  <img
                    {...imageProps}
                    alt={imageProps.alt}
                    data-test={`payment-details-image-${paymentType}`}
                  />
                </dd>
              ) : (
                <dd>
                  {paymentTypeLabels[paymentType]}
                </dd>
              )}
            </div>
          )}

          {cardNumber && (
            <div data-test={cardTestId}>
              <dt>Card number:</dt>
              <dd>{cardNumber}</dd>
            </div>
          )}

          <div data-test="payment-details-item-amount">
            <dt>{amountLabel}</dt>
            <dd>{amountText}</dd>
          </div>

          {paymentType === paymentTypeConstants.GIFT_CARD && cardWithRemainingBalance && (
            <CreditRemainingAmount
              amount={cardWithRemainingBalance.remainingBalance}
              cardEnding={cardWithRemainingBalance.endNumber}
              className={styles.creditRemaining}
              data-test="payment-details-gift-card-credit-remaining"
              type={paymentTypeConstants.GIFT_CARD}
            />
          )}
        </dl>
      </div>
    );
  });

  return (
    <div className={styles.container}>
      {paymentItems}
    </div>
  );
};

export default PaymentDetails;
