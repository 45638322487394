import React from 'react';

// Design System
import { PrimaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { EmptyBasketNotificationProps } from './EmptyBasketNotification.types';

// Components
import Heading from '../heading';

// Styles
import styles from './empty-basket-notification.scss';

const EmptyBasketNotification = ({
  handleButtonClick = () => {},
}: EmptyBasketNotificationProps) => (
  <div className={styles.container} data-test="empty-basket-notification-container">
    <Heading
      label="Your basket is empty"
      marginBottom="2"
      tag="h2"
      type="s"
    />

    <PrimaryButton
      className={styles.button}
      onClick={handleButtonClick}
    >
      Return to homepage
    </PrimaryButton>
  </div>
);

export default EmptyBasketNotification;
