// lodash
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import analyticsTrigger, {
  getAnalyticsEventObject,
  updateWindowDataLayer,
} from '../../../utils/analytics/trigger/analyticsTrigger';
import {
  GET_LOGIN_PAGE,
  GET_DELIVERY_PAGE,
  GET_DELIVERY_METHODS,
  GET_ORDER_CONFIRMATION_PAGE,
  ANALYTICS_PAGE_NOT_FOUND,
  ANALYTICS_FORCE_UPDATE_SYNC_ERRORS,
  PUT_DELIVERY_ADDRESS,
  REDUX_FORM_SET_SUBMIT_FAILED,
  ANALYTICS_SET_SUBMIT_FAILED,
  ROUTER_LOCATION_CHANGE,
  POST_DELIVERY_PAGE,
  HAND_OVER,
  PUT_DELIVERY_DETAILS,
  PUT_CC_DELIVERY_DETAILS,
  INIT_PAYMENT_PAGE,
  GET_PAYMENT_PAGE,
  GET_MINIMAL_ORDER_CONFIRMATION_PAGE,
} from '../../../constants/actionConstants';
import routeConstants from '../../../constants/routeConstants';

import { BILLING_ADDRESS_FORM_ID } from '../../../utils/form/configs/billingAddress';
import { CARD_DETAILS_FORM_ID, PREPAID_CARD_DETAILS_FORM_ID } from '../../../utils/form/configs/cardDetails';
import { PROMO_CODE_FORM_ID } from '../../../utils/form/configs/promoCode';


const allowedReduxActionTypes = [
  REDUX_FORM_SET_SUBMIT_FAILED,
  '@@redux-form/UPDATE_SYNC_ERRORS',
];
const LOGIN_ROUTES = [routeConstants.LOGIN];

const NON_LOGIN_ROUTES = [
  routeConstants.PAYMENT,
  routeConstants.DELIVERY,
  routeConstants.DELIVERY_CHOICE,
  routeConstants.CLICK_AND_COLLECT,
  routeConstants.CLICK_AND_COLLECT_SEARCH_LIST,
  routeConstants.CLICK_AND_COLLECT_STORE_SELECTED,
  routeConstants.ORDER_CONFIRMATION,
  routeConstants.JOIN_MY_JL,
];
const ADDRESS_BOOK_ROUTES = [
  routeConstants.DELIVERY_ADDRESS_BOOK,
  routeConstants.DELIVERY_ADDRESS_BOOK_EDIT,
  routeConstants.DELIVERY_ADDRESS_BOOK_NEW,
  routeConstants.PAYMENT_ADDRESS_BOOK,
  routeConstants.PAYMENT_ADDRESS_BOOK_EDIT,
  routeConstants.PAYMENT_ADDRESS_BOOK_NEW,
];
const COLLECTION_POINT_ROUTES = [
  routeConstants.CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS,
  routeConstants.CLICK_AND_COLLECT_SEARCH_LIST,
  routeConstants.CLICK_AND_COLLECT_STORE_SELECTED,
];
const VALID_ROUTES = [
  ...LOGIN_ROUTES,
  ...NON_LOGIN_ROUTES,
  ...ADDRESS_BOOK_ROUTES,
  ...COLLECTION_POINT_ROUTES,
];
const REPLACE_ANALYTICS_ACTIONS = [
  `${GET_LOGIN_PAGE}.SUCCESS`,
  `${GET_DELIVERY_PAGE}.SUCCESS`,
  `${POST_DELIVERY_PAGE}.SUCCESS`,
  `${GET_ORDER_CONFIRMATION_PAGE}.SUCCESS`,
  `${PUT_DELIVERY_ADDRESS}.SUCCESS`,
  `${GET_DELIVERY_METHODS}.SUCCESS`,
  ANALYTICS_PAGE_NOT_FOUND,
];
// ignore normal validation error analytics for these forms
// they have a custom implementation
const IGNORE_SET_SUBMIT_FAILED_IDS = [
  BILLING_ADDRESS_FORM_ID,
  CARD_DETAILS_FORM_ID,
  PREPAID_CARD_DETAILS_FORM_ID,
  PROMO_CODE_FORM_ID,
];

export const INITIAL_STATE = {
  loginAnalyticsFetched: false,
  route: {
    pathname: '',
    search: '',
  },
  formValidationErrorMessages: {},
  analyticsData: {},
  ignoreSetSubmitFailed: false,
  proceedingToPayment: false,
};

export function getProceedingToPayment(actionType, currentState) {
  if ([
    `${PUT_DELIVERY_DETAILS}.LOADING`,
    `${PUT_CC_DELIVERY_DETAILS}.LOADING`,
  ].includes(actionType)) {
    return true;
  }

  if ([
    `${PUT_DELIVERY_DETAILS}.FAILURE`,
    `${PUT_CC_DELIVERY_DETAILS}.FAILURE`,
    `${INIT_PAYMENT_PAGE}.FAILURE`,
    `${INIT_PAYMENT_PAGE}.SUCCESS`,
    `${GET_PAYMENT_PAGE}.FAILURE`,
    `${GET_PAYMENT_PAGE}.SUCCESS`,
  ].includes(actionType)) {
    return false;
  }

  if ([
    `${PUT_DELIVERY_DETAILS}.SUCCESS`,
    `${PUT_CC_DELIVERY_DETAILS}.SUCCESS`,
  ].includes(actionType)) {
    return true;
  }

  return currentState;
}

export default function analyticsReducer(state = INITIAL_STATE, action = {}) {
  // console.warn('******************* actionType:', action.type, action);

  const actionType = action.type || '';

  // ignore these action types
  if (
    actionType === '@@INIT'
    ||
    (
      actionType === REDUX_FORM_SET_SUBMIT_FAILED &&
      IGNORE_SET_SUBMIT_FAILED_IDS.includes(get(action, 'meta.form'))
    )
    ||
    (
      actionType.includes('@@redux') &&
      !allowedReduxActionTypes.includes(actionType)
    )
  ) {
    return state;
  }

  // stash form validation errors when they occur
  // for use when @@redux-form/SET_SUBMIT_FAILED is triggered
  if (
    actionType === '@@redux-form/UPDATE_SYNC_ERRORS' ||
    actionType === ANALYTICS_FORCE_UPDATE_SYNC_ERRORS
  ) {
    const formID = get(action, 'meta.form');
    const syncErrors = get(action, 'payload.syncErrors');
    return {
      ...state,
      formValidationErrorMessages: {
        ...state.formValidationErrorMessages,
        [formID]: (!syncErrors) ? {} : { ...syncErrors },
      },
    };
  }

  // TODO: JH - 9/10/17: MARV-1873: If all analytics is returned with same key name ('analytics') this can be simplified
  let analytics =
    get(action, 'analytics') ||
    get(action.result, 'analytics') ||
    state.analyticsData;

  // For `/methods` calls, response data should merge with the existing analytics data
  if (action.mergeCustomerInfo) {
    analytics = merge(cloneDeep(get(action.result, 'analytics')), cloneDeep(state.analyticsData));
  }

  const replaceAnalyticsData = REPLACE_ANALYTICS_ACTIONS.includes(actionType);

  if (actionType === ROUTER_LOCATION_CHANGE) {
    const pathname = get(action.payload, 'location.pathname', '');

    if (VALID_ROUTES.includes(pathname)) {

      const search = get(action.payload, 'location.search', '');
      const waitForAnalytics =
        (LOGIN_ROUTES.includes(pathname) && !state.loginAnalyticsFetched) ||
        NON_LOGIN_ROUTES.includes(pathname);

      const nextState = {
        ...state,
        route: {
          pathname,
          search,
        },
        formValidationErrorMessages: {},
      };

      const eventObject = getAnalyticsEventObject({ action, state: nextState });

      if (!waitForAnalytics) {
        analyticsTrigger(eventObject);
      }

      return {
        ...nextState,
        analyticsData: eventObject.data,
      };
    }

    return state;
  }

  switch (actionType) {

    case `${GET_LOGIN_PAGE}.LOADING`:
      return {
        ...state,
        loginAnalyticsFetched: false,
      };

    case `${GET_LOGIN_PAGE}.SUCCESS`: {
      const eventObject = getAnalyticsEventObject({
        action, state, analytics, replaceAnalyticsData,
      });
      analyticsTrigger(eventObject);
      return {
        ...state,
        loginAnalyticsFetched: true,
        analyticsData: eventObject.data,
      };
    }

    case `${HAND_OVER}.SUCCESS`: {
      const eventObject = getAnalyticsEventObject({
        action, state, analytics, replaceAnalyticsData,
      });
      updateWindowDataLayer(eventObject);
      return {
        ...state,
        analyticsData: eventObject.data,
      };
    }

    case GET_MINIMAL_ORDER_CONFIRMATION_PAGE: {
      const eventObject = getAnalyticsEventObject({
        action,
        state: {
          ...state,
          passwordlessOrderConfirmedAndSaved: action.orderSaved,
        },
        analytics,
        replaceAnalyticsData,
      });

      analyticsTrigger(eventObject);

      return {
        ...state,
        analyticsData: eventObject.data,
        passwordlessOrderConfirmedAndSaved: true,
      };
    }

    default: {

      // reset ignoreSetSubmitFailed if previously set to true by ANALYTICS_SET_SUBMIT_FAILED
      if (actionType === REDUX_FORM_SET_SUBMIT_FAILED && state.ignoreSetSubmitFailed) {
        return {
          ...state,
          ignoreSetSubmitFailed: false,
        };
      }

      const proceedingToPayment = getProceedingToPayment(
        actionType,
        state.proceedingToPayment,
      );

      const eventObject = getAnalyticsEventObject({
        action, state, analytics, replaceAnalyticsData,
      });

      analyticsTrigger(eventObject);

      return {
        ...state,
        analyticsData: eventObject.data,
        ignoreSetSubmitFailed: (actionType === ANALYTICS_SET_SUBMIT_FAILED),
        proceedingToPayment,
        ...action.passwordlessOrderConfirmedAndSaved &&
          { passwordlessOrderConfirmedAndSaved: action.passwordlessOrderConfirmedAndSaved },
      };
    }
  }
}
