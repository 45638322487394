import React from 'react';

// Design System
import Modal from 'jl-design-system/components/modal/Modal';
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import reload from 'jl-design-system/utils/reload/reload';

// Config
import appConstants from '../../constants/appConstants';

// Styles
import styles from './connectivity-error-modal.scss';

const ConnectivityErrorModal = () => (
  <Modal
    className={styles.container}
    disableControls
    header="Lost connection"
    hideCloseButton
    isOpen
    rootId={appConstants.ROOT}
  >
    <p>There was a problem connecting you to the next step.</p>
    <p>Please check your internet connection, refresh the page and try again.</p>

    <PrimaryButton
      className={styles.button}
      onClick={reload}
    >
      Refresh the page
    </PrimaryButton>
  </Modal>
);

export default ConnectivityErrorModal;
