export default {
  SERVER_INTERNAL_ERROR: 'server.internal.error',
  BFF_SERVER_INTERNAL_ERROR: 'bff.server.internal.error',
  BFF_UNKNOWN_API_ERROR: 'unknown.api.error',
  SESSION_EXPIRED: 'session.expired',
  CHECKOUT_SESSION_TOKEN_MISSED: 'checkout.session.token.missed',
  CHECKOUT_SESSION_TOKEN_EXPIRED: 'checkout.session.token.expired',
  CHECKOUT_SESSION_TOKEN_MALFORMED: 'checkout.session.token.malformed',
  CUSTOMER_SYNC_FAILED: 'customer.sync.failed',
  CUSTOMER_NOT_FOUND: 'customer.not.found',
  CODE_UNAUTHORIZED_TOKEN: 'code.unauthorized.token',
  ORDER_FORM_EMPTY: 'order.form.empty',
  ORDER_FORM_NOT_FOUND: 'order.form.not.found',
  HANDOVER_TOKEN_BAD_SIGNATURE: 'handover.token.bad.signature',
  HANDOVER_TOKEN_EXPIRED: 'handover.token.expired',
  HANDOVER_TOKEN_MALFORMED: 'handover.token.malformed',
  AUTH0_ACCESS_TOKEN_ERROR: 'invalid.access.token',
  AUTH0_UNAVAILABLE_ERROR: 'auth0.service.is.down',
  UNRECOGNIZED_CUSTOMER: 'unrecognized.customer',
  ORDER_ALREADY_PLACED: 'order.already.placed',
  PAYPAL_UNAVAILABLE: 'paypal.service.unavailable',
  CATALOGUE_SERVICE_UNAVAILABLE: 'catalogue.service.unavailable',
  PAYMENT_SERVICE_PAYPAL_AUTHORISATION_UNAVAILABLE: 'payment.service.paypal.authorisation.unavailable',
  PAYMENT_SERVICE_PAYPAL_AUTHORISATION_FAILED: 'payment.service.paypal.authorisation.failed',
  PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_UNAVAILABLE: 'payment.service.paypal.fraud.data.unavailable',
  PAYMENT_SERVICE_PAYPAL_FRAUD_DATA_FAILED: 'payment.service.paypal.fraud.data.failed',
  PAYPAL_AUTHORISATION_DECLINED: 'paypal.authorisation.declined',
  PAYPAL_AUTHORISATION_DECLINED_WITH_RETRY_PERMITTED: 'paypal.authorisation.declined.with.retry.permitted',
  PAYPAL_SESSION_INTERNAL_ERROR: 'paypal.session.internal.error',
  POSTCODE_OUT_OF_AREA: 'postcode.out.of.area',
  INVALID_ADDRESS_DETAILS: 'invalid.address.details',
  INCOMPLETE_ADDRESS_DETAILS: 'incomplete.address.details',
  CARD_PAYMENT_NOT_AUTHORISED: 'credit.or.debit.card.payment.not.authorised',
  CARD_PAYMENT_NO_RETRY: 'credit.or.debit.card.payment.not.authorised.do.not.retry',
  CARD_PAYMENT_ALREADY_EXISTS: 'credit.card.payment.already.exists',
  CARD_PAYMENT_SOFT_DECLINED: 'credit.or.debit.card.payment.soft.declined',
  CARD_PAYMENT_SOFT_DECLINED_DO_NOT_RETRY: 'credit.or.debit.card.payment.soft.declined.do.not.retry',
  SAVE_ADDRESS_FAILED: 'address.save.failed',
  APPLY_PARTNER_DISCOUNT_FAILED: 'apply.partner.discount.failed',
  ORDER_FORM_INCONSISTENT_STATE: 'order.form.inconsistent.state',
  ITEM_QUANTITY_PURCHASE_LIMIT: 'quantity.reduced.to.purchase.limit',
  QUANTITY_REDUCED_TO_AVAILABLE_STOCK: 'quantity.reduced.to.available.stock',
  ALL_AVAILABLE_STOCK_ALREADY_ADDED: 'all.available.stock.already.added',
  ITEM_NOT_IN_STOCK: 'item.not.in.stock',
  ITEM_QUANTITY_INVALID: 'item.quantity.invalid.is.not.number',
  BATCHING_FAILURE: 'delivery.type.unknown',
  LOQATE_SEARCH_ERROR: 'LOQATE_SEARCH_ERROR',
  GIFT_MESSAGE_LINE_COUNT_EXCEEDED: 'gift.message.lines.amount.exceeded',
  GIFT_MESSAGE_CHARACTER_COUNT_EXCEEDED: 'gift.message.line.text.length.too.long',
  INCONSISTENT_DELIVERY_INFO: 'inconsistent.delivery.info',
  DELIVERY_ORCHESTRATOR_SERVICE_UNAVAILABLE: 'delivery.orchestrator.service.unavailable',
  BATCH_UNDELIVERABLE_SERVICES: 'batch.contains.undeliverable.services',
  ORDERING_IN_PROGRESS: 'ordering.in.progress',
  CLEARPAY_PAYMENT_NOT_AUTHORISED: 'clearpay.payment.not.authorised',

  GIFT_CARD_PIN_INCORRECT: 'gift.card.pin.incorrect',
  GIFT_CARD_NOT_RECOGNISED: 'gift.card.not.recognised',
  DUPLICATE_GIFT_CARD: 'duplicate.gift.card',
  GIFT_CARD_EXPIRED: 'gift.card.expired',
  GIFT_CARD_NO_VALUE: 'gift.card.no.value',
  GIFT_CARD_SERVICE_UNAVAILABLE: 'gift.card.service.unavailable',
  UNKNOWN_GIFT_CARD_SERVICE_RESPONSE_CODE: 'unknown.gift.card.service.response.code',
  GIFT_CARD_REDEEM_FAILED: 'gift.card.redeem.failed',
  GIFT_CARD_OR_VOUCHER_REPLAYED_REQUEST: 'gift.card.or.voucher.replayed.request',
  GIFT_CARD_PAYMENT_LIMIT_REACHED: 'gift.card.payment.limit.reached',

  DUPLICATE_GIFT_VOUCHER: 'duplicate.gift.voucher',
  GIFT_VOUCHER_NOT_RECOGNISED: 'gift.voucher.not.recognised',
  SECURITY_CODE_INVALID: 'security.code.invalid',
  SERIAL_NUMBER_INVALID: 'serial.number.invalid',
  GIFT_VOUCHER_SERVICE_UNAVAILABLE: 'gift.voucher.service.unavailable',
  INSUFFICIENT_STOCK: 'insufficient.stock',
  RESERVATION_FAILED: 'reservation.failed',
  CARD_PAYMENT_INVALID_FORMAT: 'credit.card.number.invalid.format',
  GATEWAY_TIMEOUT_ERROR: 'gateway.timeout.error',
  PARTNER_CARD_NUMBER_INVALID: 'customer.not.eligible.for.partner.discount',

  PROMO_CODE_NOT_APPLICABLE: 'promotion.code.not.applicable',
  PROMO_CODE_INVALID: 'promotion.code.invalid',
  PROMO_CODE_ALREADY_APPLIED: 'promotion.code.already.applied',
  PROMO_CODE_INTERNAL_SERVER_ERROR: 'apply.promo.code.internal.server.error',
  PROMO_CODE_TECHNICAL_ERROR: 'apply.promo.code.technical.error',
  PROMO_CODE_USE_APP: 'promotion.code.invalid.channel.use.app.instead',
  PROMO_CODE_INVALID_CHANNEL: 'promotion.code.invalid.channel',
  PROMO_CODE_NOT_FOUND: 'promotion.code.not.found',
  PROMO_CODE_NOT_MYJL_CUSTOMER: 'promotion.code.not.myjl.customer',
  PROMO_CODE_USED: 'promotion.code.used',
  PROMO_CODE_IN_THE_PAST: 'promotion.code.in.the.past',
  PROMO_CODE_IN_THE_FUTURE: 'promotion.code.in.the.future',
  PROMO_CODE_NOT_ACTIVE: 'promotion.code.not.active',

  SAVE_ORDER_TECHNICAL_ERROR: 'claim.order.technical.error',
  SAVE_ORDER_TECHNICAL_ERROR_NO_SESSION: 'claim.order.technical.error.no.session',
  SAVE_ORDER_INVALID_LINK: 'claim.order.technical.invalid.link',

  INCOMPLETE_DELIVERY_DETAILS: 'incomplete.delivery.details',

  INCOMPLETE_COLLECTION_SEARCH: 'query.parameter.missed',

  DELIVERIES_INCONSISTENT: 'deliveries.inconsistent',

  UPDATE_MARKETING_PREFERENCES_V3_ERROR: 'update.marketing.preferences.v3.error',

  PAYMENT_POS_CREDIT_DECLINED_ERROR: 'pos.credit.application.declined',
  PAYMENT_POS_CREDIT_CANCELLED_ERROR: 'pos.credit.application.cancelled',
  PAYMENT_POS_CREDIT_APPLICATION_INCOMPLETE: 'pos.credit.application.applied',
  PAYMENT_POS_CREDIT_OPTIONS_CHANGED: 'credit.options.were.changed',
  PAYMENT_POS_CREDIT_OPTIONS_CHANGED_WITH_ACTION: 'order.form.requires.additional.actions',
  PAYMENT_POS_CREDIT_OPTIONS_CHANGED_BASKET_INVALID: 'order.form.is.not.eligible.for.pos.credit',

  // GENERATED ON CLIENT
  CLIENT_CONNECTIVITY_ERROR: 'client.api.connectivity.error',
  CLIENT_UNKNOWN_TECHNICAL_ERROR: 'client.api.unknown.technical.error',
  CLIENT_FORM_VALIDATION_ERRORS: 'client.form.validation.errors',
  CLIENT_PAYMENT_3DS_CANCELLED_ERROR: 'client.payment.3ds.cancelled',
  CLIENT_NO_COLLECTION_POINT_FOUND_ERROR: 'client.no.collection.point.found.error',
  CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED: 'client.create.account.details.not.saved',
  CLIENT_CREATE_ACCOUNT_ORDER_NOT_LINKED: 'client.create.account.order.not.linked',
  CLIENT_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED: 'client.create.account.details.not.saved.order.not.linked',
  CLIENT_OCP_PAYMENT_ERROR: 'client.ocp.payment.error',
  CLIENT_PAYMENT_OCP_3DS_INVALID_ERROR: 'client.ocp.payment.3ds.error',
  CLIENT_PREPAID_CARD_PAYMENT_ERROR: 'client.prepaid.card.payment.error',
  CLIENT_GEO_LOCATION_NOT_SUPPORTED: 'client.geo.location.not.supported',
  CLIENT_GEO_LOCATION_NOT_ENABLED: 'client.geo.location.not.enabled',
  CLIENT_GEO_LOCATION_GET_POSITION_ERROR: 'client.geo.location.get.position.error',
  CLIENT_AGE_CHECK_FAILED: 'client.age.check.failed',
  CLIENT_CARD_PAYMENT_ALREADY_EXISTS_OCP: 'client.card.payment.already.exists.ocp',
  CLIENT_PAYMENT_CARD_NOT_DELETED: 'customer.payment.card.not.belongs.to.customer',
  CLIENT_APPLE_PAY_BILLING_ADDRESS_MISSING: 'client.apple.pay.billing.address.missing',
  CLIENT_CARD_TYPE_MISSING: 'client.card.type.missing',
  CLIENT_CARD_DELETE_FAILED: 'client.card.delete.failed',

  CLIENT_POS_CREDIT_PAYMENT_FAILED: 'client.pos.credit.payment.failed',
  CLIENT_POS_CREDIT_PROVIDER_UNAVAILABLE: 'client.pos.credit.provider.unavailable',

  CLIENT_ADDRESS_INELIGIBLE_DELIVERY_POSTCODE: 'client.address.ineligible.delivery.postcode',
  CLIENT_DELIVERY_METHODS_ERROR: 'client.delivery.methods.error',
  CLIENT_API_TIMEOUT: 'client.api.timeout',

  // For when there's been a global error we want to handle on the client
  CLIENT_HANDLE_SERVER_INTERNAL_ERROR: 'client.handle.server.internal.error',

  // for APPS
  CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED_ORDER_NOT_LINKED:
    'client.apps.create.account.details.not.saved.order.not.linked',
  CLIENT_APPS_CREATE_ACCOUNT_DETAILS_NOT_SAVED: 'client.apps.create.account.details.not.saved',

  SUBMIT_ORDER_INCONSISTENT_AUTHORIZED_AMOUNT: 'authorized.and.order.amounts.mismatch',
  SUBMIT_ORDER_BALANCE_AMOUNT_MISMATCH: 'balance.and.order.amounts.mismatch',
  SUBMIT_ORDER_SERVER_INTERNAL_ERROR: 'submit.order.server.internal.error',
  MISSING_CLIENT_DATA_CODE: 'missing.client.data',

  AGE_VERIFICATION_CANCELLED: 'age.verification.cancelled',
  AGE_VERIFICATION_ERROR: 'age.verification.error',
  AGE_VERIFICATION_IN_PROGRESS: 'age.verification.in_progress',
  AGE_VERIFICATION_FAIL: 'age.verification.fail',

  LAZY_COMPONENT_WITH_SUSPENSE_ERROR: 'lazy.component.suspense.error',
};
