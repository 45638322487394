// lodash
import isString from 'lodash/isString';

const isZeroAmount = (amount) => {
  // null, undefined, '', 0,
  if (!amount) return true;
  // {}, [], true, 1,
  if (!isString(amount)) return false;
  // '0', '0.0', '0.00', '£0', '£0.0', ' £0 remaining', ' ',
  const cleanString = amount.replace(/[£\s]/g, '');
  const float = parseFloat(cleanString, 10);

  return (Number.isNaN(float) || float === 0);
};

export default isZeroAmount;
