import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';
// jl-design-system
import env from 'jl-design-system/utils/env/env';
// only for use in local/dev/test environments
import FormAutoFill from 'jl-design-system/components/form/_dev/FormAutoFill';
// lodash
import isEmpty from 'lodash/isEmpty';
// classnames
import classNames from 'classnames';
// redux
import { connect } from 'react-redux';
import { shouldShowAgeVerificationCheck } from './redux/actions/age-verification/ageVerificationActions';
import {
  initMonetateScript,
  appendGTMScript,
} from './redux/actions/app/appActions';
import { restoreAuth0Client } from './redux/actions/auth0-callback/auth0CallbackActions';
import { handbackTo } from './redux/actions/data-action-link/dataActionLinkActions';
import { rebatchOrderAfterBasketEdit } from './redux/actions/edit-basket/editBasketActions';
// constants
import appConstants from './constants/appConstants';
import routeConstants from './constants/routeConstants';
import featureConstants from './constants/featureConstants';
import { getFeatureVariant, isFeatureActive } from './redux/reducers/config/configReducer';
// utils
import { isApps, isIosApp } from './redux/reducers/app/appReducer';
import { loadIovationScripts } from './redux/actions/payment/paymentActions';
import { initLogging } from './utils/logging/logging-utils';
import { isDeliveryPage, isOrderConfirmationPage, isPaymentPage } from './utils/helpers/pageType';
import shouldShowFullScreenSignUp from './utils/myjl/fullScreenSignUp';
import showMinimalSaveOrder from './utils/orderform/showMinimalSaveOrder';
import { shouldLoadPaymentWalletStubs } from './non-prod-utils/utils/paymentWallet/loadPaymentWalletStubs';
// components
import ESIBanner from './components/esi-banner';
import Sidebar from './components/sidebar';
import Header from './components/header';
import LoadingScreen from './components/loading-screen';
import GenericError from './views/generic-error';
import SessionExpired from './views/session-expired';
import OrderCompleteNotification from './components/order-complete-notification';
import EmptyBasketNotification from './components/empty-basket-notification';
import OrderTimeoutNotification from './components/order-timeout-notification';
import PosCreditSessionExpiredError from './components/pos-credit-session-expired-error';
import AgeChecker from './components/age-checker';
import SessionTimeoutWarning from './components/session-timeout-warning';
import SessionExpiryCounter from './components/session-expiry-counter/SessionExpiryCounter';
import SessionTimeoutRedirect from './components/session-timeout-redirect/SessionTimeoutRedirect';
import ConnectivityErrorModal from './components/connectivity-error-modal';
import LimitedStockModal from './components/limited-stock-modal';
import BatchingFailureModal from './components/batching-failure-modal/BatchingFailureModal';
import EditBasketModal from './components/edit-basket-modal';
import DevConsole from './non-prod-utils/components/dev-console';
import Recommendations from './components/recommendations';
import UnavailableItemsModal from './components/unavailable-items-modal';
import LeaveCheckoutModal from './components/leave-checkout-modal';
import PaymentWalletStubsModal from './non-prod-utils/components/payment-wallet-stubs/modal';
import PaymentWalletStubs from './non-prod-utils/components/payment-wallet-stubs';
import OutOfStockModal from './components/out-of-stock-modal';

// styles
import styles from './app.scss';

export class App extends Component {

  static propTypes = {
    appendGTMScript: PropTypes.func,
    basketUrl: PropTypes.string,
    browseUrl: PropTypes.string,
    children: PropTypes.node,
    editBasketModalBaseRoutePath: PropTypes.string,
    globalError: PropTypes.shape({
      body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.node,
      ]),
      code: PropTypes.string,
      message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ]),
    }),
    handbackTo: PropTypes.func,
    initLogging: PropTypes.func,
    initMonetateScript: PropTypes.func,
    isGuest: PropTypes.bool,
    isInvalidPartnerDiscount: PropTypes.bool,
    isLoading: PropTypes.bool,
    leaveCheckoutModalBaseRoutePath: PropTypes.string,
    loadingMessage: PropTypes.string,
    loadIovationScripts: PropTypes.func,
    loadPaymentWalletStubs: PropTypes.bool,
    orderComplete: PropTypes.bool,
    orderConfirmationPageSuccess: PropTypes.bool,
    orderNeedsToBeRebatched: PropTypes.bool,
    outOfStockItems: PropTypes.arrayOf(PropTypes.object),
    pageNotFound: PropTypes.bool,
    pathname: PropTypes.string,
    paymentWalletStubName: PropTypes.string,
    posCreditSessionExpired: PropTypes.bool,
    rebatchOrderAfterBasketEdit: PropTypes.func,
    restoreAuth0Client: PropTypes.func,
    sessionExpired: PropTypes.bool,
    shouldShowMinimalSaveOrder: PropTypes.bool,
    showCitrusAds: PropTypes.shape({
      active: PropTypes.bool,
      variant: PropTypes.string,
    }),
    showConnectivityErrorModal: PropTypes.bool,
    showDisableSiteSpinner: PropTypes.bool,
    showEditBasketModal: PropTypes.bool,
    showEmptyBasketNotification: PropTypes.bool,
    showExpressPayments: PropTypes.bool,
    showFullScreenSignup: PropTypes.bool,
    showHeader: PropTypes.bool,
    showLeaveCheckoutModal: PropTypes.bool,
    showRecommendations: PropTypes.bool,
    showUnavailableItemsModal: PropTypes.bool,
    submitOrderTimeout: PropTypes.bool,
  };

  static defaultProps = {
    appendGTMScript: window.defaultFunc,
    basketUrl: '',
    browseUrl: '',
    children: null,
    editBasketModalBaseRoutePath: '',
    globalError: undefined,
    handbackTo: window.defaultFunc,
    initLogging: window.defaultFunc,
    initMonetateScript: window.defaultFunc,
    isGuest: true,
    isInvalidPartnerDiscount: false,
    isLoading: false,
    leaveCheckoutModalBaseRoutePath: '',
    loadingMessage: '',
    loadIovationScripts: window.defaultFunc,
    loadPaymentWalletStubs: false,
    orderComplete: false,
    orderConfirmationPageSuccess: false,
    orderNeedsToBeRebatched: false,
    outOfStockItems: [],
    pageNotFound: false,
    pathname: '',
    paymentWalletStubName: undefined,
    posCreditSessionExpired: false,
    rebatchOrderAfterBasketEdit: window.defaultFunc,
    restoreAuth0Client: window.defaultFunc,
    sessionExpired: false,
    shouldShowMinimalSaveOrder: false,
    showCitrusAds: undefined,
    showConnectivityErrorModal: false,
    showDisableSiteSpinner: false,
    showEditBasketModal: false,
    showEmptyBasketNotification: false,
    showExpressPayments: false,
    showFullScreenSignup: false,
    showHeader: true,
    showLeaveCheckoutModal: false,
    showRecommendations: false,
    showUnavailableItemsModal: false,
    submitOrderTimeout: false,
  };

  constructor() {
    super();

    this.state = {
      sidebarVisibleResolution: false,
    };
  }

  componentDidMount() {
    if (window?.history.scrollRestoration === 'auto') {
      window.history.scrollRestoration = 'manual';
    }

    const {
      appendGTMScript,
      initLogging,
      restoreAuth0Client,
      loadIovationScripts,
      initMonetateScript,
    } = this.props;

    initLogging();

    this.windowMatchMedia = window.matchMedia(`(min-width: ${appConstants.SIDEBAR_MIN_BREAKPOINT})`);
    this.setSidebarVisibleResolution(this.windowMatchMedia.matches);
    this.windowMatchMedia.addListener(this.mediaQueryCheck);

    restoreAuth0Client();

    loadIovationScripts();
    initMonetateScript();
    appendGTMScript();
  }

  setSidebarVisibleResolution = (bool) => {
    this.setState({ sidebarVisibleResolution: bool });
  };

  mediaQueryCheck = () => {
    this.setSidebarVisibleResolution(this.windowMatchMedia.matches);
  };

  componentWillUnmount() {
    if (this.windowMatchMedia) {
      this.windowMatchMedia.removeListener(this.mediaQueryCheck);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      appendGTMScript,
      pathname,
      orderNeedsToBeRebatched,
      rebatchOrderAfterBasketEdit,
      restoreAuth0Client,
      loadIovationScripts,
      initMonetateScript,
      isGuest,
    } = this.props;

    if (
      prevProps.pathname?.includes(routeConstants.EDIT_BASKET) &&
      !pathname.includes(routeConstants.EDIT_BASKET) &&
      orderNeedsToBeRebatched
    ) {
      rebatchOrderAfterBasketEdit();
    }

    if (prevProps.isGuest && !isGuest) {
      restoreAuth0Client();
    }

    loadIovationScripts();
    initMonetateScript();
    appendGTMScript();
  }

  render() {
    const {
      basketUrl,
      browseUrl,
      children,
      editBasketModalBaseRoutePath,
      globalError,
      handbackTo,
      isGuest,
      isInvalidPartnerDiscount,
      isLoading,
      leaveCheckoutModalBaseRoutePath,
      loadingMessage,
      orderComplete,
      orderConfirmationPageSuccess,
      pathname,
      posCreditSessionExpired,
      sessionExpired,
      showCitrusAds,
      showConnectivityErrorModal,
      showDisableSiteSpinner,
      showEditBasketModal,
      showEmptyBasketNotification,
      showHeader,
      showLeaveCheckoutModal,
      showRecommendations,
      showUnavailableItemsModal,
      submitOrderTimeout,
      showFullScreenSignup,
      pageNotFound,
      loadPaymentWalletStubs,
      paymentWalletStubName,
      outOfStockItems,
      shouldShowMinimalSaveOrder,
      showExpressPayments,
    } = this.props;

    const { sidebarVisibleResolution } = this.state;

    let mainContent = children;

    const isPathExcluded = ![
      routeConstants.ORDER_CONFIRMATION,
      routeConstants.CALLBACK_ORDER_CLAIM,
      routeConstants.CALLBACK_CREATE_ACCOUNT,
      routeConstants.JOIN_MY_JL,
    ].some(routeConstant => pathname.includes(routeConstant));
    const showOrderCompleteNotification = orderComplete && isPathExcluded;

    const shouldShowHeader = showHeader &&
      ![routeConstants.APPS_GUEST_HANDOVER, routeConstants.APPS_AUTHENTICATED_HANDOVER].includes(pathname);
    if (globalError) {
      mainContent = <GenericError />;
    } else {

      if (sessionExpired) {
        mainContent = (
          <SessionExpired />
        );
      }

      if (showOrderCompleteNotification) {
        mainContent = (
          <OrderCompleteNotification browseUrl={browseUrl} />
        );
      }

      if (showEmptyBasketNotification) {
        mainContent = (
          <EmptyBasketNotification handleButtonClick={() => handbackTo(browseUrl)} />
        );
      }

      if (submitOrderTimeout) {
        mainContent = (
          <OrderTimeoutNotification isGuest={isGuest} />
        );
      }

      if (posCreditSessionExpired) {
        mainContent = (
          <PosCreditSessionExpiredError
            handleButtonClick={() => handbackTo(basketUrl)}
            isInvalidPartnerDiscount={isInvalidPartnerDiscount}
          />
        );
      }
    }

    const deliveryPageActive = isDeliveryPage(pathname, editBasketModalBaseRoutePath, leaveCheckoutModalBaseRoutePath);
    const paymentPageActive = isPaymentPage(pathname, editBasketModalBaseRoutePath, leaveCheckoutModalBaseRoutePath);
    const orderConfirmationActive = isOrderConfirmationPage(pathname);

    const showUserActiveContent = !sessionExpired
      && !posCreditSessionExpired
      && isEmpty(globalError)
      && !submitOrderTimeout
      && !showEmptyBasketNotification;
    const showSidebar = showUserActiveContent
      && sidebarVisibleResolution
      && (deliveryPageActive || paymentPageActive || (orderConfirmationActive && !shouldShowMinimalSaveOrder));

    const centerVertical = !!globalError
      || showEmptyBasketNotification
      || showOrderCompleteNotification
      || submitOrderTimeout
      || sessionExpired
      || pageNotFound;

    const containerClassNames = classNames(styles.container, {
      [styles.centerVertical]: centerVertical,
      [styles.sidebarVisible]: showSidebar,
    });

    const shouldShowCitrusAds = (showCitrusAds && showCitrusAds.active)
      && ((sidebarVisibleResolution && showCitrusAds.variant === 'A') || !sidebarVisibleResolution)
      && (showUserActiveContent && (orderConfirmationActive && !shouldShowMinimalSaveOrder));

    const shouldShowRecommendations = showRecommendations
      && (orderConfirmationPageSuccess && orderConfirmationActive)
      && (!isLoading && orderComplete)
      && !showFullScreenSignup;

    // TODO clarify with UX on what pages we can show out of stock/limited stock modals
    // If express payments are enabled, allow modal to show on any page
    // If express payments are not enabled, do not show on delivery page
    const shouldShowOutOfStockModal = outOfStockItems.length > 0 && (
      showExpressPayments || !deliveryPageActive
    );

    return (
      <div className={styles.app}>

        <SessionExpiryCounter />

        {
          /* NOTE: This will not render in production */
          (env.isClientLocal || env.isClientDev) &&
          <FormAutoFill id="urlQueryParams" />
        }

        {
          shouldShowHeader &&
          <Header />
        }

        {shouldShowCitrusAds && orderConfirmationPageSuccess && (
          <section className={styles.citrusAd}>
            <div>
              <ESIBanner
                id="citrus_skinny"
                type="skinny"
                url="/esi/online-adverts/banner/broad?placement=orderConfirmation&forceFullyQualifiedESI=true&type=skinny"
              />
            </div>
          </section>
        )}

        <main
          className={classNames(styles.main, {
            [styles.sidebarVisible]: showSidebar,
            [styles.app]: !shouldShowHeader,
          })}
          data-test="main"
          id="main"
        >
          {
            shouldShowHeader &&
            !centerVertical &&
            (deliveryPageActive || paymentPageActive || pathname.includes(routeConstants.ORDER_CONFIRMATION)) &&
            <h1
              className={classNames(styles.mainHeading, {
                [styles.sidebarVisible]: showSidebar,
              })}
            >
              { orderComplete ? 'Order confirmation' : 'Checkout' }
            </h1>
          }

          <LoadingScreen
            isApps={!showHeader}
            message={loadingMessage}
            seeThrough={showDisableSiteSpinner}
            show={isLoading}
          />

          <section
            className={styles.section}
          >
            <div className={containerClassNames} data-test="main-content">
              {mainContent}
            </div>
            {showSidebar && (
              <Sidebar
                isDeliveryPage={deliveryPageActive}
                isOrderConfirmationPage={orderComplete}
              />
            )}
          </section>
          {
            !sessionExpired &&
            <>
              <AgeChecker />
              <BatchingFailureModal />
              {showLeaveCheckoutModal && <LeaveCheckoutModal />}
              <LimitedStockModal />
              {showEditBasketModal && <EditBasketModal />}
              {showUnavailableItemsModal && <UnavailableItemsModal />}
              {shouldShowOutOfStockModal && <OutOfStockModal />}
            </>
          }

          {
            !sessionExpired &&
            !orderComplete &&
            !submitOrderTimeout &&
            <SessionTimeoutWarning />
          }

          {
            !orderComplete &&
            <SessionTimeoutRedirect />
          }

          {
            showConnectivityErrorModal &&
            <ConnectivityErrorModal />
          }

          {
            env.isClientNonProd &&
            <>
              <DevConsole showHeader={showHeader} />
              {
                loadPaymentWalletStubs &&
                <>
                  <PaymentWalletStubs stubName={paymentWalletStubName} />
                  <PaymentWalletStubsModal />
                </>
              }
            </>
          }

        </main>

        {shouldShowRecommendations && (
          <Recommendations />
        )}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const ageVerificationFeatureActive = shouldShowAgeVerificationCheck(state);
  const hasItemsNeedingAgeVerification =
    (ageVerificationFeatureActive && state.delivery.itemsNeedingAgeVerification?.length > 0) ||
    (state.user?.ageCheckRequired && !state.user?.ageCheckSuccess);
  const unavailableItemsInBasket = state?.orderForm?.unavailableItemInfo?.unavailableItemsInBasket ?? [];
  const shouldShowMinimalSaveOrder = showMinimalSaveOrder(state) || false;

  return {
    globalError: state.error.error,
    basketUrl: state.bff.basketUrl,
    browseUrl: state.bff.browseUrl,
    isLoading: state.app.isLoading ||
      (!state.app.sessionExpired && hasItemsNeedingAgeVerification) ||
      !!state.payment?.invalidGooglePayBillingAddress,
    orderComplete: state.app.orderComplete,
    orderConfirmationPageSuccess: state.app.orderConfirmationPageSuccess,
    submitOrderTimeout: state.app.submitOrderTimeout,
    isGuest: state.user.isGuest,
    loadingMessage: state.app.loadingMessage,
    showHeader: !isApps(state),
    sessionExpired: state.app.sessionExpired,
    showDisableSiteSpinner: state.app.showDisableSiteSpinner,
    shouldShowMinimalSaveOrder,
    pathname: state?.router.location.pathname,
    orderNeedsToBeRebatched: state.orderForm?.orderNeedsToBeRebatched,
    showEmptyBasketNotification: state.app.showEmptyBasketNotification,
    showCitrusAds: {
      active: isFeatureActive(state, featureConstants.CITRUS_ADS),
      variant: getFeatureVariant(state, featureConstants.CITRUS_ADS),
    },
    showConnectivityErrorModal: state.app.showConnectivityErrorModal,
    showEditBasketModal: state.delivery.showEditBasketModal,
    showLeaveCheckoutModal: state.app.showLeaveCheckout,
    showUnavailableItemsModal: unavailableItemsInBasket?.length > 0,
    posCreditSessionExpired: state.app.posCreditSessionExpired,
    editBasketModalBaseRoutePath: state.delivery.editBasketModalBaseRoutePath,
    leaveCheckoutModalBaseRoutePath: state.app.leaveCheckoutModalBaseRoutePath,
    showRecommendations: !isApps(state) &&
      isFeatureActive(state, featureConstants.SHOW_RECOMMENDATIONS) &&
      env.isClientProd,
    isInvalidPartnerDiscount: state.app.isInvalidPartnerDiscount,
    showFullScreenSignup: shouldShowFullScreenSignUp(state),
    pageNotFound: state.app.pageNotFound,
    loadPaymentWalletStubs: shouldLoadPaymentWalletStubs(state),
    paymentWalletStubName: isIosApp(state) ? 'applePayPaymentInitOnIosApp' : 'applePayPaymentInitOnWeb',
    outOfStockItems: state.orderForm.outOfStockItems,
    showExpressPayments: state.app.showExpressPayments,
  };
}

export function mapDispatchToProps() {
  return {
    appendGTMScript,
    initLogging,
    rebatchOrderAfterBasketEdit,
    handbackTo,
    restoreAuth0Client,
    loadIovationScripts,
    initMonetateScript,
  };
}

export default connect(state => mapStateToProps(state), mapDispatchToProps())(App);
