import getRegExp, {
  asciiExtendedPattern,
} from 'jl-design-system/form/regex';

import { restrictInputExcludeControlChars } from '../normalizers/excludeSymbols';
import GiftMessage from '../../../components/gift-message/GiftMessage';

const GIFT_MESSAGE = {
  component: GiftMessage,
  type: 'textarea',
  id: 'giftMessage',
  name: 'giftMessage',
  label: 'Add a personal note and your name to let the recipient know who the gift is from.',
  props: {
    maxLines: 4,
    maxChars: 120,
    maxCharsLine: 30,
  },
  regex: getRegExp(asciiExtendedPattern),
  required: true,
  validationMessageKeys: {
    regex: 'Sorry, only plain text can be used for a gift message. Please remove any non standard characters.',
    required: 'Please enter a gift message.',
  },
  normalize: restrictInputExcludeControlChars,
};

export default GIFT_MESSAGE;
