import merge from 'lodash/merge';
import get from 'lodash/get';

import { BILLING_ADDRESS_FORM_ID } from '../form/configs/billingAddress';

function mergeBillingAddressSources(state, prioritiseFormValues) {

  const billingAddressFormValues = get(state, `form[${BILLING_ADDRESS_FORM_ID}].values`);
  const billingAddressInitialValues = get(state, 'billingAddress.initialValues');

  if (prioritiseFormValues) {
    return merge({}, billingAddressInitialValues, billingAddressFormValues);
  }

  return merge({}, billingAddressFormValues, billingAddressInitialValues);

}

export default mergeBillingAddressSources;
