import { createSelector } from 'reselect';
import get from 'lodash/get';

// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { getIsSignedInSelector } from '../../redux/reducers/user/userSelector';
import shouldSaveDefaultCollectionPoint from '../../utils/collection/shouldSaveDefaultCollectionPoint';
import { SAVE_AS_DEFAULT_FORM_ID } from '../../utils/form/configs/saveAsDefault';

const fieldValueSelector = (state: RootState, fieldId: string) => get(state, `form.${SAVE_AS_DEFAULT_FORM_ID}.values.${fieldId}`, '');
const fulfilmentPreferenceFeatureActiveSelector =
  (state: RootState) => isFeatureActive(state, featureConstants.ENABLE_FULFILMENT_PREFERENCE);
const saveDefaultCollectionPointSelector = (state: RootState) => shouldSaveDefaultCollectionPoint(state);

const saveAsDefaultFormState = createSelector(
  [
    fieldValueSelector,
    fulfilmentPreferenceFeatureActiveSelector,
    getIsSignedInSelector,
    saveDefaultCollectionPointSelector,
  ],
  (fieldValue, fulfilmentPreferenceFeatureActive, isSignedIn, saveDefaultCollectionPoint) => ({
    fieldValue,
    fulfilmentPreferenceFeatureActive,
    isSignedIn,
    saveDefaultCollectionPoint,
  }),
);

export default saveAsDefaultFormState;
