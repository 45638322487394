import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

// Design System
import LoadingSpinner from 'jl-design-system/components/loading-spinner/LoadingSpinner';
import { setNoScroll } from 'jl-design-system/utils/scroll/noScroll';
import { scrollTo } from 'jl-design-system/utils/scroll/scrollTo';

// Types
import { LoadingScreenProps } from './LoadingScreen.types';

// Components
import BodyText from '../body-text';
import Container from '../container';

// Styles
import styles from './loading-screen.scss';

const LoadingScreen = ({
  disableModalAttributes = true,
  isApps = false,
  message = '',
  seeThrough = false,
  show = false,
}: LoadingScreenProps) => {
  const prevShow = useRef<boolean>(show);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (show) {
      timeoutRef.current = setTimeout(() => {
        scrollTo(0);
        setNoScroll(true, disableModalAttributes);
      }, 0);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setNoScroll(false, disableModalAttributes);
    };
  }, []);

  useEffect(() => {
    if (!prevShow.current && show) {
      timeoutRef.current = setTimeout(() => {
        scrollTo(0);
        setNoScroll(true, disableModalAttributes);
      }, 0);

    } else if (prevShow.current && !show) {
      timeoutRef.current = setTimeout(() => {
        setNoScroll(false, disableModalAttributes);
      }, 0);
    }

    prevShow.current = show;

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      setNoScroll(false, disableModalAttributes);
    };
  }, [show, disableModalAttributes]);

  return show && (
    <div
      className={cx(styles.overlay, {
        [styles.seeThrough]: seeThrough,
        [styles.apps]: isApps,
      })}
      data-test="loading-screen"
    >
      <Container
        className={styles.contentBox}
        paddingX="4"
      >
        <Container className={styles.spinner} marginBottom="3">
          <LoadingSpinner />
        </Container>
        {message && (
          <BodyText type="l">{message}</BodyText>
        )}
      </Container>
    </div>
  );
};

export default LoadingScreen;
