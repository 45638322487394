import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export const isBasketClearpayEligible = (state) => {
  const {
    delivery: {
      deliveriesWithClearabee,
    } = {},
    orderForm: {
      items = [],
    } = {},
  } = state ?? {};

  const basketContainsAlcohol = items.some(item => item.productType === 'Alcohol');
  if (basketContainsAlcohol) return false;

  if (deliveriesWithClearabee?.length > 0) return false;

  if (state.orderForm?.amounts?.outstandingBalanceRaw < 30 || state.orderForm?.amounts?.outstandingBalanceRaw > 1000) {
    return false;
  }

  return true;
};

export const shouldShowClearpay = state => !isFeatureActive(state, featureConstants.DISABLE_CLEARPAY) &&
  isBasketClearpayEligible(state);
