import isEmpty from 'lodash/isEmpty';
import deliveryConstants from '../../constants/deliveryConstants';

export default function getUnavailableItems(items = []) {
  const unavailableItemsInBasket = items.filter(
    item => item.availability?.availabilityStatus === deliveryConstants.UNAVAILABLE,
  );

  const allItemsInBasketUnavailable = !!(
    !isEmpty(unavailableItemsInBasket) && unavailableItemsInBasket.length === items.length
  );

  return {
    allItemsInBasketUnavailable,
    unavailableItemsInBasket,
  };
}
