import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import CircularCountdown from 'jl-design-system/components/circular-countdown/CircularCountdown';
import Modal from 'jl-design-system/components/modal/Modal';

// Types
import { AppDispatch } from 'types/RootState.types';
import { SessionTimeoutModalProps } from './SessionTimeoutModal.types';

// Redux
import {
  refreshSession,
} from '../../redux/actions/app/appActions';

// Config
import appConstants from '../../constants/appConstants';
import sessionTimeoutModalState from './SessionTimeoutModal.state';

// Components
import Heading from '../heading';

// Styles
import styles from './session-timeout-modal.scss';

const SessionTimeoutModal = ({
  now = Date.now(),
}: SessionTimeoutModalProps) => {
  const {
    sessionExpiryData,
    sessionWarningAfterTime,
    sessionRefreshAPICallActive,
  } = useSelector(sessionTimeoutModalState);

  if (!sessionExpiryData || !sessionWarningAfterTime) return null;

  const dispatch: AppDispatch = useDispatch();
  const handleRefresh = () => dispatch(refreshSession());
  const headerText = 'Your session is about to expire in:';

  const elapsedTime = now - sessionExpiryData.timestamp;
  const remainingTime = sessionExpiryData.sessionExpiresIn - elapsedTime;

  return (
    <Modal
      a11yHeader={headerText}
      className={styles.modal}
      disableControls={sessionRefreshAPICallActive}
      isOpen
      onClose={handleRefresh}
      rootId={appConstants.ROOT}
    >
      <Heading
        label={headerText}
        marginBottom="2"
        tag="h2"
        type="s"
      />
      <CircularCountdown
        className={styles.circularCountdown}
        total={remainingTime}
      />
      <PrimaryButton
        className={styles.continue}
        onClick={handleRefresh}
        submitting={sessionRefreshAPICallActive}
      >
        Continue with checkout
      </PrimaryButton>
    </Modal>
  );
};

export default SessionTimeoutModal;
