import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { getOrderCompleteSelector } from '../../redux/reducers/app/appSelector';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

const getIsDiorPromosFeatureActive = (state: RootState) => isFeatureActive(state, featureConstants.DIOR_PROMOS_ENABLED);

const orderItemState = createSelector(
  [
    getOrderCompleteSelector,
    getIsDiorPromosFeatureActive,
  ],
  (orderComplete, hasDiorPromosEnabled) => ({
    orderComplete,
    hasDiorPromosEnabled,
  }),
);

export default orderItemState;
