import { format, isBefore, isEqual } from 'date-fns';
// lodash
import get from 'lodash/get';
import {
  USER_AGE_CHECK,
  UPDATE_CUSTOMER_DOB,
  GET_PAYMENT_WALLET,
} from '../../../constants/actionConstants';
import {
  URL_CUSTOMER_PAYMENT_CARDS,
  URL_UPDATE_CUSTOMER,
} from '../../../constants/endpointConstants';
import featureConstants from '../../../constants/featureConstants';
import { recordImpressions } from '../app/appActions';
import { initDeliveryPage } from '../delivery/deliveryActions';

export const updateCustomerDOB = body => ({
  type: UPDATE_CUSTOMER_DOB,
  request: client => client({ path: URL_UPDATE_CUSTOMER, config: { method: 'PUT', body } }),
});

export const fetchPaymentWallet = () => async (dispatch, getState) => {
  const isSignedIn = get(getState().user, 'isSignedIn', false);
  const notFetched = !get(getState().user, 'fetchedPaymentWallet', false);
  const isPartnerDiscountEnabled = get(getState().user, 'partnerDiscountEnabled', false);

  if (isSignedIn && notFetched) {
    return dispatch({
      type: GET_PAYMENT_WALLET,
      request: client => client({
        path: URL_CUSTOMER_PAYMENT_CARDS,
        config: {
          method: 'GET',
        },
      }),
      isPartnerDiscountEnabled,
    });
  }

  return {};
};

export const checkAgeRestrictionDate = (date, updateCustomer) => async (dispatch, getState) => {

  const { ageCheckDate, isSignedIn } = getState().user;

  if (isEqual(date, ageCheckDate) || isBefore(date, ageCheckDate)) {
    if (isSignedIn && updateCustomer) {
      await dispatch(updateCustomerDOB({ dateOfBirth: format(date, 'YYYY-MM-DD') }));
    }

    dispatch({
      type: `${USER_AGE_CHECK}.SUCCESS`,
    });

    await dispatch(initDeliveryPage());

    return;
  }

  dispatch({
    type: `${USER_AGE_CHECK}.FAILED`,
    dob: date,
  });

};

export const recordSpendMoreFeatureImpressionId = () => (dispatch, getState) => {
  const {
    app: {
      recordedImpressionIds = [],
    } = {},
    config: {
      features = [],
    } = {},
    orderForm: {
      amounts: {
        grandTotalRaw,
      } = {},
    } = {},
  } = getState() ?? {};

  if (grandTotalRaw < 60) return null;

  const spendMoreFeature = features?.find(
    feature => feature.id === featureConstants.SHOW_MYJL_SPEND_MORE_MESSAGING,
  ) ?? {};

  const spendMoreFeatureImpressionId = spendMoreFeature.impressionId;
  if (
    spendMoreFeatureImpressionId &&
    !recordedImpressionIds.includes(spendMoreFeatureImpressionId)
  ) return dispatch(recordImpressions(spendMoreFeatureImpressionId));

  return null;
};
