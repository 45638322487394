import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import Form from 'jl-design-system/components/form/Form';
import { Message } from 'jl-design-system/components/message/v2/Message';
import Modal from 'jl-design-system/components/modal/Modal';
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import Link, { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { AppDispatch } from 'types/RootState.types';
import { AgeCheckModalProps } from './AgeCheckModal.types';

// Config
import ageCheckModalState from './AgeCheckModal.state';
import appConstants from '../../constants/appConstants';
import { checkAgeRestrictionDate } from '../../redux/actions/user/userActions';
import { removeItemsFromBasket } from '../../redux/actions/edit-basket/editBasketActions';
import ageCheckFormConfig from '../../utils/form/configs/ageCheck';
import { pluraliseItemWord } from '../../utils/helpers/pluraliseItem';
import thumbnail from '../../utils/image/thumbnail';

// Components
import Container from '../container';
import MyAccountLink from '../my-account-link';

// Styles
import styles from './age-check-modal.scss';

const AgeCheckModal = ({ showStepIndicator }: AgeCheckModalProps) => {
  const dispatch: AppDispatch = useDispatch();
  const {
    ageCheckError,
    ageCheckMinimumAge,
    ageRestrictedProducts = [],
    ageRestrictedProductsCanRemove,
    basketUrl,
    hasSavedDob,
    isApplication,
    isSignedIn,
    removeBasketItemsApiCallActive,
    removeBasketItemsFailed,
    rebatchOrderApiCallActive,
    showModal,
  } = useSelector(ageCheckModalState);

  if (!showModal) {
    return null;
  }

  const onFormSubmit = (values: { dateOfBirth: any }) => {
    const { dateOfBirth } = values;
    return dispatch(checkAgeRestrictionDate(dateOfBirth, true));
  };

  const onRemoveItemsButtonClick = () => {
    const itemIds = ageRestrictedProducts.map(item => (item.id));
    dispatch(removeItemsFromBasket({ itemIds }));
  };

  const isSignedInWithDob = isSignedIn && hasSavedDob;

  const formConfig = ageCheckFormConfig(isSignedIn, {
    submitButtonExtra: {
      extra: true,
      name: 'age-check-return-to-basket-link',
      component: Link,
      props: {
        to: basketUrl,
        'data-action': 'handback',
        'data-test': 'age-check-modal-return-to-basket',
        children: 'Return to basket',
        className: styles.link,
      },
    },
  });

  const heading = isSignedInWithDob && ageCheckError ?
    `You must be over ${ageCheckMinimumAge} to continue` : 'Age verification';

  const singleItem = ageRestrictedProducts.length === 1;
  const ageRestrictionWithSavedDobBody =
    `Based on the information we have for you, you are not old enough to purchase the following ${pluraliseItemWord(singleItem)}.`;
  const ageRestrictionBody = singleItem ? 'The item below is age-restricted. Please confirm your age to continue.' :
    'The items below are age-restricted. Please confirm your age to continue.';

  let messageBody;
  let messageTitle = isSignedInWithDob ? ageRestrictionWithSavedDobBody : ageRestrictionBody;
  let modalProps;
  if (showStepIndicator && !isSignedInWithDob) {
    messageBody = "Please enter your date of birth to confirm you're over 18.";
    messageTitle = 'Further items in your basket are age restricted';

    modalProps = {
      headerCount: 'Step 2 of 2',
    };
  }

  return (
    <Modal
      forceFullHeight
      header={heading}
      headerStyleModifier={styles.modalHeader}
      hideCloseButton
      isOpen
      large
      rootId={appConstants.ROOT}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      {...modalProps}
    >
      <Container
        className={styles.content}
        testId="age-check-modal"
      >
        <Container marginBottom="3">
          <Message
            body={messageBody}
            dataTest="age-check-modal-message"
            title={messageTitle}
            type="warning"
          />
        </Container>

        <Container className={styles.products} renderIf={ageRestrictedProducts.length}>
          {ageRestrictedProducts.map((product, i) => {
            const key = `product-${i}`;
            const imageSrc = thumbnail(product.imageUrl);
            return (
              <Container
                key={key}
                className={styles.product}
                testId="age-check-modal-product"
              >
                <div>
                  {imageSrc && (
                  <img
                    alt={product.name}
                    src={imageSrc}
                  />
                  )}
                </div>
                <p>{product.name}</p>
              </Container>
            );
          })}
        </Container>

        <Container renderIf={!!ageCheckError} testId="age-check-modal-errors">
          <Container marginBottom="2" renderIf={!isSignedInWithDob && !removeBasketItemsFailed}>
            <Message
              body={ageCheckError?.body}
              dataTest="age-check-error"
              title={ageCheckError?.message}
              type="error"
            />
          </Container>

          <Container marginBottom="2" renderIf={removeBasketItemsFailed}>
            <Message
              dataTest="age-check-remove-failed"
              title={`Sorry, we couldn't remove the item${singleItem ? '' : 's'}. Please return to basket.`}
              type="error"
            />
          </Container>

          <Container
            className={styles.buttonWrap}
            testId="age-check-modal-errors-button-wrap"
          >
            {(ageRestrictedProductsCanRemove && !removeBasketItemsFailed) && (
              <Link
                className={styles.link}
                data-action="handback"
                data-test="age-check-modal-return-to-basket"
                disabled={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
                to={basketUrl}
              >
                Return to basket
              </Link>
            )}

            {(!ageRestrictedProductsCanRemove || (ageRestrictedProductsCanRemove && removeBasketItemsFailed)) && (
              <PrimaryLink
                className={styles.button}
                data-action="handback"
                data-test="age-check-modal-return-to-basket"
                disabled={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
                to={basketUrl}
              >
                Return to basket
              </PrimaryLink>
            )}

            {(ageRestrictedProductsCanRemove && !removeBasketItemsFailed) && (
              <PrimaryButton
                className={styles.button}
                data-test="age-check-modal-remove-items"
                onClick={onRemoveItemsButtonClick}
                submitting={removeBasketItemsApiCallActive || rebatchOrderApiCallActive}
              >
                {singleItem ? 'Remove item and continue' : 'Remove items and continue'}
              </PrimaryButton>
            )}
          </Container>

          {isSignedInWithDob && (
            <p className={styles.footer}>
              {isApplication &&
                <>If you feel the date of birth we have for you is incorrect, you can review this in My Account.</>}
              {!isApplication &&
                <>
                  If you feel the date of birth we have for you is incorrect,
                  you can review this in <MyAccountLink />.
                </>}
            </p>
          )}
        </Container>

        <Container renderIf={!ageCheckError}>
          <Form
            config={formConfig}
            data-test="age-check-modal-form"
            form={formConfig.id}
            multiFormValidation
            onSubmit={onFormSubmit}
          />
        </Container>
      </Container>
    </Modal>
  );
};

export default AgeCheckModal;
