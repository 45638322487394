import React from 'react';
import { render } from 'react-dom';
import Modal from 'react-modal';

// jl-design-system
import DataActionManager from 'jl-design-system/components/data-action-manager/DataActionManager';
// lodash
import get from 'lodash/get';
// redux
import { dataAction } from './redux/actions/data-action-link/dataActionLinkActions';
import mobileAppsActions from './redux/actions/mobileApps/mobileAppsActions';
// utils
import configureStore from './redux/configureStore';
import { URL_FEATURES } from './constants/endpointConstants';
import routeConstants from './constants/routeConstants';
import appConstants from './constants/appConstants';
import createBrowserHistory from './utils/history/history';
import api from './utils/api/api';
import { initLogging } from './utils/logging/logging-utils';
import unionWithDevFeatures from './utils/features/feature-utils';
// components
import Root from './Root';

// This is needed so screen readers don't see main content when modal is opened.
const appRoot = document.getElementById(appConstants.ROOT);
if (appRoot) {
  Modal.setAppElement(appRoot);
}

export const reactDOM = {
  render,
};

const mount = document.getElementById(appConstants.ROOT);

function onRender(store) {
  // initialise DataActionManager action
  DataActionManager.setDataAction((...args) => store.dispatch(dataAction(...args)));

  store.dispatch(initLogging());
}

export function initStore(initialState = {}) {
  const history = createBrowserHistory();
  const { store, persistor } = configureStore(history, initialState);

  return { history, store, persistor };
}

export function init(initialState = {}) {

  const { history, store, persistor } = initStore(initialState);

  // Set up Apps actions
  window.appsActions = mobileAppsActions(store);

  const root = <Root history={history} persistor={persistor} store={store} />;
  reactDOM.render(root, mount, () => { onRender(store); });

  if (module.hot) {
    module.hot.accept('./Root', () => {
      const NewRoot = require('./Root').default; // eslint-disable-line global-require
      const root = <NewRoot history={history} persistor={persistor} store={store} />;
      reactDOM.render(root, mount, () => { onRender(store); });
    });
  }
}

const getFeatures = () => (
  api({
    path: URL_FEATURES,
    config: { method: 'GET' },
  }).then((response) => {
    const bffFeatures = get(response, 'features', []);
    return unionWithDevFeatures(bffFeatures);
  }).catch(() => [])
);

const skipGetFeaturesCallOnPaths = [
  routeConstants.LOGIN,
  routeConstants.WEB_HANDOVER,
  routeConstants.APPS_GUEST_HANDOVER,
  routeConstants.APPS_AUTHENTICATED_HANDOVER,
  routeConstants.CALLBACK_LOGIN_AUTHENTICATED,
  routeConstants.CALLBACK_LOGIN_GUEST,
  routeConstants.CALLBACK_ORDER_CLAIM,
];

export default async function initApp() {
  window.timeToReactLoaded = Date.now();

  const features = skipGetFeaturesCallOnPaths.includes(window.location.pathname) ? [] : await getFeatures();

  return init({
    config: {
      features,
    },
  });
}

initApp();
