import get from 'lodash/get';
//
import { replace } from 'connected-react-router';
import routeConstants from '../../constants/routeConstants';
import { rebatchOrder } from '../../redux/actions/edit-basket/editBasketActions';
import deliveryConstants from '../../constants/deliveryConstants';
import {
  initDeliveryPage,
} from '../../redux/actions/delivery/deliveryActions';
import { showDisableSiteSpinner } from '../../redux/actions/app/appActions';
import { REBATCH_ORDER } from '../../constants/actionConstants';

export const handleInconsistentOrderFormState = () => async (dispatch, getState) => {
  const address = get(getState(), 'delivery.confirmedDeliveryAddress');
  const selectedDeliveryChoiceId = get(getState(), 'delivery.selectedDeliveryChoiceId');

  dispatch(showDisableSiteSpinner());

  const response = await dispatch(rebatchOrder(address));

  if (response.type === `${REBATCH_ORDER}.SUCCESS`) {

    const currentLocation = get(getState().router, 'location.pathname', '');
    const isOnPayment = currentLocation.includes(routeConstants.PAYMENT) ||
      currentLocation.includes(routeConstants.PAYMENT_POS_CREDIT_CALLBACK);
    const isOnDelivery = currentLocation.includes(routeConstants.DELIVERY);
    const isOnClickCollect = currentLocation.includes(routeConstants.CLICK_AND_COLLECT);

    if (isOnPayment) {
      if (selectedDeliveryChoiceId === deliveryConstants.CLICK_AND_COLLECT) {
        await dispatch(replace(routeConstants.CLICK_AND_COLLECT));
      } else {
        await dispatch(replace(routeConstants.DELIVERY_OPTIONS));
      }
    }
    // initDeliveryPage() is called automatically when you navigate to
    // /delivery or /click-and-collect from /payment
    // so if we are already on /delivery or /click-and-collect we need to call manually here
    if (isOnClickCollect || isOnDelivery) {
      const refresh = true;
      await dispatch(initDeliveryPage(refresh));
    }
  }
};


export default handleInconsistentOrderFormState;
