import get from 'lodash/get';

function transformDeliveries(action) {

  const orderForm = get(action, 'result.orderForm', {});
  const deliveries = get(orderForm, 'deliveries', []);
  const items = get(orderForm, 'items', []);

  return deliveries.map((delivery) => {
    const itemIds = delivery.items.map(d => d.id);
    const deliveryItems = items.filter(item => itemIds.includes(item.id));

    return {
      ...delivery,
      items: deliveryItems,
    };
  });
}

export default transformDeliveries;
