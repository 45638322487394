import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';

export const shouldApplyRewards = (state) => {
  if (!isFeatureActive(state, featureConstants.ENABLE_CLIP_TO_CARD)) return false;

  const {
    user: {
      myJLMemberNumber,
    } = {},
    orderForm: {
      shouldApplyRewards,
    } = {},
  } = state ?? {};

  const isMember = !!myJLMemberNumber;
  if (!isMember) return false;

  if (!shouldApplyRewards) return false;

  return true;
};
