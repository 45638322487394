/* eslint-disable css-modules/no-unused-class */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// jl-design-system
import Input from 'jl-design-system/elements/input/Input';
import Textarea from 'jl-design-system/elements/textarea/Textarea';
import { Message } from 'jl-design-system/components/message/v2/Message';
// utils
import { setDefaultGiftMessageValue } from '../../redux/actions/app/appActions';
import {
  getTextAreaLinesArray,
  isAscii,
  getNextCharsInfo,
  getCharsLeft,
  calcLinesAfterEdit,
  replaceNonAsciiExtended,
  parseCharCode,
} from '../../utils/string/textAreaUtils';

import BodyText from '../body-text';
import Container from '../container';
import styles from './gift-message.scss';

export class GiftMessage extends PureComponent {

  static propTypes = {
    ...Input.propTypes,
    ariaDescribedBy: PropTypes.string,
    classNameOverride: PropTypes.string,
    defaultGiftMessageValue: PropTypes.string,
    describedById: PropTypes.string,
    giftMessageValidationFailed: PropTypes.bool,
    id: PropTypes.string,
    maxChars: PropTypes.number,
    maxCharsLine: PropTypes.number,
    maxLines: PropTypes.number,
    setDefaultGiftMessageValue: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    ...Input.defaultProps,
    ariaDescribedBy: undefined,
    classNameOverride: undefined,
    defaultGiftMessageValue: '',
    describedById: undefined,
    giftMessageValidationFailed: false,
    maxChars: 0,
    maxCharsLine: 0,
    maxLines: 0,
    setDefaultGiftMessageValue: window.defaultFunc,
    value: '',
  };

  constructor(props) {
    super(props);

    this.textArea = {
      input: {},
    };

    const { value, maxLines, maxChars, maxCharsLine } = this.props;
    const linesArray = getTextAreaLinesArray({
      string: value,
      maxLines,
      maxCharsLine,
    });
    const charsLeft = getCharsLeft({
      textArea: this.textArea.input,
      linesArray,
      maxChars,
      maxLines,
      maxCharsLine,
    });

    this.state = {
      charsLeft,
      linesLeft: maxLines - linesArray.length,
    };
  }

  onKeyDown = (evt) => {
    const { maxLines, maxCharsLine } = this.props;
    const { charsLeft } = this.state;
    const charCode = evt.key.toLowerCase();

    if (!charCode.includes('arrow')) {

      const isBackspace = charCode === 'backspace' || charCode === 'delete';
      const isTab = charCode === 'tab';
      const { prevChar, nextChar, newLine } = getNextCharsInfo(this.textArea.input);
      const doNotAllowSpace = prevChar === ' ' || nextChar === ' ' || newLine;
      const isNotAscii = !isAscii({ value: charCode, extended: true });
      const insert = parseCharCode(charCode);

      const linesAfterEdit = calcLinesAfterEdit({
        textArea: this.textArea.input,
        insert,
        maxCharsLine,
      });
      const willExceedMaxLines = linesAfterEdit > maxLines;

      if (
        (!charsLeft && !isBackspace && !isTab) ||
        isNotAscii ||
        (charCode === ' ' && doNotAllowSpace) ||
        willExceedMaxLines
      ) {
        evt.preventDefault();
      }
    }
  };

  onKeyUp = (evt) => {
    const charCode = evt.key.toLowerCase();

    if (charCode.includes('arrow')) {
      this.calcStats();
    }
  };

  onChange = (evt) => {
    const inputElement = this.textArea?.input;
    if (inputElement) {
      this.textArea.input.value = replaceNonAsciiExtended(this.textArea.input.value);

      // pass event through to redux-form
      const { giftFormId, onChange, setDefaultGiftMessageValue } = this.props;
      onChange(evt);

      this.calcStats();

      setDefaultGiftMessageValue({ value: this.textArea.input.value, giftFormId });
    }
  };

  onFocus = (evt) => {
    // pass event through to redux-form
    const { onFocus } = this.props;
    onFocus(evt);

    this.calcStats();
  };

  onBlur = (evt) => {
    // pass event through to redux-form
    const { onBlur } = this.props;
    onBlur(evt);

    this.calcStats();
  };

  onPaste = (evt) => {
    const { maxLines, maxCharsLine } = this.props;
    const clipboardData = replaceNonAsciiExtended(evt.clipboardData.getData('Text'));
    const willExceedMaxLines = calcLinesAfterEdit({
      textArea: this.textArea.input,
      insert: clipboardData,
      maxCharsLine,
    }) > maxLines;

    evt.clipboardData.setData('Text', clipboardData);

    if (willExceedMaxLines) {
      evt.preventDefault();
    }
  };

  getRemainingCharacters = (linesArray) => {
    const { maxChars, maxCharsLine, maxLines } = this.props;

    return getCharsLeft({
      textArea: this.textArea.input,
      linesArray,
      maxLines,
      maxChars,
      maxCharsLine,
    });
  }

  calcStats() {
    const { maxCharsLine, maxLines } = this.props;

    const { value } = this.textArea.input;

    const linesArray = getTextAreaLinesArray({
      string: value,
      maxLines,
      maxCharsLine,
    });

    let charsLeft = this.getRemainingCharacters(linesArray);

    // Handle negative values because of iOS https://www.jlpit.com/jira/browse/MARV-4403
    const charsLeftIsNegative = charsLeft && charsLeft / Math.abs(charsLeft) < 0;
    if (charsLeftIsNegative) {
      // trim the characters that have gone over the limit and set the textarea value
      this.textArea.input.value =
        this.textArea.input.value.substring(Math.abs(charsLeft), this.textArea.input.value.length).trim();

      // recalculate new characters left after truncating
      charsLeft = this.getRemainingCharacters(linesArray);
    }

    const linesLeftIsNegative = maxLines - linesArray.length < 0;

    this.setState({
      charsLeft: charsLeftIsNegative ? 0 : charsLeft,
      linesLeft: linesLeftIsNegative ? 0 : maxLines - linesArray.length,
    });
  }


  render() {
    const {
      ariaDescribedBy,
      describedById,
      defaultGiftMessageValue,
      maxChars,
      maxLines,
      value,
      giftMessageValidationFailed,
      classNameOverride,
      ...rest
    } = this.props;

    const {
      charsLeft,
      linesLeft,
    } = this.state;

    // need to declare this here to get around jsx-ally linting issue
    const role = 'text';

    return (
      <div className={classNameOverride}>
        {giftMessageValidationFailed && (
          <Container marginBottom="3">
            <Message
              body="Please check your gift message for extra characters or lines and try again."
              data-test="gift-message-validation-failed-error"
              title="Sorry, something went wrong"
              type="error"
            />
          </Container>
        )}

        <div className={styles.messageContainer}>
          <Container marginBottom="1">
            <Textarea
              {...rest}
              ref={(el) => {
                this.textArea = el;
              }}
              ariaDescribedBy={ariaDescribedBy}
              defaultValue={defaultGiftMessageValue}
              extensionProps={{
                onChange: this.onChange,
                onKeyDown: this.onKeyDown,
                onKeyUp: this.onKeyUp,
                onFocus: this.onFocus,
                onClick: this.onFocus,
                onBlur: this.onBlur,
                onPaste: this.onPaste,
              }}
              maxLength={maxChars + (maxLines - 1)}
            />
          </Container>
          <div id={describedById}>
            <BodyText marginBottom="1" role={role}>
              <strong>{charsLeft}</strong> characters and <strong>{linesLeft}</strong> lines remaining
            </BodyText>
          </div>
        </div>
        <BodyText>
          Delivery notes are only printed when the order is a gift. Prices will not be shown
        </BodyText>
      </div>
    );
  }
}

export function mapStateToProps(state, ownProps) {
  return {
    defaultGiftMessageValue: state.app?.defaultGiftMessageValue?.[ownProps.giftFormId] ?? '',
    giftMessageValidationFailed: state.delivery?.giftMessageValidationFailed,
  };
}

export function mapDispatchToProps() {
  return {
    setDefaultGiftMessageValue,
  };
}

export default connect((state, ownProps) => mapStateToProps(state, ownProps), mapDispatchToProps())(GiftMessage);
