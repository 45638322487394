import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import { getAppIsLoadingSelector } from '../../redux/reducers/app/appSelector';
import { getConfirmationOrderSelector } from '../../redux/reducers/confirmation/orderConfirmationSelector';
import { getRouterLocationPathname } from '../../redux/reducers/router/routerSelector';
import shouldShowFullScreenSignUp from '../../utils/myjl/fullScreenSignUp';

const getShowFullScreenSignUpFlowSelector = createSelector(
  [(state: RootState) => state],
  (state: RootState) => shouldShowFullScreenSignUp(state),
);

const headerState = createSelector(
  [
    getAppIsLoadingSelector,
    getConfirmationOrderSelector,
    getRouterLocationPathname,
    getShowFullScreenSignUpFlowSelector,
  ],
  (isLoading, order, pathname, showFullScreenSignUpFlow) => ({
    isLoading,
    order,
    pathname,
    showFullScreenSignUpFlow,
  }),
);

export default headerState;
