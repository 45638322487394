import { replace } from 'connected-react-router';
//
import {
  GET_AGE_VERIFICATION_RESULT,
  GET_AGE_VERIFICATION_SESSION,
  REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET,
  SET_AGE_VERIFICATION_CONFIRMATION_STATE,
  SET_AGE_VERIFICATION_LOADING_STATE,
  USER_AGE_CHECK,
} from '../../../constants/actionConstants';
import {
  URL_AGE_VERIFICATION_SESSION,
  URL_AGE_VERIFICATION_CALLBACK,
} from '../../../constants/endpointConstants';
import { FAILED_AGE_CHECK_REQUIRED } from '../../../constants/ageVerificationConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import routeConstants from '../../../constants/routeConstants';
import featureConstants from '../../../constants/featureConstants';

import { isFeatureActive } from '../../reducers/config/configReducer';

import { handleRemoveItem, removeItemsFromBasket } from '../edit-basket/editBasketActions';

export const shouldShowAgeVerificationCheckForAllItems =
  state => isFeatureActive(state, featureConstants.ENABLE_YOTI_FOR_ALL_AGE_ITEMS);

export const shouldShowAgeVerificationCheck = state => isFeatureActive(state, featureConstants.ENABLE_YOTI) ||
  shouldShowAgeVerificationCheckForAllItems(state);


export const getAgeVerificationSession = () => ({
  type: GET_AGE_VERIFICATION_SESSION,
  request: client => client({ path: URL_AGE_VERIFICATION_SESSION, config: { method: 'POST' } }),
});

export const setAgeVerificationLoadingState = (loadingState = false) => ({
  type: SET_AGE_VERIFICATION_LOADING_STATE,
  loadingState,
});

export const setAgeVerificationConfirmationState = (confirmationState = false) => ({
  type: SET_AGE_VERIFICATION_CONFIRMATION_STATE,
  confirmationState,
});

export const getItemsNeedingAgeVerification =
  items => items.filter(item => item.ageCheck === FAILED_AGE_CHECK_REQUIRED);

export const handleAgeVerificationCallback = (
  {
    retryTimeout = window.env.yotiRetryTimeout || 3000,
    retryAttempts = window.env.yotiRetryAttempts || 10,
    attemptNumber = 1,
  } = {},
) => async (dispatch, getState) => {
  const sessionId = getState().user.yotiSessionId;
  const response = await dispatch({
    type: GET_AGE_VERIFICATION_RESULT,
    request: client => client({ path: URL_AGE_VERIFICATION_CALLBACK, config: { method: 'POST', body: { sessionId } } }),
  });

  if (response.error) {
    const shouldAttemptCallback = attemptNumber < retryAttempts &&
      response.error.code === errorCodeConstants.AGE_VERIFICATION_IN_PROGRESS;

    if (shouldAttemptCallback) {
      const nextAttemptNumber = attemptNumber + 1;
      setTimeout(() => {
        dispatch(handleAgeVerificationCallback({ attemptNumber: nextAttemptNumber, retryTimeout, retryAttempts }));
      }, retryTimeout);
      return;
    }

    dispatch(getAgeVerificationSession());
  } else {
    dispatch({ type: `${USER_AGE_CHECK}.SUCCESS` });
  }

  dispatch(replace({ pathname: routeConstants.DELIVERY_CHOICE }));
};

export const handleAgeVerificationRemoveItem = ({ itemsToRemove, lastItemInBasket = false }) => async (dispatch) => {
  await dispatch({ type: REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET });

  const itemIds = itemsToRemove?.map(item => (item.id));
  if (itemsToRemove.length === 1) {
    await dispatch(
      handleRemoveItem({
        itemId: itemIds[0],
        lastItemInBasket,
        isAgeVerification: true,
      }),
    );
  } else {
    await dispatch(removeItemsFromBasket({ itemIds, shouldInitDeliveryPage: true }));
  }

  await dispatch({ type: `${REMOVE_AGE_VERIFICATION_ITEMS_FROM_BASKET}.SUCCESS` });
};
