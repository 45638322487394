import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';

// Design System
import { TertiaryButton } from 'jl-design-system/elements/button/Button';
import ChevronRight24PxOutlinedIcon from 'jl-design-system/elements/icons-jb/ChevronRight24PxOutlined';

// Types
import { OrderSummaryProps, OrderSummaryTitleProps } from './OrderSummary.types';

// Config
import orderSummaryState from './OrderSummary.state';
import { triggerExpandBasketButtonClickedAnalytics } from '../../redux/actions/app/appActions';
import { showEditBasketModal } from '../../redux/actions/edit-basket/editBasketActions';
import { getFormId } from '../../utils/form/configs/itemQuantity';

// Components
import Container from '../container';
import Heading from '../heading';
import OrderItem from '../order-item';
import TradeInDetails from '../trade-in-details';

// Styles
import styles from './order-summary.scss';

const itemsNeededToEnableExpander = 3;
const spaceNeededForToggleButton = 70;

export const OrderSummaryTitle = ({
  button,
  id,
  label,
  totalQuantity = 1,
}: OrderSummaryTitleProps) => {
  const heading = totalQuantity === 1 ?
    '(1 item)' :
    `(${totalQuantity} items)`;

  return (
    <div className={styles.sidebarHeading}>
      <Heading
        className={styles.headingTitle}
        id={id}
        tag="h2"
        testId="order-summary-header"
        type="s"
      >{label} <span>{heading}</span>
      </Heading>
      {button && button}
    </div>
  );
};

const OrderSummary = ({
  classNames = {},
  collapsed = false,
  displayHeaders = true,
  form,
  hideChildItems = false,
  hideEditBasket = false,
  hideOutOfStockText = false,
  hideQuantities = false,
  hideSubtotals = false,
  isMultiBatch = false,
  isOrderConfirmation = false,
  items = [],
  modalView = false,
  preventEditing = false,
  showReducedQuantityDetails = false,
  sidebarView = false,
}: OrderSummaryProps) => {
  const [expanded, setExpanded] = useState(false);

  const dispatch = useDispatch<any>();
  const {
    pathname,
    initPaymentPageCallActive,
    isLoadingLazyComponent,
    putDeliveryAddressApiCallActive,
    proceedingToPayment,
    getBatchesDeliveryMethodsApiCallActive,
    paymentProcessing,
    postAgeVerificationApiCallsActive,
    tradeInQuotes = [],
    showTradeInBoxInOrderConfirmation,
  } = useSelector(orderSummaryState);
  const elRef = useRef<HTMLDivElement>(null);

  const handleShowEditBasketModalClick = () => {
    dispatch(showEditBasketModal(pathname));
  };

  const setInlineHeightStyles = () => {

    if (elRef.current && elRef.current.querySelectorAll && !collapsed) {
      const orderSummaryItems = elRef.current.querySelectorAll('[data-index^="order-summary-item"]');

      if (orderSummaryItems.length >= itemsNeededToEnableExpander) {
        if (!expanded) {
          const heightContracted = orderSummaryItems[0].clientHeight + orderSummaryItems[1].clientHeight;
          setContainerHeight(heightContracted);
        } else {
          let heightExpanded = spaceNeededForToggleButton;
          for (let i = 0; i < orderSummaryItems.length; i += 1) {
            heightExpanded += orderSummaryItems[i].clientHeight;
          }
          setContainerHeight(heightExpanded);
        }
      } else {
        removeContainerHeight();
      }
    }
  };

  useEffect(() => {
    setInlineHeightStyles();
  });

  const getContainerElement = () => elRef.current?.querySelector<HTMLDivElement>('[data-test="order-summary-item-container"]');

  const removeContainerHeight = () => {
    const containerElement = getContainerElement();
    if (containerElement) {
      containerElement.style.removeProperty('height');
    }
  };

  const setContainerHeight = (height: number) => {
    const containerElement = getContainerElement();
    if (containerElement) {
      containerElement.style.height = `${height}px`;
    }
  };

  const setExpandToggleState = () => {
    const isExpanded = expanded;
    setExpanded(!isExpanded);

    if (!isExpanded) {
      dispatch(triggerExpandBasketButtonClickedAnalytics());
    }
  };

  const renderExpandToggle = (totalRenderedItems: number) => {
    if (totalRenderedItems >= itemsNeededToEnableExpander) {
      return (
        <Container className={styles.expandToggleContainer} hideForPrint>
          <button
            className={styles.expandToggleButton}
            onClick={setExpandToggleState}
            type="button"
          >
            <span data-test="show-item-button">{expanded ? 'Show less' : 'Show more items'}</span>
          </button>
        </Container>
      );
    }

    return null;
  };

  const disableEditButton = () => initPaymentPageCallActive ||
    putDeliveryAddressApiCallActive ||
    proceedingToPayment ||
    getBatchesDeliveryMethodsApiCallActive ||
    paymentProcessing ||
    postAgeVerificationApiCallsActive;

  let totalQuantity = 0;
  let totalRenderedItems = 0;

  const orderItems = items.map((item = {}) => {
    const isItemFabric = item.uom !== '';
    totalRenderedItems += 1;

    if (!isItemFabric) {
      totalQuantity += item.quantity || 0;
    } else {
      totalQuantity += 1;
    }

    const reducedQuantity = showReducedQuantityDetails ? Number(item?.availability?.stockLevel) : undefined;

    const additionalServicesText = isOrderConfirmation ?
      <strong>Additional services</strong>
      : <p>Additional services</p>;

    const itemContainerClassnames = cx(styles.itemContainer, classNames.item, {
      [styles.orderConfirmation]: isOrderConfirmation,
    });

    return (
      <div
        key={item.id}
        className={itemContainerClassnames}
        data-index={`order-summary-item-${totalQuantity}`}
        data-test="order-summary-item"
      >
        <OrderItem
          displayImage={!!item.imageUrl}
          hideOutOfStockText={hideOutOfStockText}
          hideQuantities={hideQuantities}
          hideSubtotals={hideSubtotals}
          isOrderConfirmation={isOrderConfirmation}
          modal={modalView}
          reducedQuantity={reducedQuantity}
          removeItem={handleShowEditBasketModalClick}
          sidebar={sidebarView}
          {...item}
        />
        {!hideChildItems && !!item.childItems?.length &&
          // eslint-disable-next-line @typescript-eslint/default-param-last
          item.childItems.map((childItem = {}, index) => {
            const selectedParentQuantity = get(form, `${getFormId(item.id)}.values.itemQuantity`, reducedQuantity);
            let childItemReducedQuantity;

            if (selectedParentQuantity && childItem.quantity) {
              childItemReducedQuantity = selectedParentQuantity < childItem.quantity ?
                selectedParentQuantity :
                undefined;
            }

            totalQuantity += childItem.quantity || 0;

            return (
              <div key={childItem.id} className={styles.additionalServices}>
                {(sidebarView || isOrderConfirmation) &&
                  index === 0 &&
                  additionalServicesText}
                <OrderItem
                  child
                  displayImage={false}
                  hideQuantities={hideQuantities}
                  hideSubtotals={hideSubtotals}
                  isOrderConfirmation={isOrderConfirmation}
                  modal={modalView}
                  preventEditing={preventEditing}
                  reducedQuantity={childItemReducedQuantity}
                  sidebar={sidebarView}
                  {...childItem}
                />
              </div>
            );
          })}
      </div>
    );
  });

  const containerShouldBeContracted = !expanded && totalRenderedItems >= itemsNeededToEnableExpander;
  const containerShouldBeExpanded = expanded && totalRenderedItems >= itemsNeededToEnableExpander;
  const enableScrollableContainer = containerShouldBeContracted && sidebarView;

  const itemsContainerClassnames = cx(styles.items, {
    [styles.itemContainerContracted]: containerShouldBeContracted,
    [styles.itemContainerExpanded]: containerShouldBeExpanded,
    [styles.scrollBar]: enableScrollableContainer,
    [styles.orderConfirmation]: isOrderConfirmation,
  });

  totalQuantity += tradeInQuotes.length;

  const headingId = 'order-summary-heading';

  const editBasketProps = {
    'aria-describedby': headingId,
    'data-test': 'edit-basket-button',
    disabled: disableEditButton(),
    onClick: handleShowEditBasketModalClick,
    size: 'small',
  };

  const containerClassnames = cx(
    styles.container,
    classNames.container,
    {
      [styles.containerCollapsed]: collapsed,
    },
  );

  return (
    <section
      ref={elRef}
      className={containerClassnames}
      data-test="order-summary-container"
    >
      {displayHeaders && (
        <header>
          <OrderSummaryTitle
            button={!hideEditBasket && (
              <TertiaryButton
                {...editBasketProps}
                className={styles.editBasketButton}
                IconComponent={ChevronRight24PxOutlinedIcon}
                small
                submitting={isLoadingLazyComponent}
              >
                {collapsed ? 'View' : 'Edit'}
              </TertiaryButton>
            )}
            id={headingId}
            label="Basket"
            totalQuantity={totalQuantity}
          />
        </header>
      )}

      {!collapsed && (
        <>
          {!isMultiBatch && (
            <TradeInDetails
              showTradeInBoxInOrderConfirmation={showTradeInBoxInOrderConfirmation}
              tradeInItems={tradeInQuotes}
            />
          )}
          <div className={styles.itemContainerWrapper}>
            <div
              className={itemsContainerClassnames}
              data-test="order-summary-item-container"
            >
              {orderItems}
              {!enableScrollableContainer && renderExpandToggle(totalRenderedItems)}
            </div>
            {enableScrollableContainer && (
              <div className={styles.fade} data-test="scrollbar-fade" />
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default OrderSummary;
