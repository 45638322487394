import React from 'react';
import cx from 'classnames';

// Types
import { BodyTextThemeProps } from './BodyText.types';

// Config
import { textThemes, getSpacingClass } from '../design-system/DesignSystem.constants';
import { getDataAttributes } from '../../utils/helpers/dataAttributes';

const BodyText = ({
  children,
  className,
  marginBottom = '0',
  maskContentSquare = false,
  maskPercy = false,
  maskText = false,
  noTheme = false,
  tag = 'p',
  testId,
  type = 'm',
  ...otherProps
}: BodyTextThemeProps & React.HTMLAttributes<HTMLElement>) => {
  const Tag = tag;
  const themeClass = !noTheme && (textThemes[type] || textThemes.m);
  const marginBottomClass = getSpacingClass({ type: 'margin', position: 'bottom', size: marginBottom, backupSize: '0' });

  return children && (
    <Tag
      className={cx(themeClass, marginBottomClass, className)}
      {...getDataAttributes({ maskContentSquare, maskPercy, maskText, testId })}
      {...otherProps}
    >{children}
    </Tag>
  );
};

export default BodyText;
