export default {
  // AUTH
  LOGIN: '/login',
  APPS_HANDOVER: '/app/handover',
  APPS_GUEST_HANDOVER: '/app/handover/guest',
  APPS_AUTHENTICATED_HANDOVER: '/app/handover/auth',
  WEB_AUTH0_FALLBACK_QUERY_PARAM: 'auth0Fallback',
  CUSTOMER_APPS_FALLBACK_QUERY_PARAM: 'customerAppsFallback',
  WEB_HANDOVER: '/web/handover',

  NOT_FOUND: '/*',
  DELIVERY_CHOICE: '/delivery-choice',
  SESSION_EXPIRED: '/session-expired',

  // DELIVERY
  DELIVERY: '/delivery',
  DELIVERY_ADDRESS_BOOK: '/delivery/address-book',
  DELIVERY_ADDRESS_BOOK_EDIT: '/delivery/address-book/edit',
  DELIVERY_ADDRESS_BOOK_NEW: '/delivery/address-book/new',
  DELIVERY_OPTIONS: '/delivery/delivery-options',

  // CLICK AND COLLECT
  CLICK_AND_COLLECT: '/click-and-collect',
  CLICK_AND_COLLECT_SEARCH_MAP: '/click-and-collect/search-map',
  CLICK_AND_COLLECT_SEARCH_LIST: '/click-and-collect/search-list',
  CLICK_AND_COLLECT_STORE_DETAILS: '/click-and-collect/store-details',
  CLICK_AND_COLLECT_HEAD_OFFICE_PROMPT: '/click-and-collect/prompt',
  CLICK_AND_COLLECT_SAVED_COLLECTION_POINTS: '/click-and-collect/saved-collection-points',
  CLICK_AND_COLLECT_STORE_SELECTED: '/click-and-collect/store-selected',

  // PAYMENT
  PAYMENT: '/payment',
  PAYMENT_ADDRESS_BOOK: '/payment/address-book',
  PAYMENT_ADDRESS_BOOK_EDIT: '/payment/address-book/edit',
  PAYMENT_ADDRESS_BOOK_NEW: '/payment/address-book/new',
  PAYMENT_GIFT_OPTIONS: '/payment/gift-options',
  PAYMENT_STORE_DETAILS: '/payment/store-details',
  PAYMENT_CARD_DELETE: '/payment/payment-card-delete',
  PAYMENT_POS_CREDIT_CALLBACK: '/callback/pos-credit',

  // ORDER CONFIRMATION
  ORDER_CONFIRMATION: '/order-confirmation',
  ORDER_CONFIRMATION_JOIN_MY_JL: '/order-confirmation/join-my-jl',
  ORDER_CONFIRMATION_JOIN_MY_JL_EDIT: '/order-confirmation/join-my-jl/edit',
  ORDER_CONFIRMATION_JOIN_MY_JL_NEW: '/order-confirmation/join-my-jl/new',
  ORDER_CONFIRMATION_STORE_DETAILS: '/order-confirmation/store-details',

  // JOIN MY JL full screen
  JOIN_MY_JL: '/join-my-jl',
  JOIN_MY_JL_EDIT: '/join-my-jl/edit',
  JOIN_MY_JL_NEW: '/join-my-jl/new',

  // GENERIC
  ADDRESS_BOOK: '/address-book',
  ADDRESS_BOOK_EDIT: '/address-book/edit',
  ADDRESS_BOOK_NEW: '/address-book/new',
  EDIT_BASKET: '/edit-basket',
  LEAVE_CHECKOUT: '/leave-checkout',

  // AUTH0 CALLBACKS
  CALLBACK_LOGIN_AUTHENTICATED: '/callback/login/auth',
  CALLBACK_LOGIN_GUEST: '/callback/login/guest',
  CALLBACK_ORDER_CLAIM: '/callback/claim-order',
  CALLBACK_CREATE_ACCOUNT: '/callback/registration',

  // AGE CALLBACK
  CALLBACK_AGE_VERIFICATION: '/callback/age-verification',
  CALLBACK_AGE_VERIFICATION_CANCEL: '/callback/age-verification/cancel',
};
