import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { getIsApplicationSelector } from '../../redux/reducers/app/appSelector';
import { getConfirmationExpiredSignedInSelector, getConfirmationOrderSelector } from '../../redux/reducers/confirmation/orderConfirmationSelector';
import { getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';
import { getIsGuestSelector } from '../../redux/reducers/user/userSelector';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { shouldApplyRewards } from '../../utils/payment/shouldApplyRewards';

const getWillMakeRewardsAPICallSelector = (state: RootState) => shouldApplyRewards(state);
const getShowCitrusAdsSelector = (state: RootState) => isFeatureActive(state, featureConstants.CITRUS_ADS, false, 'B');

const sidebarState = createSelector(
  [
    getConfirmationExpiredSignedInSelector,
    getIsApplicationSelector,
    getIsGuestSelector,
    getOrderFormItemsSelector,
    getConfirmationOrderSelector,
    getWillMakeRewardsAPICallSelector,
    getShowCitrusAdsSelector,
  ],
  (
    expiredSignedIn,
    isApplication,
    isGuest,
    items,
    order,
    willMakeRewardsAPICall,
    showCitrusAds,
  ) => ({
    expiredSignedIn,
    isApplication,
    isGuest,
    items,
    order,
    willMakeRewardsAPICall,
    showCitrusAds,
  }),
);

export default sidebarState;
