import isEmpty from 'lodash/isEmpty';
//
import { getDefaultPaymentCardOrFirst } from '../../reducers/user/userReducer';
import paymentCategoryConstants from '../../../constants/paymentCategoryConstants';
import { DETERMINE_SELECTED_PAYMENT_TYPE } from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import userConstants from '../../../constants/userConstants';
import { setSelectedPaymentCategory } from './paymentActions';
import isBasketPayPalPayLaterEligible from '../../../utils/payment/isBasketPayPalPayLaterEligible';
import { shouldShowClearpay } from '../../../utils/payment/isBasketClearpayEligible';
import isPayPalAvailable from '../../../utils/payment/isPayPalAvailable';

export function canSelectGooglePay(payment) {
  return payment.canMakeGooglePayPayments && payment.googlePayPaymentsClientHasBeenCreated;
}

export function posCreditNoLongerEligible(posCreditEligible, selectedPaymentType) {
  return !posCreditEligible && selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT);
}

export function payPalPayLaterNoLongerEligible(payPalPayLaterEligible, selectedPaymentType) {
  return !payPalPayLaterEligible && selectedPaymentType?.includes(paymentTypeConstants.PAYPAL_PAY_LATER);
}

export function clearpayNoLongerEligible(clearpayEligible, selectedPaymentType) {
  return !clearpayEligible && selectedPaymentType?.includes(paymentTypeConstants.CLEARPAY);
}

export function getAvailablePayLaterOptions(state = {}) {
  const availableOptions = [];

  if (isBasketPayPalPayLaterEligible(state)) availableOptions.push(paymentTypeConstants.PAYPAL_PAY_LATER);
  if (shouldShowClearpay(state)) availableOptions.push(paymentTypeConstants.CLEARPAY);
  if (
    state.payment?.posCreditEligible &&
    !isEmpty(state.payment?.creditTypeGroups)
  ) availableOptions.push(paymentTypeConstants.POS_CREDIT);

  return availableOptions;
}

export const checkPayLaterOptionsAvailable = () => (dispatch, getState) => {
  const {
    selectedPaymentType,
    selectedPaymentCategory,
    posCreditEligible,
  } = getState().payment;

  if (selectedPaymentCategory === paymentCategoryConstants.PAY_LATER) {
    // check if selected pay later option is still available and reset to PAY_NOW if not
    const payPalPayLaterEligible = isBasketPayPalPayLaterEligible(getState());
    const clearpayEligible = shouldShowClearpay(getState());

    const selectNewPaymentType =
      posCreditNoLongerEligible(posCreditEligible, selectedPaymentType) ||
      payPalPayLaterNoLongerEligible(payPalPayLaterEligible, selectedPaymentType) ||
      clearpayNoLongerEligible(clearpayEligible, selectedPaymentType);

    if (selectNewPaymentType) {
      return dispatch(setSelectedPaymentCategory(paymentCategoryConstants.PAY_NOW, false));
    }
  }

  return {};
};

export const priorityOrder = [
  paymentTypeConstants.APPLE_PAY,
  paymentTypeConstants.GOOGLE_PAY,
  paymentTypeConstants.SAVED_PAYMENT_CARD,
  paymentTypeConstants.CREDIT_CARD,
  paymentTypeConstants.PAYPAL,
];

export function getPayNowPriorityOrder(paymentMethodPreference) {
  const {
    APPLE_PAY,
    GOOGLE_PAY,
    SAVED_PAYMENT_CARD,
    PAYPAL,
  } = paymentTypeConstants;

  const {
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  } = userConstants;

  if ([
    PAYMENT_PREFERENCE_PAYPAL,
    PAYMENT_PREFERENCE_APPLE_PAY,
    PAYMENT_PREFERENCE_GOOGLE_PAY,
    PAYMENT_PREFERENCE_SAVED_CARD,
  ].includes(paymentMethodPreference)) {
    const lookup = new Map();
    lookup.set(PAYMENT_PREFERENCE_APPLE_PAY, APPLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_GOOGLE_PAY, GOOGLE_PAY);
    lookup.set(PAYMENT_PREFERENCE_SAVED_CARD, SAVED_PAYMENT_CARD);
    lookup.set(PAYMENT_PREFERENCE_PAYPAL, PAYPAL);

    // move the preferred payment method to the front of the list
    const mappedPreference = lookup.get(paymentMethodPreference);
    const found = priorityOrder.indexOf(mappedPreference);
    priorityOrder.unshift(priorityOrder.splice(found, 1)[0]);
  }

  return priorityOrder;
}

export const determineSelectedPaymentType = () => async (dispatch, getState) => {
  await dispatch(checkPayLaterOptionsAvailable());

  const {
    applePayCompatibilityChecksComplete,
    selectedPaymentType,
    paymentWallet = [],
    creditTypeGroups,
  } = getState().payment;

  if (!selectedPaymentType) {
    let newSelectedPaymentType;

    const defaultSavedPaymentCard = getDefaultPaymentCardOrFirst(paymentWallet);
    const selectedPaymentCategory = getState().payment.selectedPaymentCategory;

    if (!selectedPaymentCategory || selectedPaymentCategory === paymentCategoryConstants.PAY_NOW) {
      const {
        APPLE_PAY,
        GOOGLE_PAY,
        SAVED_PAYMENT_CARD,
        CREDIT_CARD,
        PAYPAL,
      } = paymentTypeConstants;

      const priorityOrder = getPayNowPriorityOrder(getState().user?.customerPreferences?.paymentChoice);

      priorityOrder.forEach(((method) => {
        // Try and select each payment method in turn, setting newSelectedPaymentType if any succeed to stop trying others
        if (!newSelectedPaymentType) {
          if (method === APPLE_PAY && applePayCompatibilityChecksComplete) newSelectedPaymentType = method;
          if (method === GOOGLE_PAY && canSelectGooglePay(getState().payment)) newSelectedPaymentType = method;
          if (method === SAVED_PAYMENT_CARD && defaultSavedPaymentCard) newSelectedPaymentType = `${SAVED_PAYMENT_CARD}:${defaultSavedPaymentCard.id}`;
          if (method === CREDIT_CARD && isEmpty(paymentWallet)) newSelectedPaymentType = CREDIT_CARD;
          if (method === PAYPAL && isPayPalAvailable(getState())) newSelectedPaymentType = method;
        }
      }));
    }

    if (selectedPaymentCategory === paymentCategoryConstants.PAY_LATER) {
      const payLaterOptionsAvailable = getAvailablePayLaterOptions(getState());

      if (payLaterOptionsAvailable.length === 1) {
        // Only select a pay later option when there's only one option available
        const option = payLaterOptionsAvailable[0];
        const {
          CLEARPAY,
          POS_CREDIT,
          PAYPAL_PAY_LATER,
        } = paymentTypeConstants;

        if (option === POS_CREDIT && creditTypeGroups.length === 1) newSelectedPaymentType = `${POS_CREDIT}:${creditTypeGroups[0]?.id}`;
        if (option === PAYPAL_PAY_LATER) newSelectedPaymentType = PAYPAL_PAY_LATER;
        if (option === CLEARPAY) newSelectedPaymentType = CLEARPAY;
      }
    }

    if (newSelectedPaymentType) {
      dispatch({
        type: DETERMINE_SELECTED_PAYMENT_TYPE,
        selectedPaymentType: newSelectedPaymentType,
      });
    }
  }
};

export default determineSelectedPaymentType;
