import {
  OCP_GET_SUBMISSION_SCRIPT,
  REMOVE_BASKET_ITEMS,
  PUT_ITEMS_QUANTITIES,
  REMOVE_ADDRESS,
  UPDATE_MARKETING_PREFERENCES_V3,
  PAYMENT_CARD_DELETE,
  PUT_DELIVERY_ADDRESS,
  GET_PAYPAL_CLIENT_TOKEN,
  AUTHENTICATE_PAYPAL_PAYMENT,
} from '../constants/actionConstants';
import errorCodeConstants from '../constants/errorCodeConstants';

export function shouldIgnoreError(errorType, errorCode) {

  // if an error type appears here ignore errors which are not internal server errors.
  const ignoreNonServerErrors = [
    PUT_DELIVERY_ADDRESS,
    PAYMENT_CARD_DELETE,
  ];

  if (ignoreNonServerErrors.includes(errorType) && errorCode !== errorCodeConstants.SERVER_INTERNAL_ERROR) {
    return true;
  }
  return false;
}

export function getError(error, type) {

  const errorMap = {
    [REMOVE_ADDRESS]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [REMOVE_BASKET_ITEMS]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [PUT_ITEMS_QUANTITIES]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [OCP_GET_SUBMISSION_SCRIPT]: errorCodeConstants.CLIENT_OCP_PAYMENT_ERROR,
    [UPDATE_MARKETING_PREFERENCES_V3]: errorCodeConstants.UPDATE_MARKETING_PREFERENCES_V3_ERROR,
    [PAYMENT_CARD_DELETE]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [PUT_DELIVERY_ADDRESS]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [GET_PAYPAL_CLIENT_TOKEN]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
    [AUTHENTICATE_PAYPAL_PAYMENT]: errorCodeConstants.CLIENT_HANDLE_SERVER_INTERNAL_ERROR,
  };

  if (errorMap[type] && !shouldIgnoreError(type, error.code)) {
    return {
      ...error,
      code: errorMap[type],
    };
  }

  return error;
}

export default function createApiMiddleware(client) {
  return ({ dispatch, getState }) => (
    next => (action) => {

      // redux thunk
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { request, types, type, ...rest } = action;
      if (!request) {
        return next(action);
      }

      let loading;
      let success;
      let failed;

      if (types) {
        ({ loading, success, failed } = types);
      } else if (type) {
        loading = `${type}.LOADING`;
        success = `${type}.SUCCESS`;
        failed = `${type}.FAILED`;
      }

      next({ ...rest, type: loading });

      // TODO how to test client is being called with params and getState?
      return request(params => client({ ...params, getState, dispatch })).then(
        result => next({ ...rest, result, type: success }),
        error => next({ ...rest, error: getError(error, type), type: failed }),
      );
    });
}
