import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// Design System
import validate from 'jl-design-system/form/validation/validate';

// Config
import { updateBillingAddress } from './cardPaymentActions';
import { initPaymentPage } from './orderFormActions';
import { triggerPlaceOrderAndPayAnalytics } from './paymentActions';
import { submitOrderAndRedirect } from './submitOrderActions';
import { isWeb } from '../../reducers/app/appReducer';
import { isFeatureActive } from '../../reducers/config/configReducer';
import {
  AUTHENTICATE_PAYPAL_PAYMENT,
  CREATE_PAYPAL_PAYMENT,
  GET_PAYPAL_CLIENT_TOKEN,
  SET_PAYPAL_SERVICE_UNAVAILABLE,
  PAYPAL_PAYMENT_CANCEL,
  PAYPAL_PAYMENT_FAILED,
  UPDATE_BILLING_ADDRESS,
  PAYPAL_STUBS_MODAL_CLOSE,
  PAYPAL_STUBS_MODAL_OPEN,
  PAYPAL_PAYMENT_STARTED,
  PAYPAL_PAYMENT_ENDED,
  SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS,
} from '../../../constants/actionConstants';
import {
  URL_AUTHENTICATE_PAYPAL_PAYMENT,
  URL_GET_PAYPAL_CLIENT_TOKEN,
} from '../../../constants/endpointConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import featureConstants from '../../../constants/featureConstants';
import { getBillingAddress } from '../../../utils/address/addressHelpers';
import getPayPalBillingDetailsFormConfig from '../../../utils/form/configs/payPalBillingAddress';
import { sendNewRelicCustomEvent } from '../../../utils/logging/logging-utils';

export const getPayPalClientToken = (showError = true) => ({
  type: GET_PAYPAL_CLIENT_TOKEN,
  request: client => client({ path: URL_GET_PAYPAL_CLIENT_TOKEN, config: { method: 'GET' } }),
  showError,
});

const authenticatePayPalPayment = body => ({
  type: AUTHENTICATE_PAYPAL_PAYMENT,
  request: client => client({ path: URL_AUTHENTICATE_PAYPAL_PAYMENT, config: { method: 'POST', body } }),
});

export const getPayPalPaymentRequest = () => async (dispatch, getState) => {
  const state = getState();
  const payPalPaymentProcessing = get(state, 'payment.payPalPaymentProcessing');

  if (payPalPaymentProcessing) dispatch({ type: PAYPAL_PAYMENT_ENDED });
  dispatch({ type: CREATE_PAYPAL_PAYMENT });

  return get(state, 'payment.payPalPaymentRequest', {});
};

export const setPayPalServiceUnavailable = () => ({
  type: SET_PAYPAL_SERVICE_UNAVAILABLE,
});

export const setCanMakePayPalPayExpressPayments = () => (dispatch, getState) => {
  if (!isFeatureActive(getState(), featureConstants.ENABLE_PAYPAL_EXPRESS)) return;

  dispatch({
    type: SET_CAN_MAKE_PAYPAL_PAY_EXPRESS_PAYMENTS,
  });
};

export const canMakePayPalPayExpressPayments = () => async (dispatch, getState) => {
  const state = getState();
  const compatibilityChecksComplete = get(state.payment, 'payPalPayExpressCompatibilityChecksComplete');

  if (compatibilityChecksComplete) {
    return;
  }

  if (isWeb(state)) {
    dispatch(setCanMakePayPalPayExpressPayments());
  }
};

export const payPalPaymentOnApprove =
  (firstName, lastName, phone, billingAddress, nonce) => async (dispatch, getState) => {
    const payPalPaymentProcessing = get(getState(), 'payment.payPalPaymentProcessing');
    if (payPalPaymentProcessing) return;

    const billingAddressRequest = {
      addressee: {
        firstName,
        lastName,
      },
      address: {
        addressLine1: billingAddress.line1,
        addressLine2: billingAddress.line2,
        addressLine3: billingAddress.line3,
        addressLine4: billingAddress.line4,
        townOrCity: billingAddress.city,
        postcode: billingAddress.postalCode,
        countryCode: billingAddress.countryCode,
        countyStateOrProvince: billingAddress.state,
      },
      phoneNumber: phone,
    };

    dispatch({ type: PAYPAL_PAYMENT_STARTED });

    const validateAddressResult = validate({
      ...billingAddressRequest.address,
      ...billingAddressRequest.addressee,
      phoneNumber: billingAddressRequest.phoneNumber,
    }, {
      config: getPayPalBillingDetailsFormConfig({
        actions: {},
        countryCode: billingAddress.countryCode,
        enableGBCounty: !!billingAddress.address?.countyStateOrProvince,
      }),
    });

    if (!isEmpty(validateAddressResult)) {
      dispatch({
        type: `${UPDATE_BILLING_ADDRESS}.FAILED`,
        error: {
          code: errorCodeConstants.INCOMPLETE_ADDRESS_DETAILS,
        },
        billingAddressFromPaypal: billingAddressRequest,
        paypalNonce: nonce,
      });

      dispatch({ type: PAYPAL_PAYMENT_ENDED });
      return;
    }

    const { type } = await dispatch(updateBillingAddress({ body: billingAddressRequest, nonce }));
    if (type === `${UPDATE_BILLING_ADDRESS}.FAILED`) {
      dispatch({ type: PAYPAL_PAYMENT_ENDED });
      return;
    }

    const authenticationResponse = await dispatch(authenticatePayPalPayment({ nonce }));
    await dispatch(triggerPlaceOrderAndPayAnalytics(authenticationResponse?.result?.jlppr));

    if (authenticationResponse.type === `${AUTHENTICATE_PAYPAL_PAYMENT}.SUCCESS`) {
      dispatch(submitOrderAndRedirect());
    }

    if (authenticationResponse.type === `${AUTHENTICATE_PAYPAL_PAYMENT}.FAILED`) {
      await dispatch(initPaymentPage());
      dispatch({ type: PAYPAL_PAYMENT_FAILED });
    }
  };

export const resubmitBillingAddress = nonce => async (dispatch, getState) => {
  const billingAddress = getBillingAddress(getState());

  return dispatch(payPalPaymentOnApprove(
    billingAddress.addressee.firstName,
    billingAddress.addressee.lastName,
    billingAddress.phoneNumber,
    {
      line1: billingAddress.address.addressLine1,
      line2: billingAddress.address.addressLine2,
      // addressLine3 and addressLine4 can only come from the invalid address modal when customer clicks 'add lines'
      line3: billingAddress.address.addressLine3,
      line4: billingAddress.address.addressLine4,
      city: billingAddress.address.townOrCity,
      postalCode: billingAddress.address.postcode,
      countryCode: billingAddress.address.countryCode,
      state: billingAddress.address.countyStateOrProvince,
    },
    nonce,
  ));
};

export const openPayPalStubsModal = () => async (dispatch) => {
  await dispatch(getPayPalPaymentRequest());
  dispatch({ type: PAYPAL_STUBS_MODAL_OPEN });
};

export const closePayPalStubsModal = () => ({
  type: PAYPAL_STUBS_MODAL_CLOSE,
});

export const payPalPaymentOnCancel = () => async (dispatch, getState) => {
  const state = getState();
  const payPalStubsModalOpen = state?.payment?.payPalStubsModalOpen;
  const sessionId = state?.bff?.sessionId ?? '';

  sendNewRelicCustomEvent('payPayCancelled', { sessionId });

  if (payPalStubsModalOpen) {
    dispatch(closePayPalStubsModal());
  }

  await dispatch(initPaymentPage());
  dispatch({ type: PAYPAL_PAYMENT_CANCEL });
};

export const payPalPaymentOnError = () => async (dispatch, getState) => {
  const state = getState();
  const payPalStubsModalOpen = state?.payment?.payPalStubsModalOpen;
  const sessionId = state?.bff?.sessionId ?? '';

  sendNewRelicCustomEvent('payPayFailed', { sessionId });

  if (payPalStubsModalOpen) {
    dispatch(closePayPalStubsModal());
  }

  await dispatch(initPaymentPage());
  dispatch({ type: PAYPAL_PAYMENT_FAILED });
};

export const mockPayPalIncompleteBillingAddress = () => (dispatch) => {
  dispatch(closePayPalStubsModal());

  dispatch(payPalPaymentOnApprove(
    'James',
    'Ellroy',
    '07777777777',
    {
      line1: '新登镇',
      line2: 'An unusually long line to trigger line length errors',
      postalCode: 'N75PQ',
      countryCode: 'GB',
      state: 'London',
    },
    'nonce',
  ));
};

export const mockPayPalApprove = (nonce = 'nonce') => async (dispatch) => {
  dispatch(closePayPalStubsModal());

  dispatch(payPalPaymentOnApprove(
    'James',
    'Ellroy',
    '07777777777',
    {
      line1: 'addressLine1',
      line2: 'addressLine2',
      city: 'townOrCity',
      postalCode: 'SW1E 5NN',
      countryCode: 'GB',
      state: 'countyStateOrProvince',
    },
    nonce,
  ));
};

export const mockPayPalApproveUS = (nonce = 'nonce') => async (dispatch) => {
  dispatch(closePayPalStubsModal());

  dispatch(payPalPaymentOnApprove(
    'James',
    'Ellroy',
    '+1650-555-5555',
    {
      line1: '1600 Amphitheatre Parkway',
      line2: 'Mountain View',
      city: 'CA',
      postalCode: '94 043',
      countryCode: 'US',
      state: 'NJ',
    },
    nonce,
  ));
};
