import { isAndroidApp } from '../../redux/reducers/app/appReducer';
import appConstants from '../../constants/appConstants';

export function androidAppSaveSessionStorage(state = {}) {
  if (!isAndroidApp(state)) return;

  if (typeof window?.CheckoutHandler?.persistSessionStorage !== 'function') return;

  if (!window.sessionStorage) return;

  setTimeout(() => {
    const key = `persist:${appConstants.ROOT}`;
    const pageRoot = window.sessionStorage.getItem(key);
    if (pageRoot) window.CheckoutHandler.persistSessionStorage(key, pageRoot);
  }, 500);
}
