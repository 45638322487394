import React from 'react';
import { useSelector } from 'react-redux';

// Design System
import { SecondaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { TechnicalErrorProps } from './TechnicalError.types';

// Config
import { getBasketUrlSelector } from '../../redux/reducers/bff/bffSelector';
import urlConstants from '../../constants/urlConstants';

// Components
import BodyText from '../body-text';
import Heading from '../heading';

// Styles
import styles from './technical-error.scss';

const TechnicalError = ({
  pageNotFoundTechnicalError = false,
}: TechnicalErrorProps) => {
  const basketUrl = useSelector(getBasketUrlSelector) || urlConstants.BASKET_PAGE_URL;
  let buttonText;
  let message;
  let title;

  if (pageNotFoundTechnicalError) {
    buttonText = 'Return to basket';
    message = 'Please return to basket and try again.';
    title = 'Sorry, this page is unavailable';
  } else {
    buttonText = 'Return to the homepage';
    message = 'Please return to the homepage.';
    title = "Sorry, we've had a technical problem";
  }

  return (
    <div className={styles.container}>
      <Heading
        label={title}
        marginBottom="2"
        type="s"
      />
      <BodyText marginBottom="3">{message}</BodyText>
      <SecondaryLink
        className={styles.button}
        data-action="handback"
        to={basketUrl}
      >
        <BodyText marginBottom="3" tag="span">{buttonText}</BodyText>
      </SecondaryLink>
    </div>
  );
};

export default TechnicalError;
