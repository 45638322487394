import { getCookieGroupPermission } from '../pecr/pecr';

export function shouldRemoveAndSaveForLater({
  isChildItem = false,
  isExpressCheckout = false,
  isOutlet = false,
  isSaveForLaterActive,
  isShipFromStore = false,
  isSignedIn = false,
  isTradeIn = false,
}) {
  const functionalCookiesEnabled = getCookieGroupPermission('Functional') === 'allow';
  const shouldSaveForLater = !isChildItem
    && !isExpressCheckout
    && !isOutlet
    && !isShipFromStore
    && (isSignedIn || functionalCookiesEnabled)
    && !isTradeIn;


  if (isSaveForLaterActive && shouldSaveForLater) {
    return true;
  }
  return false;
}
