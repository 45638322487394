import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Design System
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import { PrimaryLink, SecondaryLink } from 'jl-design-system/elements/link/Link';
import { Message } from 'jl-design-system/components/message/v2/Message';
import Modal from 'jl-design-system/components/modal/Modal';

// Types
import { AppDispatch } from 'types/RootState.types';

// Redux
import { removeBatchingFailureItems } from '../../redux/actions/edit-basket/editBasketActions';

// Components
import Container from '../container';
import OrderItem from '../order-item';

// Config
import batchingFailureModalState from './BatchingFailureModal.state';
import { getItemQuantityPhrase, getItemStatePhrase, pluraliseItemWord } from '../../utils/helpers/pluraliseItem';
import appConstants from '../../constants/appConstants';

// Styles
import styles from './batching-failure-modal.scss';

export const BatchingFailureModal = () => {
  const dispatch: AppDispatch = useDispatch();
  const {
    allItemsHaveBatchingFailure,
    basketUrl,
    items = [],
    removeItemsFailed,
    showModal,
  } = useSelector(batchingFailureModalState);

  if (!showModal) {
    return null;
  }

  const singleItem = items.length === 1;
  const removeItemButtonText = `Remove ${pluraliseItemWord(singleItem)} and continue`;
  const topText = `We're sorry but the below ${getItemStatePhrase(singleItem)} unavailable to buy online.`;

  const handleRemoveButtonClick = () => dispatch(removeBatchingFailureItems(items));

  return (
    <Modal
      header="Something's gone wrong"
      hideCloseButton
      isOpen
      rootId={appConstants.ROOT}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <span data-test="batching-failure-top-text">{topText}</span>

      <Container
        className={styles.itemContainer}
        marginY="3"
      >
        {items.map(item => <OrderItem {...item} key={item.id} displayImage hideSubtotals />)}
      </Container>

      {(allItemsHaveBatchingFailure || removeItemsFailed) && (
        <p data-test="batching-failure-bottom-text">
          Please return to the basket. We apologise for any inconvenience caused.
        </p>
      )}

      {(!allItemsHaveBatchingFailure && !removeItemsFailed) && (
        <Container testId="batching-failure-bottom-text">
          <p>
            Please remove {getItemQuantityPhrase(singleItem)} to continue with the rest of your
            order or you can return to the basket.
          </p>
          <p>We apologise for any inconvenience caused.</p>
        </Container>
      )}

      {removeItemsFailed && (
        <Container marginBottom="2">
          <Message
            title={`Sorry, we couldn't remove the ${pluraliseItemWord(singleItem)}. Please return to basket.`}
            type="error"
          />
        </Container>
      )}

      <div className={styles.buttonContainer}>
        {(allItemsHaveBatchingFailure || removeItemsFailed) && (
          <PrimaryLink
            data-action="handback"
            data-test="batching-failure-return-to-basket"
            to={basketUrl}
          >
            Return to basket
          </PrimaryLink>
        )}

        {(!allItemsHaveBatchingFailure && !removeItemsFailed) && (
          <>
            <SecondaryLink
              data-action="handback"
              data-test="batching-failure-return-to-basket"
              to={basketUrl}
            >
              Return to basket
            </SecondaryLink>

            <PrimaryButton
              data-test="batching-failure-remove-item"
              onClick={handleRemoveButtonClick}
            >
              {removeItemButtonText}
            </PrimaryButton>
          </>
        )}
      </div>
    </Modal>
  );
};

export default BatchingFailureModal;
