import { createSelector } from 'reselect';

// Types
import { RootState } from 'types/RootState.types';

// Config
import featureConstants from '../../constants/featureConstants';
import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import { getOrderFormItemsSelector } from '../../redux/reducers/order-form/orderFormSelector';

const PLACEMENT = 'OSPOrderConfirmation1';

const getRecommendationsServersideFetchSelector =
  (state: RootState) => isFeatureActive(state, featureConstants.RECOMMENDATIONS_SERVERSIDE_FETCH);

const getProductAndSkuIdsSelector = createSelector(
  [getOrderFormItemsSelector],
  (items = []) => {
    const productIds = items.map(item => item.productId);
    const skuIds = items.map(item => item.skuId);
    return { productIds, skuIds };
  },
);

const getRecommendationUrlParamsSelector = createSelector(
  [getProductAndSkuIdsSelector, getRecommendationsServersideFetchSelector],
  ({ productIds, skuIds }, serverSideFetch) => {
    const params = new URLSearchParams({
      placement: PLACEMENT,
      productId: productIds.join(),
      skuId: skuIds.join(),
      ...serverSideFetch && { serverSideFetch: String(serverSideFetch) },
    }).toString();
    return params;
  },
);

export const getRecommendationElementIdSelector = () => `jl-recommendations-panel-esi-${PLACEMENT.toLowerCase()}`;

const recommendationsState = createSelector(
  [getRecommendationUrlParamsSelector],
  params => `${window.publicJLSiteDomain}/esi/recommendations/?${params}`,
);

export default recommendationsState;
