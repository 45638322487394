import React from 'react';
import { useSelector } from 'react-redux';

// Config
import ageCheckerState from './AgeChecker.state';

// Components
import AgeCheckModal from '../age-check-modal';
import YotiModal from '../yoti-modal';
import YotiModalConfirmation from '../yoti-modal/confirmation/YotiModalConfirmation';

const AgeChecker = () => {
  const {
    ageVerificationProducts,
    isSaveForLaterActive,
    isSignedIn,
    itemsNeedingVerification,
    showAgeCheckModal,
    showYotiConfirmationModal,
    yotiSessionId,
  } = useSelector(ageCheckerState);

  // Honour based: DOB age check.
  if (showAgeCheckModal) {
    return (
      <AgeCheckModal showStepIndicator={!!yotiSessionId} />
    );
  }

  // Online Identity Verification: Yoti visual verification.
  if (ageVerificationProducts?.length) {
    return (
      <YotiModal
        ageVerificationProducts={ageVerificationProducts}
        isSaveForLaterActive={isSaveForLaterActive}
        isSignedIn={isSignedIn}
        itemsNeedingVerification={itemsNeedingVerification}
      />
    );
  }

  if (showYotiConfirmationModal) {
    return (
      <YotiModalConfirmation />
    );
  }

  return null;
};

export default AgeChecker;
