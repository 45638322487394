// lodash
import isString from 'lodash/isString';

export function isAscii({ value, extended = false }) {
  if (!isString(value)) {
    return false;
  }
  const regex = extended ? /[\x00-\xFF]/g : /[\x00-\x7F]/g;
  return regex.test(value);
}

export function replaceNonAsciiExtended(value) {
  if (!isString(value)) {
    return '';
  }
  const regex = /[^\x00-\xFF]/g;
  return value.replace(regex, '');
}

export function replaceLineBreaksWithSub(string, sub) {
  if (!isString(string)) {
    return string;
  }
  // split based on carriage returns
  // /\r\n|\n/
  const regex = /[\n\r]/g;
  return string.replace(regex, sub);
}

// TODO: swap below getSoftWrapStringArray?
export function getSoftWrapStringArraySimple({ string = '', maxCharsLine, trimLineStartSpace }) {
  const arr = [];
  let startIndex = 0;
  let endIndex = 0;

  while (endIndex < string.length) {
    startIndex = endIndex;
    endIndex = startIndex + maxCharsLine;

    // console.warn('while:', startIndex, endIndex);

    let line = string.substring(startIndex, endIndex);
    // console.warn(line);

    if (line.length === maxCharsLine) {
      const lastChar = string.charAt(line.length - 1);
      const nextChar = string.charAt(line.length);

      // line with spaces and end word spans soft break
      if (line.includes(' ') && lastChar !== ' ' && nextChar !== ' ') {
        const diff = line.length - line.lastIndexOf(' ') - 1;
        endIndex -= diff;
        line = string.substring(startIndex, endIndex);
        // console.warn('ADJUST:', line);
      }

      // space at start of next line... adjust to discard
      if (trimLineStartSpace && nextChar === ' ') {
        endIndex += 1;
      }
    }

    arr.push(line);
    // console.warn('ARRAY:', arr);
  }

  return arr;
}

export function getSoftWrapStringArray({ string = '', maxCharsLine }) {

  let line = '';
  const array = [];

  const wordsArray = string.split(' ');

  wordsArray.forEach((word, index) => {

    const remaining = maxCharsLine - line.length;

    if (word.length <= remaining) {
      if (word.length === remaining) {
        array.push(`${line}${word}`);
        // console.warn('PUSHING short line::', `${line}${word}`);
        // new line with space
        line = '';
      } else {
        // add to line
        line = `${line}${word} `;
      }
    } else {
      // ADDING NEW LINE
      if (line) {
        // console.warn('PUSHING line', line);
        array.push(line);
        // new line
        line = '';
      }

      if (word.length >= maxCharsLine) {
        const items = Math.ceil(word.length / maxCharsLine);
        const arr = [...Array(items)];
        arr.forEach((val, index) => {
          const offset = index * maxCharsLine;
          const chunk = word.substring(offset, offset + maxCharsLine);
          if (chunk.length === maxCharsLine) {
            // console.warn('PUSHING chunk::', chunk);
            array.push(chunk);
          } else {
            // console.warn('ADDING to line::', chunk);
            // add to line
            line = `${line}${chunk} `;
          }
        });
      } else {
        // console.warn('ADDING last word::', word);
        // new line
        line = `${word} `;
      }
    }

    // last word
    if (index === wordsArray.length - 1) {
      line = line.trim();
      if (line) {
        // console.warn('ADDING LAST line::', line);
        array.push(line);
      }
    }
  });

  return array;
}

export function getTextAreaLinesArray({ string = '', maxLines, maxCharsLine, isTextArea = true } = {}) {

  if (string && maxLines && maxCharsLine) {

    const getSoftStringWrapArrayFunc = isTextArea ? getSoftWrapStringArray : getSoftWrapStringArraySimple;

    const raw = replaceLineBreaksWithSub(string, '*RT*');
    const lineBreakArray = raw.split('*RT*');

    // split based on hard returns and soft wrapping
    const linesArray = lineBreakArray.reduce((acc, line) => {
      if (acc.length < maxLines) {
        if (line.length <= maxCharsLine) {
          return acc.concat(line);
        }
        const chunk = getSoftStringWrapArrayFunc({ string: line, maxCharsLine, trimLineStartSpace: isTextArea });
        // console.warn('>>>> soft wrap array:', chunk);
        return acc.concat(chunk);
      }
      return acc;
    }, []);

    // console.warn('linesArray:', linesArray);
    return linesArray;
  }
  return [];
}

export function getCaretLine({
  textArea = {},
  maxLines,
  maxCharsLine,
  prefix = '',
  isTextArea,
}) {
  // console.warn('>>>>>>>>> getCaretLine');
  const { selectionStart = 0, value } = textArea;
  const caretIndex = selectionStart + prefix.length;
  let caretLine = 0;

  const parsedValue = `${prefix}${value}`;

  if (parsedValue) {
    const charsBeforeCaretArray = getTextAreaLinesArray({
      string: parsedValue.substring(0, caretIndex),
      maxLines,
      maxCharsLine,
      isTextArea,
    });
    caretLine = charsBeforeCaretArray.length - 1;
  }

  return caretLine;
}

export function getNextCharsInfo(textArea = {}) {
  const { value, selectionStart = 0 } = textArea;

  if (value) {
    const caretIndex = selectionStart;
    const prevChar = value.charAt(caretIndex - 1);
    const nextChar = value.charAt(caretIndex);

    return {
      prevChar,
      nextChar,
      newLine: !prevChar || /[\n\r]/.test(prevChar),
    };
  }

  return {
    prevChar: '',
    nextChar: '',
    newLine: true,
  };
}

export function getCharsLeft({
  textArea = {},
  linesArray = [],
  maxChars,
  maxLines,
  maxCharsLine,
  prefix = '',
  isTextArea,
}) {
  const caretLine = getCaretLine({ textArea, maxLines, maxCharsLine, prefix, isTextArea });
  const charsUsed = linesArray.reduce((acc, line, index) => {
    if (index < caretLine) {
      return acc + maxCharsLine;
    }
    return acc + line.length;
  }, 0);
  // how many chars can be typed after the cursor position
  return maxChars - charsUsed;
}

export function calcLinesAfterEdit({
  textArea = {},
  insert = '',
  maxCharsLine,
  prefix = '',
  isTextArea,
}) {
  // console.warn('>>>>>>>>> calcLinesAfterEdit:', insert);
  const { value = '', selectionStart, selectionEnd } = textArea;

  const selectRange = selectionEnd - selectionStart;
  const isBackspace = insert === '';
  const singleDelete = isBackspace && !selectRange && selectionStart > 0;
  const startToIndex = singleDelete ? selectionStart - 1 : selectionStart;
  const endFromIndex = selectionEnd;

  const start = value.substring(0, startToIndex);
  const end = value.substring(endFromIndex);

  const string = `${prefix}${start}${insert}${end}`;
  const lines = getTextAreaLinesArray({ string, maxLines: 10, maxCharsLine, isTextArea });

  return lines.length;
}


export function parseCharCode(charCode) {
  if (charCode === 'backspace' || charCode === 'delete' || charCode === 'tab') {
    return '';
  }
  if (charCode === 'enter') {
    return '\n';
  }
  return charCode;
}
