import { SUBMIT_ORDER } from '../../constants/actionConstants';
import errorCodeConstants from '../../constants/errorCodeConstants';

export default function submitOrderCanRetry(action) {
  const {
    type,
    error: {
      code,
    } = {},
  } = action;

  return type === `${SUBMIT_ORDER}.FAILED` && code === errorCodeConstants.ORDERING_IN_PROGRESS;
}
