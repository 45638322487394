/* eslint-disable css-modules/no-unused-class */
import React from 'react';

// Components
import GiftReceipt from '../../../components/gift-receipt/GiftReceipt';
import Heading from '../../../components/heading';

// Styles
import styles from '../../../components/gift-message/gift-message.scss';

const GIFT_RECEIPT_CHECKBOX = {
  component: GiftReceipt,
  type: 'checkbox',
  id: 'giftReceipt',
  name: 'giftReceipt',
  label: 'I would like to include a gift message',
  labelClassName: styles.giftMessageCheckboxLabel,
  checkedValue: 'true',
};

export const GIFT_RECEIPT_UNAVAILABLE = {
  /* eslint-disable react/prop-types */
  component: props => (
    <Heading
      className={props?.classNameOverride}
      label="Sorry, gift messaging is unavailable for this delivery"
      tag="h4"
      type="xs"
    />
  ),
  id: 'giftReceiptUnavailable',
  name: 'giftReceiptUnavailable',
};

export default GIFT_RECEIPT_CHECKBOX;
