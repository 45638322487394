import React from 'react';

// Design System
import { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { OrderCompleteNotificationProps } from './OrderCompleteNotification.types';

// Config
import urlConstants from '../../constants/urlConstants';
import useWindowSize from '../../utils/helpers/window';

// Components
import BodyText from '../body-text';
import Container from '../container';
import Heading from '../heading';

const OrderCompleteNotification = ({
  browseUrl = urlConstants.HOME_PAGE_URL,
}: OrderCompleteNotificationProps) => {
  const { isDesktop } = useWindowSize();

  return (
    <Container paddingX="2" textAlign="center">
      <Heading
        label="Your order is complete"
        marginBottom="2"
        tag="h2"
        type="s"
      />
      <BodyText marginBottom="3">
        You can either exit your browser or continue to browse our website
      </BodyText>
      <PrimaryLink
        data-action="handback"
        inline={isDesktop}
        to={browseUrl}
      >
        Continue shopping
      </PrimaryLink>
    </Container>
  );
};

export default OrderCompleteNotification;
