import get from 'lodash/get';
import { POST_DELIVERY_PAGE } from '../../constants/actionConstants';
import userConstants from '../../constants/userConstants';
import deliveryConstants from '../../constants/deliveryConstants';
import getUndeliverableItems from '../delivery/getUndeliverableItems';

export default function setLoadingState(action) {
  if (action.type !== `${POST_DELIVERY_PAGE}.SUCCESS`) {
    return false;
  }

  if (!action.result?.customer?.preferences) {
    return false;
  }

  if (!action.fulfilmentPreferenceFeatureActive) {
    return false;
  }

  const customerFulfilmentChoice = action.result?.customer?.preferences?.fulfilmentChoice;
  if (customerFulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_UNKNOWN) {
    return false;
  }

  const orderForm = get(action, 'result.orderForm', {});

  const clickAndCollectAvailable = orderForm.deliveryOptions?.clickCollectAvailable ||
    orderForm.deliveryOptions?.collectPlusAvailable;

  if (customerFulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_COLLECTION) {
    if (!clickAndCollectAvailable) return false;

    if (!action.result?.customer?.collectionPoints.find(collectionPoint => collectionPoint.default)) return false;
  }

  if (orderForm.deliveries.length > 1) {
    // multibatch
    return false;
  }

  const deliveries = get(orderForm, 'deliveries');
  const items = get(orderForm, 'items', []);

  if (deliveries[0].type === deliveryConstants.GREEN_VAN_FLEET) {
    return false;
  }

  if (getUndeliverableItems(deliveries, items)) {
    return false;
  }

  if (customerFulfilmentChoice === userConstants.FULFILMENT_PREFERENCE_DELIVERY) {
    if (!action.result?.customer?.addressBook?.find(addressRecord => addressRecord.defaultShipping)) return false;
  }

  return true;
}
