import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

// Design System
import Form from 'jl-design-system/components/form/Form';
import { setFieldValue } from 'jl-design-system/redux/actions/form/formActions';

// Types
import { AppDispatch, RootState } from 'types/RootState.types';
import { SaveAsDefaultFormProps } from './SaveAsDefaultForm.types';

// Config
import saveAsDefaultFormState from './SaveAsDefaultForm.state';
import deliveryConstants from '../../constants/deliveryConstants';
import paymentTypeConstants from '../../constants/paymentTypeConstants';
import {
  SAVE_AS_DEFAULT_FORM_ID,
  getSaveAsDefaultFormConfig,
  getSaveAsDefaultFieldIdByType,
} from '../../utils/form/configs/saveAsDefault';

// Styles
import styles from './save-as-default-form.scss';

const SaveAsDefaultForm = ({
  disabled,
  onChange,
  saveAsDefaultObject,
  type,
}: SaveAsDefaultFormProps) => {
  const isInitialMount = useRef(true);
  const fieldId = getSaveAsDefaultFieldIdByType(type);

  const dispatch: AppDispatch = useDispatch();
  const {
    fieldValue,
    fulfilmentPreferenceFeatureActive,
    isSignedIn,
    saveDefaultCollectionPoint,
  } = useSelector((state: RootState) => saveAsDefaultFormState(state, fieldId));

  const resetFieldValues = ({ update = false } = {}) => {
    if (update) {
      dispatch(setFieldValue(SAVE_AS_DEFAULT_FORM_ID, fieldId, ''));
      return;
    }

    if (type === paymentTypeConstants.CREDIT_CARD) {
      if (fieldValue && saveAsDefaultObject?.id !== fieldValue) {
        dispatch(setFieldValue(SAVE_AS_DEFAULT_FORM_ID, fieldId, ''));
      }
    }

    if (type === deliveryConstants.DELIVERY) {
      dispatch(setFieldValue(SAVE_AS_DEFAULT_FORM_ID, getSaveAsDefaultFieldIdByType(deliveryConstants.CLICK_AND_COLLECT), ''));

      // https://www.jlpit.com/jira/browse/MARV-10097
      if (fieldValue === 'true' && saveAsDefaultObject?.id !== 'true') {
        dispatch(setFieldValue(
          SAVE_AS_DEFAULT_FORM_ID,
          getSaveAsDefaultFieldIdByType(deliveryConstants.DELIVERY),
          saveAsDefaultObject?.id,
        ));
      }
    }

    if (type === deliveryConstants.CLICK_AND_COLLECT) {
      dispatch(setFieldValue(SAVE_AS_DEFAULT_FORM_ID, getSaveAsDefaultFieldIdByType(deliveryConstants.DELIVERY), ''));
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      if (!fulfilmentPreferenceFeatureActive && isSignedIn) {
        resetFieldValues();
      }
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    resetFieldValues({ update: true });
  }, [saveAsDefaultObject]);

  if (type !== paymentTypeConstants.CREDIT_CARD && fulfilmentPreferenceFeatureActive) return null;

  if (!isSignedIn || !type || !saveAsDefaultObject) {
    return null;
  }

  if (type === deliveryConstants.DELIVERY && get(saveAsDefaultObject, 'defaultShipping')) {
    return null;
  }

  if (type === deliveryConstants.CLICK_AND_COLLECT) {
    if (get(saveAsDefaultObject, 'isDefault')) return null;

    if (saveDefaultCollectionPoint) return null;
  }

  if (type === paymentTypeConstants.CREDIT_CARD && get(saveAsDefaultObject, 'default')) {
    return null;
  }

  const config = getSaveAsDefaultFormConfig(type, saveAsDefaultObject?.id, onChange);

  return (
    <div className={styles.saveAsDefaultForm}>
      <Form
        config={config}
        data-test="save-as-default-form"
        destroyOnUnmount={false}
        disabled={disabled}
        form={config.id}
      />
    </div>
  );
};

export default SaveAsDefaultForm;
