import React from 'react';

// Types
import { ScrollAnchorProps } from './ScrollAnchor.types';

// Styles
import styles from './scroll-anchor.scss';

const ScrollAnchor = ({ scrollId }: ScrollAnchorProps) => scrollId && (
  <div
    className={styles.scrollAnchor}
    data-scroll={scrollId}
    data-test="scroll-anchor"
  />
);

export default ScrollAnchor;
