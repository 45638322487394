import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Design System
import Modal from 'jl-design-system/components/modal/Modal';
import { PrimaryButton } from 'jl-design-system/elements/button/Button';
import TickCircledRegularIcon from 'jl-design-system/elements/icons-jb/TickInCircle32PxOutlined';

// Types
import { AppDispatch } from 'types/RootState.types';
import { YotiModalConfirmationProps } from './YotiModalConfirmation.types';

// Config
import appConstants from '../../../constants/appConstants';
import { setAgeVerificationConfirmationState } from '../../../redux/actions/age-verification/ageVerificationActions';
import useWindowSize from '../../../utils/helpers/window';

// Components
import BodyText from '../../body-text';
import Container from '../../container';

// Styles
import styles from './yoti-modal-confirmation.scss';

const YotiModalConfirmation = ({
  onLoad = () => {},
}: YotiModalConfirmationProps) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    onLoad();
  }, []);

  const { isSmallDesktop } = useWindowSize();
  const buttonSize = isSmallDesktop ? 'large' : 'small';
  const textBodyType = isSmallDesktop ? 'l' : 'm';

  const onCloseHandler = () => dispatch(setAgeVerificationConfirmationState());

  return (
    <Modal
      forceFullHeight
      header="Age verification complete"
      headerStyleModifier={styles.modalHeader}
      isOpen
      large
      onClose={onCloseHandler}
      rootId={appConstants.ROOT}
    >
      <Container testId="yoti-modal-confirmation">
        <Container
          className={styles.content}
          marginBottom="4"
        >
          <TickCircledRegularIcon />
          <BodyText type={textBodyType}>We have confirmed you meet the required age</BodyText>
        </Container>
        <Container
          className={styles.buttonGroup}
          textAlign="right"
        >
          <PrimaryButton
            onClick={onCloseHandler}
            size={buttonSize}
          >
            Continue to checkout
          </PrimaryButton>
        </Container>
      </Container>
    </Modal>
  );
};

export default memo(YotiModalConfirmation);
