// lodash
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// constants
import paymentTypeConstants from '../../constants/paymentTypeConstants';

export default function getAppliedPayments(paymentStates, returnedPayments) {
  const removedPayments = paymentStates?.filter(paymentState => paymentState.isRemoved);
  const payments = !isEmpty(removedPayments) ? returnedPayments.concat(removedPayments) : returnedPayments;

  return payments.map((payment) => {
    const appliedPayment = returnedPayments?.filter(appliedPayment => payment.id === appliedPayment.id)[0];
    const previousStateEntry = paymentStates.find(paymentState => paymentState.id === payment.id);

    const isGiftCard = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_CARD;
    const isGiftVoucher = get(previousStateEntry, 'type', '') === paymentTypeConstants.GIFT_VOUCHER;

    return appliedPayment ? {
      ...appliedPayment,
      details: {
        ...appliedPayment.details,
        ...(isGiftCard && {
          pin: previousStateEntry.details.pin,
          cardNumber: previousStateEntry.details.cardNumber,
        }),
        ...(isGiftVoucher && {
          securityCode: previousStateEntry.details.securityCode,
          serialNumber: previousStateEntry.details.serialNumber,
        }),
      },
    } : payment;
  });
}
