import { createSelector } from 'reselect';

// Config
import {
  getConfirmationIsClickCollectOrderSelector,
  getConfirmationIsMultiBatchSelector,
  getConfirmationOrderAmountsSelector,
  getConfirmationOrderDeliveriesSelector,
  getConfirmationPromoCodeAppliedSelector,
} from '../../redux/reducers/confirmation/orderConfirmationSelector';
import {
  getOrderFormAmountsSelector,
  getOrderFormIsClickCollectOrderSelector,
  getOrderFormIsGiftReceiptOrderSelector,
  getOrderFormPromoCodeAppliedSelector,
  getOrderShouldHideRewardsValueSelector,
} from '../../redux/reducers/order-form/orderFormSelector';

const orderTotalsOrderFormState = createSelector(
  [
    getOrderFormAmountsSelector,
    getOrderFormIsGiftReceiptOrderSelector,
    getOrderFormIsClickCollectOrderSelector,
    getOrderFormPromoCodeAppliedSelector,
    getOrderShouldHideRewardsValueSelector,
  ],
  (amounts, isGiftReceiptOrder, isClickCollectOrder, promotionalCode, shouldHideRewardsValue) => ({
    amounts,
    isGiftReceiptOrder,
    isClickCollectOrder,
    promotionalCode,
    shouldHideRewardsValue,
  }),
);

const orderTotalsConfirmationPagePropsState = createSelector(
  [
    getConfirmationOrderAmountsSelector,
    getConfirmationIsMultiBatchSelector,
    getConfirmationOrderDeliveriesSelector,
    getConfirmationIsClickCollectOrderSelector,
    getConfirmationPromoCodeAppliedSelector,
  ],
  (amounts, isMultiBatch, deliveries, isClickCollectOrder, promotionalCode) => ({
    amounts,
    isGiftReceiptOrder: !isMultiBatch && deliveries?.some(delivery => delivery.isGiftReceiptOrder),
    isClickCollectOrder,
    promotionalCode,
    shouldHideRewardsValue: false,
  }),
);

export { orderTotalsConfirmationPagePropsState, orderTotalsOrderFormState };
