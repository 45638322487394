import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import featureConstants from '../../constants/featureConstants';

export default function googlePayAllowedAuthMethods(state, authMethodsFromConfig) {
  // Due to a limitation on Google's side, cryptogram 3ds auth method can only be
  // tested in integration if it's the ONLY auth method available
  if (isFeatureActive(state, featureConstants.TEST_CRYPTOGRAM_IN_INTEGRATION)) {
    return ['CRYPTOGRAM_3DS'];
  }

  return authMethodsFromConfig;
}
