import React from 'react';
import { useSelector } from 'react-redux';

// Design System
import LoadingSpinner from 'jl-design-system/components/loading-spinner/LoadingSpinner';
import { PrimaryLink } from 'jl-design-system/elements/link/Link';

// Types
import { SidebarProps } from './Sidebar.types';

// Config
import sidebarState from './Sidebar.state';
import urlConstants from '../../constants/urlConstants';

// Components
import Container from '../container';
import ESIBanner from '../esi-banner';
import MyAccountLink from '../my-account-link';
import OrderSummary, { OrderSummaryTitle } from '../order-summary';
import OrderTotals from '../order-totals';
import PaymentDetails from '../payment-details';

// Styles
import styles from './sidebar.scss';

export const SidebarContent = ({
  isDeliveryPage = false,
  isOrderConfirmationPage = false,
  showAds = false,
}: SidebarProps) => {
  const {
    expiredSignedIn,
    isApplication,
    isGuest,
    items = [],
    order,
    willMakeRewardsAPICall,
    showCitrusAds,
  } = useSelector(sidebarState);
  if (isOrderConfirmationPage) {
    const showESIBanner = showAds && showCitrusAds;
    const showMyAccountLink = !isGuest && !isApplication && !expiredSignedIn;

    return (
      <Container testId="sidebar-content">
        <Container className={styles.wrapper} marginBottom="2">
          <OrderSummaryTitle
            label="Order Summary"
            totalQuantity={items.length}
          />
          <OrderTotals confirmationPage sidebarView />
          <PaymentDetails
            payments={order?.payments}
          />
        </Container>
        <Container className={styles.actions} marginBottom={showESIBanner ? '2' : '0'}>
          <PrimaryLink
            className={styles.primaryLink}
            data-action="handback"
            data-test="sidebar-continue-shopping"
            to={urlConstants.HOME_PAGE_URL}
          >
            Continue shopping
          </PrimaryLink>

          {showMyAccountLink && (
            <MyAccountLink
              className={styles.secondaryLink}
              level="secondary"
              text="View online orders"
            />
          )}
        </Container>

        {showESIBanner && (
          <ESIBanner
            id="citrus_sidebar"
            type="block"
            url="/esi/online-adverts/banner/broad?placement=orderConfirmation&forceFullyQualifiedESI=true&type=block"
          />
        )}
      </Container>
    );
  }

  const renderOrderTotals = () => {
    if (willMakeRewardsAPICall) {
      return (
        <div className={styles.spinner} data-test="rewards-loading-spinner">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <OrderTotals
        isDeliveryPage={isDeliveryPage}
        sidebarView
      />
    );
  };

  return (
    <div className={styles.wrapper} data-test="sidebar-content">
      <OrderSummary
        items={items}
        sidebarView
      />
      {renderOrderTotals()}
    </div>
  );
};

const Sidebar = ({
  isDeliveryPage = false,
  isOrderConfirmationPage = false,
}: SidebarProps) => (
  <aside
    className={styles.container}
    data-test="sidebar-container"
  >
    <div className={styles.innerContainer}>
      <SidebarContent
        isDeliveryPage={isDeliveryPage}
        isOrderConfirmationPage={isOrderConfirmationPage}
        showAds
      />
    </div>
  </aside>
);

export default Sidebar;
