// Loads script by appending <script> tag to the DOM
export function loadScript({
  url,
  appendToNodeId,
  onSuccessCallback = () => {
  },
  onErrorCallback = () => {
  },
  async = false,
}) {

  let scriptLoaded = false;

  function onLoad() {
    scriptLoaded = true;
    onSuccessCallback();
  }

  function onError() {
    scriptLoaded = false;
    onErrorCallback();
  }

  return () => {
    if (scriptLoaded) {
      onSuccessCallback();
    }

    const script = document.createElement('script');
    script.onload = onLoad;
    script.onerror = onError;
    script.type = 'text/javascript';
    script.src = url;
    script.async = async;

    const node = appendToNodeId ? document.getElementById(appendToNodeId) : document.body;
    node.appendChild(script);
  };
}

// Executes script by appending <script> tag to the DOM
export function appendScript(jsString, nodeId) {

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = jsString;

  const node = nodeId ? document.getElementById(nodeId) : document.body;
  node.appendChild(script);
}
