import {
  APPLY_REWARDS,
  REMOVE_REWARDS,
} from '../../../constants/actionConstants';
import {
  URL_REWARDS_APPLY,
  URL_REWARDS_REMOVE,
} from '../../../constants/endpointConstants';
import { getPayments } from './paymentActions';
import { shouldApplyRewards } from '../../../utils/payment/shouldApplyRewards';
import determineSelectedPaymentType from './selectedPaymentTypeActions';

export const applyRewards = ({ shouldGetPayments, isCustomerAction } = {}) => async (dispatch, getState) => {

  if (!isCustomerAction && !shouldApplyRewards(getState())) return;

  await dispatch({
    type: APPLY_REWARDS,
    request: client => client({ path: URL_REWARDS_APPLY, config: { method: 'POST' } }),
    isCustomerAction,
  });

  if (shouldGetPayments) {
    await dispatch(getPayments());
    dispatch(determineSelectedPaymentType());
  }
};

export const removeRewards = campaignId => async (dispatch) => {
  await dispatch({
    type: REMOVE_REWARDS,
    request: client => client({ path: URL_REWARDS_REMOVE(campaignId), config: { method: 'DELETE' } }),
  });
  await dispatch(getPayments());
  dispatch(determineSelectedPaymentType());
};
