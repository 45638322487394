import { createSelector } from 'reselect';

import { APPLY_GIFT_VOUCHER } from '../../../constants/actionConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import makeGiftOptionsReducer from './giftOptionsHigherOrderReducer';

export const INITIAL_STATE = {
  isLoading: false,
  formSubmitFailed: false,
  errorMessage: undefined,
};

export default makeGiftOptionsReducer({
  INITIAL_STATE,
  ACTION_NAME: APPLY_GIFT_VOUCHER,
});

export const getGiftVoucherRemovedOptions = state => state?.giftVoucher?.removedOptions ?? [];

const emptyArray = Object.freeze([]);
export const giftVouchersSelector = createSelector(
  state => state.orderForm?.payments || emptyArray,
  (_, context) => context,
  (payments, context) => payments
    .filter(payment => payment.type === paymentTypeConstants.GIFT_VOUCHER)
    .filter(payment => !payment.isRemoved || (payment.isRemoved && payment.removalContext === context))
    .map(payment => ({
      ...payment,
      serialNumber: payment.details.serialNumber,
      securityCode: payment.details.securityCode,
    })),
);
