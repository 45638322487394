export default async function fetchWithTimeout(resource, options) {
  const controller = new AbortController();
  const abortTimer = setTimeout(() => controller.abort(), options.timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(abortTimer);

  return response;
}
