import React from 'react';

// Design System
import Checkbox from 'jl-design-system/components/checkbox/Checkbox';
import EditSmall from 'jl-design-system/elements/icons-jb/Edit24PxOutlined';
import { TertiaryButton } from 'jl-design-system/elements/button/Button';

// Types
import { ToggleProposedBillingAddressProps } from './ToggleProposedBillingAddress.types';

// Component
import BodyText from '../../../body-text';
import Container from '../../../container';
import DisplayAddress from '../../../display-address/DisplayAddress';

const ToggleProposedBillingAddress = ({
  action,
  addressAsLabel = false,
  deliveryAddress = {},
  disabled = false,
  isSignedInWithData = false,
  useProposedBillingAddress = true,
}: ToggleProposedBillingAddressProps) => {
  const getCheckboxLabel = () => {
    if (!addressAsLabel) {
      return 'Same as delivery details';
    }

    return (
      <BodyText
        marginBottom="2"
        tag="div"
        testId="address-as-label"
      >
        <DisplayAddress {...deliveryAddress} data-test="toggle-billing-address-display-address" inline />
      </BodyText>
    );
  };

  return (
    <Container data-test="toggle-proposed-billing-address">
      <Container marginBottom="2">
        <Checkbox
          checked={useProposedBillingAddress}
          disabled={disabled}
          isControlled
          label={getCheckboxLabel()}
          name="toggle-delivery-address-checkbox"
          onChange={action}
        />
      </Container>

      {useProposedBillingAddress && !addressAsLabel && !isSignedInWithData && (
        <BodyText
          marginBottom="2"
          tag="div"
          testId="same-as-delivery"
        >
          <DisplayAddress {...deliveryAddress} inline />
        </BodyText>
      )}

      {useProposedBillingAddress && !isSignedInWithData && (
        <TertiaryButton
          data-test="enter-new-address-button"
          disabled={disabled}
          IconComponent={EditSmall}
          onClick={action}
          small
        >
          Enter new details
        </TertiaryButton>
      )}
    </Container>
  );
};

export default ToggleProposedBillingAddress;
