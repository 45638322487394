import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { push } from 'connected-react-router';
import routeConstants from '../../../constants/routeConstants';
import {
  ANALYTICS_SINGLE_SIGN_ON_LOGIN,
  APPS_AUTHENTICATED_HANDOVER,
  APPS_GUEST_HANDOVER,
  APPS_REFRESH_ACCESS_TOKEN_REQUESTED,
  GET_FEATURES,
  HAND_OVER,
  HAND_OVER_RESET,
  LOGIN_RESET,
} from '../../../constants/actionConstants';
import {
  URL_APPS_HANDOVER,
  URL_FEATURES,
  URL_WEB_HANDOVER,
} from '../../../constants/endpointConstants';
import { resetApp } from '../reset/resetActions';
import { showDisableSiteSpinner } from '../app/appActions';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import appConstants from '../../../constants/appConstants';
import {
  webAuth0Fallback,
  onAuth0SdkException,
  customerAppsFallback,
  auth0Callback,
} from '../auth0-callback/auth0CallbackActions';
import { initLogging } from '../../../utils/logging/logging-utils';
import createAuth0Client from '../../../utils/auth0/auth0Proxy';
import getAuth0Config from '../../../utils/auth0/auth0Config';
import triggerAnalyticsEvent from '../analytics/analyticsAction';
import { saveTimeToAuth0TokenTimestamp } from '../timing/timingActions';

const webHandover = body => ({
  type: HAND_OVER,
  requestPayload: body,
  request: client => client({ path: URL_WEB_HANDOVER, config: { method: 'POST', body } }),
});

const appsHandover = body => ({
  type: body.actionType,
  requestPayload: body,
  request: client => client({ path: URL_APPS_HANDOVER, config: { method: 'POST', body } }),
});

export const getFeatures = () => ({
  type: GET_FEATURES,
  request: client => client({ path: URL_FEATURES, config: { method: 'GET' } }),
});

const handleAccessTokenFailure = () => async (dispatch, getState) => {
  const state = getState();
  const isRefreshAccessTokenRequested = get(state.mobileApps, 'isRefreshAccessTokenRequested');

  if (isRefreshAccessTokenRequested) {
    dispatch(customerAppsFallback());
  } else {
    const requestAccessToken = get(state.mobileApps, 'requestAccessToken');
    dispatch(showDisableSiteSpinner());
    dispatch({ type: APPS_REFRESH_ACCESS_TOKEN_REQUESTED });
    if (isFunction(requestAccessToken)) {
      requestAccessToken();
    }
  }
};

const handleAppsHandoverFailed = error => async (dispatch) => {
  switch (error.code) {
    case errorCodeConstants.AUTH0_ACCESS_TOKEN_ERROR: await dispatch(handleAccessTokenFailure()); break;
    case errorCodeConstants.AUTH0_UNAVAILABLE_ERROR: await dispatch(customerAppsFallback()); break;
    case errorCodeConstants.SESSION_EXPIRED: break;
    default: break;
  }
};

const updateBasketIdCallback = (state, basketId) => {
  const updateBasketId = get(state, 'mobileApps.updateBasketId');
  if (updateBasketId) {
    updateBasketId(basketId);
  }
};

export function getHandoverParams(params) {
  const {
    timeContinueToCheckoutClicked,
    timeToCheckoutSpinner,
    timeToReactLoaded,
    timeToAuth0Complete,
  } = window;

  if (timeContinueToCheckoutClicked && timeToCheckoutSpinner && timeToReactLoaded) {
    return {
      ...params,
      timestamps: {
        timeContinueToCheckoutClicked: parseInt(timeContinueToCheckoutClicked, 10),
        timeToCheckoutSpinner,
        timeToReactLoaded: timeToReactLoaded - timeContinueToCheckoutClicked,
        ...timeToAuth0Complete && { timeToAuth0Complete: timeToAuth0Complete - timeContinueToCheckoutClicked },
      },
    };
  }

  return params;
}

export const handleAppsHandover = body => async (dispatch, getState) => {
  dispatch(resetApp(HAND_OVER_RESET));
  const authAction = await dispatch(appsHandover(getHandoverParams(body)));
  const { type, error, result: { basketId } = {} } = authAction;

  if (type === `${APPS_GUEST_HANDOVER}.SUCCESS` || type === `${APPS_AUTHENTICATED_HANDOVER}.SUCCESS`) {
    dispatch(initLogging());
    await dispatch(resetApp(LOGIN_RESET));
    if (basketId && !body.express) {
      updateBasketIdCallback(getState(), basketId);
    }

    dispatch(push(routeConstants.DELIVERY_CHOICE));
  }

  if (type === `${APPS_GUEST_HANDOVER}.FAILED` || type === `${APPS_AUTHENTICATED_HANDOVER}.FAILED`) {
    await dispatch(handleAppsHandoverFailed(error));
  }
};

export const redirectToAuth0Login = (auth0Client, checkoutAuth0TokenTimeout) => async (dispatch) => {
  const handleRedirectToAuth0Login = (timeoutFunction) => {
    clearTimeout(timeoutFunction);
    dispatch(onAuth0SdkException(Error(), 'auth0: loginWithRedirect in auth0Login'));
    dispatch(webAuth0Fallback());
  };

  const redirectTimeout = setTimeout(handleRedirectToAuth0Login, checkoutAuth0TokenTimeout);

  auth0Client.loginWithRedirect()
    .then(() => {
      clearTimeout(redirectTimeout);
    })
    .catch(() => {
      handleRedirectToAuth0Login(redirectTimeout);
    });
};

export const singleSignOn = (auth0Client, checkoutAuth0TokenTimeout, auth0TokenTimeout) => (dispatch) => {
  const handleSingleSignOnFailure = () => {
    dispatch(onAuth0SdkException(Error(), 'auth0: getTokenSilently outer timeout happened'));
    dispatch(redirectToAuth0Login(auth0Client, checkoutAuth0TokenTimeout));
  };

  const getTokenTimeout = setTimeout(handleSingleSignOnFailure, checkoutAuth0TokenTimeout);

  auth0Client.getTokenSilently({
    timeoutInSeconds: auth0TokenTimeout,
  }).then((accessToken) => {
    clearTimeout(getTokenTimeout);

    dispatch(saveTimeToAuth0TokenTimestamp());

    // preserve on window for use in api.js
    window.Auth0Client = auth0Client;

    dispatch(triggerAnalyticsEvent(ANALYTICS_SINGLE_SIGN_ON_LOGIN));
    dispatch(auth0Callback(accessToken));
  }).catch(() => {
    clearTimeout(getTokenTimeout);
    dispatch(redirectToAuth0Login(auth0Client, checkoutAuth0TokenTimeout));
  });
};

export const auth0Login = (
  auth0TokenTimeout = appConstants.AUTH0_TOKEN_TIMEOUT,
  checkoutAuth0TokenTimeout = appConstants.CHECKOUT_AUTH0_TOKEN_TIMEOUT,
) => async (dispatch, getState) => {
  try {
    const auth0Config = getAuth0Config(getState());
    const auth0Client = await createAuth0Client(auth0Config);

    if (get(auth0Config, 'prompt') === undefined) {
      dispatch(singleSignOn(auth0Client, checkoutAuth0TokenTimeout, auth0TokenTimeout));
    } else {
      dispatch(redirectToAuth0Login(auth0Client, checkoutAuth0TokenTimeout));
    }
  } catch (err) {
    dispatch(onAuth0SdkException(err, 'auth0: createAuth0Client in auth0Login'));
    dispatch(webAuth0Fallback());
  }
};

const handleSuccessHandover = bffHandoverResponse => async (dispatch) => {
  if (bffHandoverResponse?.auth0HealthStatus === 'UNHEALTHY') {
    dispatch(webAuth0Fallback());
    return;
  }
  await dispatch(auth0Login());
};

export const handleWebHandover = (params = {}) => async (dispatch) => {
  dispatch(resetApp(HAND_OVER_RESET));

  const { type, result } = await dispatch(webHandover(getHandoverParams(params)));

  if (type === `${HAND_OVER}.SUCCESS`) {
    await dispatch(handleSuccessHandover(result));
  }
};

export default handleWebHandover;
