export default function mergeAddressBook(oldAddressBook, newAddressBook) {
  return newAddressBook.map((address) => {
    const { id } = address;
    const old = oldAddressBook?.find(addressRecord => addressRecord.id === id);

    if (old) {
      // add in any non-existent keys from the old address record
      return Object.keys(old).reduce((acc, key) => {
        if (!acc.hasOwnProperty(key)) {
          return {
            ...acc,
            [key]: old[key],
          };
        }
        return acc;
      }, address);
    }

    return address;
  });
}
