function thumbnail(src) {
  if (!src) {
    return null;
  }

  const scene7arg = '$rsp-bskt-thmb$';

  if (src.charAt(src.length - 1) === '?') {
    return `${src}${scene7arg}`;
  }

  return `${src}?${scene7arg}`;
}

export default thumbnail;
