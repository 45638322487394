import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// jl-design-system
import RadioButton from 'jl-design-system/elements/radio-button/RadioButton';
// styles
import styles from './radio-button-tile.scss';

export class RadioButtonTile extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  };

  static defaultProps = {
    children: undefined,
    id: '',
    onChange: window.defaultFunc,
    value: '',
  };

  render() {
    const { children, id, onChange, value } = this.props;
    const selected = value === id;
    const containerClassnames = classNames(styles.container, {
      [styles.selected]: selected,
    });

    return (
      <div className={containerClassnames} data-test="radio-button-tile">
        <div className={styles.radioContainer}>
          <RadioButton
            {...this.props}
            focusOnLabel
            onChange={onChange}
            radioValue={id}
            value={value}
          />
        </div>

        {
          selected &&
          <div className={styles.contentContainer} data-test="radio-button-tile-content">
            {children}
          </div>
        }
      </div>
    );
  }
}

export default RadioButtonTile;
