// Types
import { RootState } from 'types/RootState.types';

// Config
import { isAndroidApp, isApps, isCustomerApp, isWeb } from './appReducer';

export const getAppApplicationTypeSelector = (state: RootState) => state.app?.applicationType;
export const getAuth0HealthCheckLoadingSelector = (state: RootState) => state.app?.auth0HealthCheckLoading || false;
export const getAppGooglePayStubsEnabledSelector = (state: RootState) => state.app?.googlePayStubsEnabled || false;
export const getIsExpressCheckoutSelector = (state: RootState) => state.app?.isExpressCheckout || false;
export const getAppIsLoadingSelector = (state: RootState) => state.app?.isLoading ?? false;
export const getAppIsLoginRequired = (state: RootState) => state.app?.isLoginRequired ?? false;
export const getLeaveCheckoutModalBaseRoutePathSelector = (state: RootState) => state.app?.leaveCheckoutModalBaseRoutePath || '';
export const getLoadingLazyComponentSelector = (state: RootState) => state.app?.loadingLazyComponent || '';
export const getOrderCompleteSelector = (state: RootState) => state.app?.orderComplete || false;
export const getAppScrollToPartnershipDiscountToggleSelector =
    (state: RootState) => state.app?.scrollToPartnershipDiscountToggle || false;
export const getAppScrollToDeliveryDetailsSelector =
    (state: RootState) => state.app?.scrollToDeliveryDetails || false;
export const getSessionExpiredAnnouncedSelector = (state: RootState) => state.app?.sessionExpiredAnnounced || false;
export const getSessionExpiringAnnouncedSelector = (state: RootState) => state.app?.sessionExpiringAnnounced || false;
export const getShowDisableSiteSpinnerSelector = (state: RootState) => state.app?.showDisableSiteSpinner ?? true;
export const getShowLeaveCheckoutSelector = (state: RootState) => state.app?.showLeaveCheckout || false;
export const getShowSessionEndedModalSelector = (state: RootState) => state.app?.showSessionEndedModal || false;

export const getIsAndroidApplicationSelector = (state: RootState) => isAndroidApp(state);
export const getIsApplicationSelector = (state: RootState) => isApps(state);
export const getIsCustomerAppSelector = (state: RootState) => isCustomerApp(state);
export const getIsWebSelector = (state: RootState) => isWeb(state);

export const getShowExpressPayments = (state: RootState) => state.app?.showExpressPayments || false;
export const getAppShopsToShowSelector = (state: RootState) => state.app?.shopsToShow;
export const getSessionRefreshAPICallActive = (state: RootState) => state.app?.sessionRefreshAPICallActive || false;

export const getIsGiftReceiptSelectedSelector = (
  state: RootState,
  formId: string,
) => state.app?.isGiftReceiptSelected?.[formId];
