import { createSelector } from 'reselect';

import { getSessionRefreshAPICallActive } from '../../redux/reducers/app/appSelector';
import { getSessionExpiryData } from '../../redux/reducers/bff/bffSelector';
import { getSessionWarningAfterTime } from '../../redux/reducers/config/configSelector';

const sessionTimeoutModalState = createSelector(
  [
    getSessionRefreshAPICallActive,
    getSessionExpiryData,
    getSessionWarningAfterTime,
  ],
  (
    sessionRefreshAPICallActive,
    sessionExpiryData,
    sessionWarningAfterTime,
  ) => ({
    sessionRefreshAPICallActive,
    sessionExpiryData,
    sessionWarningAfterTime,
  }),
);

export default sessionTimeoutModalState;
