import React, { useState } from 'react';

// Design System
import { IconButton } from 'jl-design-system/elements/button/IconButton';
import MagnifyGlassIcon from 'jl-design-system/elements/icons-jb/Magnify24PxOutlined';
import Modal from 'jl-design-system/components/modal/Modal';

// Types
import { ProductImageWithMagnifierProps } from './ProductImageWithMagnifier.types';

// Config
import appConstants from '../../constants/appConstants';
import thumbnail from '../../utils/image/thumbnail';

// Styles
import styles from './product-image-with-magnifier.scss';

const ProductImageWithMagnifier = ({
  imageUrl,
  name,
  showMagnifyingIcon = false,
}: ProductImageWithMagnifierProps) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const setModalOpenHandler = () => setModalOpen(!isModalOpen);

  return (
    <>
      <div className={styles.itemImage}>
        <div className={styles.itemImageInnerWrapper}>
          <img
            alt={name}
            aria-hidden="true"
            data-test="product-item-image"
            loading="lazy"
            src={`${thumbnail(imageUrl)}`}
          />
          {showMagnifyingIcon && (
            <IconButton
              ariaLabel="Zoom in product image"
              className={styles.floatingIcon}
              IconComponent={MagnifyGlassIcon}
              onClick={setModalOpenHandler}
              size="small"
              variant="filled-light"
            />
          )}
        </div>
      </div>

      {isModalOpen && (
        <Modal
          a11yHeader={name}
          forceFullHeight
          fullscreen
          isOpen={isModalOpen}
          onClose={setModalOpenHandler}
          rootId={appConstants.ROOT}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
        >
          <div data-test="magnified-product-item-image-wrapper">
            <img
              alt={`magnified-${name}`}
              aria-hidden="true"
              data-test="magnified-product-item-image"
              src={imageUrl}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProductImageWithMagnifier;
