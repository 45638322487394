import React from 'react';
import cx from 'classnames';

// Design System
import { Message } from 'jl-design-system/components/message/v2/Message';

// Types
import { Challenge25Props } from './Challenge25.types';

// Components
import Container from '../container';
import Heading from '../heading';

// Styles
import styles from './challenge-25.scss';

const Challenge25 = ({
  isMessage = false,
  isSignedIn = false,
}: Challenge25Props) => {
  if (isMessage) {
    return (
      <Container background="white" padding="3">
        <Message
          body="It's our policy to ask for this if we think you look younger than 25."
          title="Please provide proof that you're aged 18 or over upon delivery/collection of an age-restricted item, such as alcohol"
          type="warning"
        />
      </Container>
    );
  }

  return (
    <div className={
      cx(styles.container, {
        [styles.divider]: isSignedIn,
      })
    }
    >
      <Heading
        label="Please provide proof that you're aged 18 or over upon delivery/collection of an age-restricted item, such as alcohol"
        marginBottom="1"
        tag="h4"
        type="xs"
      />
      <p>It&apos;s our policy to ask for this if we think you look younger than 25.</p>
    </div>
  );
};

export default Challenge25;
