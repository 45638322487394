import React from 'react';
import cx from 'classnames';

// Types
import { ContainerThemeProps } from './Container.types';

// Config
import { getBackgroundClass, getSpacingClasses } from '../design-system/DesignSystem.constants';
import { getDataAttributes } from '../../utils/helpers/dataAttributes';

// Styles
import styles from './container.scss';

const Container = ({
  background,
  children,
  className,
  display,
  hideForPrint = false,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  marginX,
  marginY,
  maskPercy = false,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingX,
  paddingY,
  renderElse,
  renderIf,
  tag: Tag = 'div',
  testId,
  textAlign,
  ...otherProps
}: ContainerThemeProps & React.HTMLAttributes<HTMLElement>) => {
  const backgroundClass = getBackgroundClass(background);
  const marginClasses = getSpacingClasses({ type: 'margin',
    positions: {
      all: margin,
      bottom: marginBottom,
      left: marginLeft,
      right: marginRight,
      top: marginTop,
      x: marginX,
      y: marginY,
    } });

  const paddingClasses = getSpacingClasses({ type: 'padding',
    positions: {
      all: padding,
      bottom: paddingBottom,
      left: paddingLeft,
      right: paddingRight,
      top: paddingTop,
      x: paddingX,
      y: paddingY,
    } });

  const containerClass = cx(
    backgroundClass,
    marginClasses,
    paddingClasses,
    className,
    {
      [styles.displayNone]: display === 'none',
      [styles.textCenter]: textAlign === 'center',
      [styles.textRight]: textAlign === 'right',
      [styles.hideForPrint]: hideForPrint,
    },
  );

  const getContainerTag = (content: string | React.ReactNode) => (
    <Tag
      className={containerClass}
      {...getDataAttributes({ maskPercy, testId })}
      {...otherProps}
    >{content}
    </Tag>
  );

  if (renderIf === false || renderIf === null || renderIf === '') {
    return renderElse && getContainerTag(renderElse);
  }

  return children && getContainerTag(children);
};

export default Container;
