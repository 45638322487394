import get from 'lodash/get';
import { CARD_DETAILS_FORM_ID, PREPAID_CARD_DETAILS_FORM_ID } from '../form/configs/cardDetails';
import { sendNewRelicCustomEvent } from '../logging/logging-utils';
import paymentTypeConstants from '../../constants/paymentTypeConstants';

const logUnsupportedCardType = selectedPaymentType => (dispatch, getState) => {
  const formId = selectedPaymentType === paymentTypeConstants.PREPAID_CARD ?
    PREPAID_CARD_DETAILS_FORM_ID :
    CARD_DETAILS_FORM_ID;
  const binRange = get(getState(), `form[${formId}].values.cardNumber`, '')
    .replace(' ', '')
    .substring(0, 6);

  sendNewRelicCustomEvent('unsupportedBinRange', { binRange });
};

export default logUnsupportedCardType;
