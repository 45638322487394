/* eslint-disable css-modules/no-undef-class */
/* eslint-disable css-modules/no-unused-class */
import cx from 'classnames';

// Types
import { DesignSystemTextThemeProps, DesignSystemHeadingThemeProps, SpacingTypes, SpacingPositions, DesignSystemColorProps } from './DesignSystem.types';

// Styles
import styles from './design-system.scss';

// Themes
export const colorKeys: Record<string, string> = {
  white: 'White',
  'grey-5': 'Grey-5',
};
export const designSystemColors: DesignSystemColorProps = Object.keys(colorKeys).reduce((acc, color) => {
  acc[color] = styles[`background${color.charAt(0).toUpperCase() + color.slice(1)}`];
  return acc;
}, {} as DesignSystemColorProps);

export function getBackgroundClass(background?: string) {
  return background && designSystemColors[background];
}

// Typography
export const headingSizeKeys: Record<string, string> = {
  xs: 'XS',
  s: 'S',
  m: 'M',
  l: 'L',
};
export const headingThemes: DesignSystemHeadingThemeProps = Object.keys(headingSizeKeys).reduce((acc, size) => {
  acc[size] = styles[`heading${size.toUpperCase()}`];
  return acc;
}, {} as DesignSystemHeadingThemeProps);

export const bodySizeKeys: Record<string, string> = {
  m: 'M',
  l: 'L',
};
export const textThemes: DesignSystemTextThemeProps = Object.keys(bodySizeKeys).reduce((acc, size) => {
  acc[size] = styles[`text${size.toUpperCase()}`];
  return acc;
}, {} as DesignSystemHeadingThemeProps);

// Spacing
const spacingSizes = [0, 0.5, 1, 1.5, 2, 3, 4, 7];
type SpacingSizeKey = `${typeof spacingSizes[number]}`;
export const spacingSizeKeys: Record<SpacingSizeKey, number> = Object.fromEntries(
  spacingSizes.map(size => [size.toString(), size]),
) as Record<SpacingSizeKey, number>;

const createMarginClasses = (key: string) => {
  const formattedKey = key.replace('.', '_');
  const all = styles[`m${formattedKey}`];
  const bottom = styles[`mb${formattedKey}`];
  const left = styles[`ml${formattedKey}`];
  const right = styles[`mr${formattedKey}`];
  const top = styles[`mt${formattedKey}`];

  return {
    all,
    bottom,
    left,
    right,
    top,
    x: cx(left, right),
    y: cx(bottom, top),
  };
};

const createPaddingClasses = (key: string) => {
  const formattedKey = key.replace('.', '_');
  const all = styles[`p${formattedKey}`];
  const bottom = styles[`pb${formattedKey}`];
  const left = styles[`pl${formattedKey}`];
  const right = styles[`pr${formattedKey}`];
  const top = styles[`pt${formattedKey}`];

  return {
    all,
    bottom,
    left,
    right,
    top,
    x: cx(left, right),
    y: cx(bottom, top),
  };
};

export const designSystemSpacing = Object.keys(spacingSizeKeys).reduce((acc, key) => {
  acc[key] = {
    margin: createMarginClasses(key),
    padding: createPaddingClasses(key),
  };
  return acc;
}, {} as {
  [key: string]: {
    margin: {
      all?: string;
      bottom?: string;
      left?: string;
      right?: string;
      top?: string;
      x?: string;
      y?: string;
    };
    padding: {
      all?: string;
      bottom?: string;
      left?: string;
      right?: string;
      top?: string;
      x?: string;
      y?: string;
    };
  };
});

export function getSpacingClass(
  { backupSize, position, size, type }:
  { backupSize?: string; position: SpacingPositions; size: string; type: SpacingTypes },
) {
  const primaryClass = designSystemSpacing[size]?.[type]?.[position];
  if (primaryClass) {
    return primaryClass;
  }

  if (backupSize) {
    const backupClass = designSystemSpacing[backupSize]?.[type]?.[position];
    return backupClass || undefined;
  }

  return undefined;
}

export function getSpacingClasses(
  { positions, type }:
  { positions: Record<string, string | undefined>; type: SpacingTypes },
) {
  if (positions.all) {
    return getSpacingClass({ type, position: 'all', size: positions.all });
  }

  return (
    cx(
      positions.bottom && getSpacingClass({ type, position: 'bottom', size: positions.bottom }),
      positions.left && getSpacingClass({ type, position: 'left', size: positions.left }),
      positions.right && getSpacingClass({ type, position: 'right', size: positions.right }),
      positions.top && getSpacingClass({ type, position: 'top', size: positions.top }),
      positions.x && getSpacingClass({ type, position: 'x', size: positions.x }),
      positions.y && getSpacingClass({ type, position: 'y', size: positions.y }),
    )
  );
}
