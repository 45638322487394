import React from 'react';

// Design System
import { Message } from 'jl-design-system/components/message/v2/Message';

// Types
import { OrderTimeoutNotificationProps } from './OrderTimeoutNotification.types';

// Components
import MyAccountLink from '../my-account-link';

// Styles
import styles from './order-timeout-notifications.scss';

const OrderTimeoutNotification = ({
  isGuest = false,
}: OrderTimeoutNotificationProps) => {
  const getBodyMessage = () => (
    <>
      <p>
        Please check your email to see if you have received an order confirmation email.
        Please also check your junk/spam folder.
      </p>
      <p>
        If you haven’t received anything after 10 minutes, it is likely your order has not been placed and
        your card has not been charged.
      </p>
      <p>
        For any concerns regarding your order, please contact us. We are sorry for any inconvenience caused.
      </p>
      {!isGuest && (
        <p><MyAccountLink /></p>
      )}
    </>
  );

  return (
    <div className={styles.container}>
      <Message
        body={getBodyMessage()}
        title="Sorry, we had an issue processing your order"
        type="error"
      />
    </div>
  );
};

export default OrderTimeoutNotification;
