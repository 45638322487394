import routeConstants from '../../constants/routeConstants';

function shouldShowFullScreenSignUp(state) {
  const myJlFullScreenSignUpFlowActive = state.createAccount?.myJlFullScreenSignUpFlowActive;
  const showMyJLAccountPrompt = state.user?.showMyJLAccountPrompt;
  const pathname = state?.router?.location.pathname;
  const joinMyJLApiCallComplete = state?.createAccount?.joinMyJLApiCallComplete;

  // don't show when customer is signed in and already has an account
  if (!showMyJLAccountPrompt) {
    return false;
  }

  // don't show if any attempt to join has finished, successful or not
  if (joinMyJLApiCallComplete) {
    return false;
  }

  // don't show if the full screen flow has been abandoned
  if (!myJlFullScreenSignUpFlowActive) {
    return false;
  }

  // don't show if we're on order confirmation page
  if (pathname.includes(routeConstants.ORDER_CONFIRMATION)) {
    return false;
  }

  return true;
}

export default shouldShowFullScreenSignUp;
