import { push, replace } from 'connected-react-router';

// Config
import { handleAuthentication, updateBillingAddress } from './cardPaymentActions';
import { getPaymentPageAfterSubmitOrderError } from './orderFormActions';
import { removePromoCode, setPromoCodeError } from './promoCodeActions';
import { auth0Login } from '../bff/bffActions';
import {
  collectionCutOffPassedDuringSubmitOrder,
  handleCutOffPassedModalChangeDateClick,
} from '../click-and-collect/clickAndCollectActions';
import { handbackTo } from '../data-action-link/dataActionLinkActions';
import { handleInvalidPartnerDiscountCardNumber } from '../partner-discount/partnerDiscountActions';
import {
  saveDeliveryPreferences,
  saveCollectionPreferences,
  saveCardPreferences,
  savePreferencesPaymentMethod,
} from '../preferences/preferencesActions';
import {
  APPS_CLEANUP_BASKET,
  RESET_SUBMIT_ORDER_ERROR,
  SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR,
  SUBMIT_ORDER,
  UPDATE_BILLING_ADDRESS,
  PUT_CC_DELIVERY_DETAILS_SILENTLY,
  CARD_PAYMENT,
} from '../../../constants/actionConstants';
import deliveryConstants from '../../../constants/deliveryConstants';
import {
  URL_SUBMIT_ORDERS_POS_CREDIT,
  URL_SUBMIT_ORDERS,
  URL_SUBMIT_ORDERS_RETRY,
  URL_OCP_AUTHENTICATION,
  GET_OCP_HEADERS,
} from '../../../constants/endpointConstants';
import errorCodeConstants from '../../../constants/errorCodeConstants';
import paymentTypeConstants from '../../../constants/paymentTypeConstants';
import { creditApplicationStatuses } from '../../../constants/posCreditConstants';
import queryStrings from '../../../constants/queryStringConstants';
import routes from '../../../constants/routeConstants';
import getErrorMessageObject from '../../../utils/error/getErrorMessageObject';
import getPathWithQueryString from '../../../utils/helpers/url';
import getIovationBlackBoxIdFromDom from '../../../utils/iovation/getIovationBlackBoxIdFromDom';
import handleInconsistentOrderFormState from '../../../utils/orderform/handleInconsistentOrderFormState';
import submitOrderCanRetry from '../../../utils/orderform/submitOrderCanRetry';

export const resetSubmitOrderError = () => async (dispatch, getState) => {
  const state = getState();
  const searchParams = new URLSearchParams(state.router?.location?.search);
  const query = Object.fromEntries(searchParams);
  const clearQuery = Object.keys(query).length && !(query.hasOwnProperty('3dsecure') && query['3dsecure'] === '');

  if (clearQuery) {
    dispatch(replace(routes.PAYMENT));
  }

  dispatch({
    type: RESET_SUBMIT_ORDER_ERROR,
  });
};

export const submitOrder = ({ state, isPosCreditOrder, isRetry }) => {
  let url = URL_SUBMIT_ORDERS;

  if (isPosCreditOrder) {
    url = URL_SUBMIT_ORDERS_POS_CREDIT;
  } else if (isRetry) {
    url = URL_SUBMIT_ORDERS_RETRY;
  }

  const selectedPaymentType = state?.payment?.selectedPaymentType;

  const submitOrderDispatch = {
    type: SUBMIT_ORDER,
    request: client => client({
      path: url,
      config: { method: 'POST', headers: { 'JL-DEVICE-ID': getIovationBlackBoxIdFromDom().ioBB } },
    }),
    ...selectedPaymentType && { selectedPaymentType },
  };

  return submitOrderDispatch;
};

const showPosCreditSessionExpiredError = (isInvalidPartnerDiscount, isLoginRequired) => ({
  type: SHOW_POS_CREDIT_SESSION_EXPIRED_ERROR,
  isInvalidPartnerDiscount,
  isLoginRequired,
});

export const submitOrderAndRedirect = ({
  billingAddress,
  isPosCreditOrder,
  express,
} = {}) => async (dispatch, getState) => {
  if (billingAddress) {
    const { type } = await dispatch(updateBillingAddress({ body: billingAddress }));
    if (type === `${UPDATE_BILLING_ADDRESS}.FAILED`) {
      return;
    }
  }

  const state = getState();

  // push to /payment?processing so that if user navigates back we can keep them on payment page
  if (!isPosCreditOrder) {
    // POS credit order already pushed to ?poscredit in posCreditPaymentAction
    if (!express) {
      const path = getPathWithQueryString(routes.PAYMENT, queryStrings.SUBMITTING_ORDER);
      dispatch(push(path));
    }
  }

  if (state.clickAndCollect?.cutOffPassed && !isPosCreditOrder) {
    const newCollectionDayResponse = await dispatch(collectionCutOffPassedDuringSubmitOrder());
    if (newCollectionDayResponse.type === `${PUT_CC_DELIVERY_DETAILS_SILENTLY}.FAILED`) {
      dispatch(handleCutOffPassedModalChangeDateClick({
        isPayment: true,
        modalVisible: false,
      }));

      return;
    }
  }

  let submitOrderAction = await dispatch(submitOrder({ state, isPosCreditOrder }));
  if (submitOrderAction?.error?.code === errorCodeConstants.CARD_PAYMENT_SOFT_DECLINED) {
    const headers = GET_OCP_HEADERS(state);
    const url = URL_OCP_AUTHENTICATION(state.payment.ocpTargetUrl);

    try {
      const patchResponse = await fetch(url, { method: 'PATCH', headers });
      const submitCardDetailsAction = await patchResponse.json();

      const authResponse = await dispatch(handleAuthentication({
        id: state.payment.ocpPaymentId,
        submitCardDetailsAction,
      }));

      const {
        result: {
          creditCard3DSecureInfo: { is3DSecureAvailable } = {},
          threeDSMethodInfo: { threeDSMethodRequired } = {},
        } = {},
        type,
      } = authResponse ?? {};

      if (type === `${CARD_PAYMENT}.SUCCESS` && !threeDSMethodRequired && !is3DSecureAvailable) {
        submitOrderAction = await dispatch(submitOrder({ state, isPosCreditOrder, isRetry: true }));
      } else {
        const path = getPathWithQueryString(routes.PAYMENT, queryStrings['3D_SECURE']);
        dispatch(push(path));
        return;
      }
    } catch (error) {
      submitOrderAction = await dispatch(submitOrder({ state, isPosCreditOrder, isRetry: true }));
    }
  }

  if (submitOrderCanRetry(submitOrderAction)) {
    // retry submit order as it might succeed this time
    submitOrderAction = await dispatch(submitOrder({ state, isPosCreditOrder, isRetry: true }));
  }

  if (submitOrderAction.type === `${SUBMIT_ORDER}.SUCCESS`) {
    const submitOrderActionStatus = submitOrderAction?.result?.status ?? '';
    if ([
      creditApplicationStatuses.DECLINED,
      creditApplicationStatuses.REFERRED,
      creditApplicationStatuses.APPROVED,
      creditApplicationStatuses.CANCELLED,
    ].includes(submitOrderActionStatus)) {
      const browseURL = state?.bff?.browseUrl;
      await dispatch({ type: APPS_CLEANUP_BASKET });
      dispatch(handbackTo(browseURL));
      return;
    }

    dispatch(push(routes.ORDER_CONFIRMATION));

    if (state?.user?.isSignedIn) {
      const selectedDeliveryChoiceId = state.delivery?.selectedDeliveryChoiceId;

      if (selectedDeliveryChoiceId === deliveryConstants.DELIVERY) {
        dispatch(saveDeliveryPreferences());
      } else {
        dispatch(saveCollectionPreferences());
      }

      dispatch(saveCardPreferences());
      dispatch(savePreferencesPaymentMethod());
    }
  }

  if (submitOrderAction.type === `${SUBMIT_ORDER}.FAILED`) {
    const { error: { code, status } } = submitOrderAction;

    if (status === 401) {
      if (code === errorCodeConstants.PAYMENT_POS_CREDIT_DECLINED_ERROR) {
        await dispatch(auth0Login());
        return;
      }

      if (submitOrderAction.selectedPaymentType?.includes(paymentTypeConstants.POS_CREDIT)) {
        const isInvalidPartnerDiscount = code === errorCodeConstants.PARTNER_CARD_NUMBER_INVALID;
        const isLoginRequired = code === errorCodeConstants.PAYMENT_POS_CREDIT_CANCELLED_ERROR
          || code === errorCodeConstants.PAYMENT_POS_CREDIT_APPLICATION_INCOMPLETE;

        await dispatch(showPosCreditSessionExpiredError(isInvalidPartnerDiscount, isLoginRequired));
        return;
      }
    }

    if (code === errorCodeConstants.ORDER_FORM_INCONSISTENT_STATE) {
      await dispatch(handleInconsistentOrderFormState());
      return;
    }

    if (code === errorCodeConstants.PARTNER_CARD_NUMBER_INVALID) {
      await dispatch(handleInvalidPartnerDiscountCardNumber());
      return;
    }

    const isPromoCodeError = code?.includes('promo');
    if (isPromoCodeError) {
      const { message, body } = getErrorMessageObject({ error: submitOrderAction.error });
      await dispatch(setPromoCodeError(message, body, true));
      const promoCodeApplied = getState().orderForm?.promoCodeApplied;
      if (promoCodeApplied) {
        await dispatch(removePromoCode(promoCodeApplied));
      }
    }

    await dispatch(replace(routes.PAYMENT));

    if (code === errorCodeConstants.GATEWAY_TIMEOUT_ERROR || code === errorCodeConstants.CLIENT_CONNECTIVITY_ERROR) {
      dispatch({
        type: `${SUBMIT_ORDER}.TIMEOUT`,
      });
      return;
    }

    dispatch(getPaymentPageAfterSubmitOrderError(submitOrderAction));

  }

};
