import { useState, useEffect } from 'react';

// Config
import appConstants from '../../constants/appConstants';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    isDesktop?: boolean; isSmallDesktop?: boolean; sidebarIsVisible?: boolean;
  }>({
    isDesktop: false,
    isSmallDesktop: false,
    sidebarIsVisible: false,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        isDesktop: window.matchMedia(`(min-width: ${appConstants.MOBILE_BREAKPOINT})`)?.matches,
        isSmallDesktop: window.matchMedia(`(min-width: ${appConstants.DESKTOP_SM_BREAKPOINT})`)?.matches,
        sidebarIsVisible: window.matchMedia(`(min-width: ${appConstants.SIDEBAR_MIN_BREAKPOINT})`)?.matches,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
