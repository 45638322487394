import { isFeatureActive } from '../../redux/reducers/config/configReducer';
import featureConstants from '../../constants/featureConstants';
import userConstants from '../../constants/userConstants';
import deliveryConstants from '../../constants/deliveryConstants';
import { FULFILMENT_CHOICE_IGNORED } from '../../constants/actionConstants';

export default function shouldSkipDeliveryPage(state) {
  if (!isFeatureActive(state, featureConstants.ENABLE_FULFILMENT_PREFERENCE)) {
    return false;
  }

  if (state.delivery.deliveries?.length > 1) {
    // multibatch
    return false;
  }

  if (state.delivery.undeliverableItemsInBasket?.length > 0) {
    return false;
  }

  if (state.app.fulfilmentPreferenceHappened) {
    return false;
  }

  if (state.user.customerPreferences?.fulfilmentChoice !== userConstants.FULFILMENT_PREFERENCE_DELIVERY) {
    return false;
  }

  if (state.delivery.deliveries[0].type === deliveryConstants.GREEN_VAN_FLEET) {
    return false;
  }

  if (!state.delivery.confirmedDeliveryAddress.defaultShipping) {
    return false;
  }

  return true;
}

export const handleFulfilmentChoiceIgnored = () => (dispatch, getState) => {
  const fulfilmentPreferenceFeatureActive = isFeatureActive(getState(), featureConstants.ENABLE_FULFILMENT_PREFERENCE);
  const customerHasSavedPreference = [
    userConstants.FULFILMENT_PREFERENCE_DELIVERY,
    userConstants.FULFILMENT_PREFERENCE_COLLECTION,
  ].includes(getState().user?.customerPreferences?.fulfilmentChoice);

  if (fulfilmentPreferenceFeatureActive && customerHasSavedPreference) {
    dispatch({
      type: FULFILMENT_CHOICE_IGNORED,
    });
  }
};
