// lodash
import get from 'lodash/get';

import isAddressValid, { getAddressValidationErrors } from './isAddressValid';
import { countryCodeIsInternational } from '../address/addressHelpers';
import errorCodeConstants from '../../constants/errorCodeConstants';

export function validateAddress(addressRecord) {
  return {
    ...addressRecord,
    invalid: !isAddressValid(addressRecord),
  };
}

function validateDeliveryAddress(addressRecord) {
  const { countryCode } = addressRecord.address;

  let unavailabilityError = get(addressRecord, 'unavailabilityError');

  const errors = getAddressValidationErrors(addressRecord, true);
  const postcodeIneligible = get(errors, 'postcode');

  if (postcodeIneligible) {
    unavailabilityError = {
      code: errorCodeConstants.CLIENT_ADDRESS_INELIGIBLE_DELIVERY_POSTCODE,
      message: postcodeIneligible === 'BFPO' ?
        'This address is disabled as we do not deliver to BFPO addresses' :
        "This address is disabled as you have an item in your order that can't be delivered to this address.",
    };
  } else if (countryCodeIsInternational(countryCode)) {
    unavailabilityError = {
      code: errorCodeConstants.CLIENT_ADDRESS_INTERNATIONAL_NOT_AVAILABLE,
      message: 'This address is disabled as we do not offer international delivery.',
    };
  } else if (unavailabilityError) {
    unavailabilityError = {};
  }

  let validatedAddress = addressRecord;

  if (unavailabilityError) {
    validatedAddress = {
      ...addressRecord,
      unavailabilityError,
      notAvailableForDelivery: !!unavailabilityError.message,
    };
  }

  return validatedAddress;
}

export function validateAddressBookRecords(addressBook) {
  return addressBook.map((addressRecord) => {
    const address = validateAddress(addressRecord);
    const deliveryAddress = validateDeliveryAddress(address);
    return {
      ...deliveryAddress,
    };
  });
}
